import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

//---------------
//TODO: Make these parameters for TimerBackoffProvider
const globalSpeedMultiplier = 0.1

const smallestIntervalMS = 60 * 1000 * globalSpeedMultiplier;
const delayStepSizeMS = 3 * 60 * 1000 * globalSpeedMultiplier;
const delayMultiplier = 3;
const maxTimeMs = 6 * 60 * 60 * 1000 * globalSpeedMultiplier

export enum TimerBackoffType {
    exponential = 'exponential',
    linear = 'linear'
}

//----------------

interface TimerBackoffProviderI extends PropsWithChildren {
    onIntervalComplete: (...params: any[]) => any,
    type: TimerBackoffType,
    active: boolean
}

type TimerBackoffContextT = {
    instantiated: boolean,
    timeMs: number,
    resetTimeMs: () => void,
    active: boolean
}

//----------------


const initial_state: TimerBackoffContextT = {
    instantiated: false,
    timeMs: smallestIntervalMS,
    resetTimeMs: () => null,
    active: true
}

const TimerBackoffContext = createContext(initial_state)

export default function TimerBackoffProvider({
    active,
    children,
    onIntervalComplete,
    type,
}: TimerBackoffProviderI) {
    let localType = TimerBackoffType.linear
    if (type === TimerBackoffType.exponential) {
        localType = TimerBackoffType.exponential
    }

    const instantiated = useRef(false)
    const [timeMs, setTimeMs] = useState(smallestIntervalMS)

    const updateTimeMs = useMemo(() => localType === TimerBackoffType.exponential
        ? () => {
            setTimeMs((prev) => {
                const newTime = prev * delayMultiplier
                return Math.min(newTime, maxTimeMs)
            })
        }
        : () => {
            setTimeMs((prev) => {
                const newTime = prev + delayStepSizeMS
                return Math.min(newTime, maxTimeMs)
            })
        }, [localType])

    const resetTimeMs = useCallback(() => {
        setTimeMs(smallestIntervalMS)
        instantiated.current = false
    }, [])

    const onTimeout = useCallback(() => {
        //TODO: Have this return a boolean to reset the timeing if user wants to.
        onIntervalComplete()
        updateTimeMs()
    }, [onIntervalComplete, updateTimeMs])


    useEffect(() => {
        if (!active) {
            instantiated.current = false
            return () => {
                resetTimeMs()
            }
        }

        instantiated.current = true

        const timer = setTimeout(onTimeout, timeMs)

        const cleanup = () => {
            clearTimeout(timer)
            instantiated.current = false
        }

        return cleanup

    }, [active, onTimeout, resetTimeMs, timeMs])

    const value = useMemo(() => ({
        instantiated: instantiated.current,
        timeMs,
        resetTimeMs,
        active
    }), [instantiated, timeMs, resetTimeMs, active])

    return (
        <TimerBackoffContext.Provider value={value}>
            {children}
        </TimerBackoffContext.Provider>
    )
}

//----------------

export function useTimerBackoffContext() {
    return useContext(TimerBackoffContext)
}
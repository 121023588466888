import { Helmet } from 'react-helmet-async';
import { useEffect, useMemo } from 'react';
import { useParams, NavLink as RouterLink } from 'react-router-dom';
import { Button, Card, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { PATH_HOME } from '../../routes/paths';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../components/settings';
import fTitle from '../../utils/mrr/formatTitle';
import { FDateFormat } from '../../utils/formatTime';
import { fBookingDetails } from '../../utils/mrr/fBookingDetails';
import useResponsive from '../../hooks/useResponsive';
import { escapeButtonLabel } from '../../utils/mrr/uiConstants';
import ReservationDetails from '../../sections/@dashboard/reservation/ReservationDetails';
import { useReservationPopoverState } from '../../sections/@dashboard/reservation/list/useReservationPopoverState';
import LoadingScreen from '../../components/loading-screen/LoadingScreen';
import { useGetRTKQSingleReservationQuery } from '../../redux/rtkQuery/apiSlice';
import { safelyReadErrorMessage } from '../../utils/mrr/errorHandling';

// ----------------------------------------------------------------------

export default function ReservationLandingPage() {
  const { themeStretch } = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar()

  const isMainMenuVisible = useResponsive('up', 'lg');

  const { id } = useParams();

  // const { model: current_reservation, error, isLoading } = useSelector((state) =>
  //   state.current_reservation
  // );

  const {
    data: reservationData,
    error: reservationError,
    isFetching,
    isSuccess,
    isError
  } = useGetRTKQSingleReservationQuery({ reservationId: id })

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(safelyReadErrorMessage(null, reservationError), { variant: 'error' })
    }
  }, [enqueueSnackbar, isError, reservationError])

  const breadcrumbsLinks = useMemo(() => {
    const links: { name: string, href?: string }[] = [
      { name: 'Home', href: PATH_HOME.root },
      {
        name: 'Bookings',
        href: PATH_HOME.booking.list,
      }]
    if (isSuccess && reservationData) {
      links.push({ name: fBookingDetails(reservationData, FDateFormat.localized_month_day) })
    }
    return links
  }, [isSuccess, reservationData])

  const popOverProps = useReservationPopoverState()

  if (isFetching) {
    return <LoadingScreen />
  }

  return (
    <>
      <Helmet>
        <title>{fTitle('Booking')}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Booking Details"
          links={breadcrumbsLinks}
        />
        {isSuccess &&
          <Card sx={{ p: 2 }}>
            <ReservationDetails reservation={reservationData} {...popOverProps} />
          </Card>
        }

        {/* Show an 'escape' button while the main menu is hidden */}
        {!isMainMenuVisible &&
          <>
            <br />
            <Button component={RouterLink} to="/" variant="text">
              {escapeButtonLabel}
            </Button>
          </>
        }
      </Container>
    </>
  );
}
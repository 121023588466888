import { Grid } from '@mui/material';
import { PropsWithChildren } from 'react';

export function DetailRow(props: PropsWithChildren) {
    const { children } = props;
    return (
        <Grid item container spacing={2}>
            {children}
        </Grid>
    );
}
interface ColmProps extends PropsWithChildren {
    fullWidth?: boolean;
    third?: boolean;
    xs?: number;
    md?: number;
    lg?: number;
}
export function DetailColumn(props: ColmProps) {
    const { children, fullWidth, third, xs, md, lg } = props;

    // eslint-disable-next-line no-nested-ternary
    const size = fullWidth ? 12 : third ? 4 : 6;

    return (
        <Grid item xs={xs ? xs : size} md={md} lg={lg}>
            {children}
        </Grid>
    );
}
export function DetailContainer(props: PropsWithChildren) {
    const { children } = props
    return (
        <Grid margin='dense' container spacing={1} p={3} direction='column' width='320px'>
            {children}
        </Grid>
    )
}

import { ReservationGeneral } from "../../models/mrr/reservationGeneral";
import { ReservationStage } from "./reservationConstants";

/**
 * @param reservations list of reservations
 * @returns returns an array of all uncanceled reservations
 */
export default function filterCanceledReservations(reservations: ReservationGeneral[]) {
    return reservations.filter((reservation) => {
        return reservation.stage_mapped !== ReservationStage.Canceled
    })
}
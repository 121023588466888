import { TaskType } from "../../models/mrr/taskGeneral"

export enum TASK_TAB_OPTION {
    loi = 'Service Items',
    task = 'Tasks',
}

export const TASK_TAB_OPTIONS = Object.values(TASK_TAB_OPTION)

export const TASK_TYPE_OPTIONS = ['all', ...Object.values(TaskType)]

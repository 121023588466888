import { useMemo } from "react";
import { doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useAuthContext } from "../../auth/useAuthContext";
import { CLOUD_FUNCTIONS, DB } from "../../auth/FirebaseContext";
import { cloud_getPortalNotification } from "../../utils/mrr/cloudFunctions";
import { NewsletterType, PriorityType } from "../model/PortalNotification";

//NOTE: This hook encapsulates the behavior specific to the app implementing this lib.

export const portalNotificationsFirestoreKey = 'users';
export const usePortalNotificationsFetcher = () => {
  const { user } = useAuthContext();
  const docRef = useMemo(() => {
    if (user === null) {
      return null
    }
    return doc(DB, portalNotificationsFirestoreKey, user.id);
  }, [user]);


  const handleRequest = async () => {
    /*TEMP: Disabled for dev. This is nothing but 500s at the moment.

    TODO: If/when this comes back, rewrite cloud_getPortalNotification as a callable function (see: makeSalesforceRequest)

    const notificationRequest = httpsCallable<any, any>(CLOUD_FUNCTIONS, cloud_getPortalNotification)
    const response = await notificationRequest({})
    const data = await response.data

    if (
      data.status !== 200
      || (  !Array.isArray(data.portalNotifications)    //TODO: To be removed after a backend deploy.
         && !Array.isArray(data.portal_notifications))  //TODO: Kept after deploy. This is the new name.
    ) {
      throw new Error('Failed to read notifications.')
    }
    if (Array.isArray(data.portalNotifications)) {      //TODO: To be removed after a backend deploy.
      return data.portalNotifications
    }
    return data.portal_notifications;                   //TODO: Kept after deploy. This is the new name.
    */
    return [];
  };

  return useMemo(() => ({ docRef, handleRequest }), [docRef]);
};

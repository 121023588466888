import { SettingsValueProps } from '../components/settings/types';

declare global {
  interface Window {
    BRAND_TITLE: string;
    locale: string;
  }
}

interface BrandConfig {
  name: string;
  greeting: string;
  brandId: string;
  brandCode: string;
  backgroundUrl?: string;
  logoUrl: string;
  iconUrl: string;
  logoUrlDark: string;
  iconUrlDark: string;
  themeSettings: SettingsValueProps;
  timezone: string;
  customCssUrl?: string; // Optional, for custom styles if needed
  address: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
  };
  website: string;
  supportEmail: string;
  supportPhone: string;
  locale: string;
  origin: string;
}

// Define your branding configurations for different domains here
const domainBrand: Record<string, BrandConfig> = {
  'owner.beachviewrentals.com': {
    name: 'Beachview Rentals',
    greeting: 'Welcome',
    brandId: '55d26c7f-eb8d-4f82-937b-8b4c3ca7c709',
    brandCode: 'BVR',
    backgroundUrl: '/assets/background/bg_img3.webp',
    logoUrl: '/logo/bvr_logo_full.svg',
    iconUrl: '/logo/bvr_icon-192x192.png',
    logoUrlDark: '/logo/bvr_logo_full_dark.svg',
    iconUrlDark: '/logo/bvr_icon-192x192.png',
    website: 'https://www.beachviewrentals.com',
    supportEmail: 'owner@beachviewrentals.com',
    origin: 'https://owner.beachviewrentals.com/',
    supportPhone: '(949) 672-2627',
    locale: 'Newport Beach',
    timezone: 'America/Los_Angeles',
    address: {
      streetAddress: '314 Old Newport Blvd',
      city: 'Newport Beach',
      state: 'CA',
      postalCode: '92663'
    },
    themeSettings: {
      themeMode: 'light',
      themeDirection: 'ltr',
      themeContrast: 'default',
      themeLayout: 'vertical',
      themeColorPresets: 'default',
      themeStretch: false
    }
  },
  'owner.mauiresortrentals.com': {
    name: 'Maui Resort Rentals',
    greeting: 'Aloha',
    brandId: '49017bd8-7ce6-40bd-952e-0b4a1429b19b',
    brandCode: 'MRR',
    backgroundUrl: '/assets/background/bg_img1_33pct.jpg',
    logoUrl: '/logo/mrr_logo_full.svg',
    iconUrl: '/logo/mrr_logo_small.svg',
    logoUrlDark: '/logo/mrr_logo_full_dark.svg',
    iconUrlDark: '/logo/mrr_logo_small_dark.svg',
    website: 'https://www.mauiresortrentals.com',
    supportEmail: 'owner@mauiresortrentals.com',
    origin: 'https://owner.mauiresortrentals.com/',
    supportPhone: '(888) 696-6284',
    locale: 'Maui',
    timezone: 'Pacific/Honolulu',
    address: {
      streetAddress: '10 Halawai Dr #A-3',
      city: 'Lahaina',
      state: 'HI',
      postalCode: '96761'
    },
    themeSettings: {
      themeMode: 'light',
      themeDirection: 'ltr',
      themeContrast: 'default',
      themeLayout: 'vertical',
      themeColorPresets: 'default',
      themeStretch: false
    }
  },
  default: {
    name: 'Dream Resorts',
    greeting: 'Aloha',
    brandId: 'maui_resort_rentals',
    brandCode: 'DRR',
    backgroundUrl: '/assets/background/bg_img1_33pct.jpg',
    logoUrl: '/logo/mrr_logo_full.svg',
    iconUrl: '/logo/mrr_logo_small.svg',
    logoUrlDark: '/logo/mrr_logo_full_dark.svg',
    iconUrlDark: '/logo/mrr_logo_small_dark.svg',
    website: 'https://www.mauiresortrentals.com',
    supportEmail: 'owner@mauiresortrentals.com',
    origin: 'https://owner.mauiresortrentals.com/',
    supportPhone: '(888) 696-6284',
    locale: 'Maui',
    timezone: 'Pacific/Honolulu',
    address: {
      streetAddress: '10 Halawai Dr #A-3',
      city: 'Lahaina',
      state: 'HI',
      postalCode: '96761'
    },
    themeSettings: {
      themeMode: 'light',
      themeDirection: 'ltr',
      themeContrast: 'default',
      themeLayout: 'vertical',
      themeColorPresets: 'default',
      themeStretch: false
    }
  }
};

// Determine the current hostname, environment variable will override
const currentHostname = process.env.REACT_APP_HOSTNAME || window.location.hostname;

// Select the brand configuration based on the current hostname
const brandConfig = domainBrand[currentHostname] || domainBrand.default; // Fallback to 'default' if no match

export default brandConfig;

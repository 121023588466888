import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, MenuItem, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UseFormReturn, useForm } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import ConfirmDialog from '../../../components/confirm-dialog';
import { EditReservationFormI, ReservationGeneral, ReservationGuestType } from '../../../models/mrr/reservationGeneral';
import DatePickerForm from '../../@dashboard/reservation/form/components/DatePickerForm';
import FormProvider from '../../../components/hook-form/FormProvider';
import { confirmActionT } from '../../@dashboard/reservation/ReservationDetails';
import { useDispatch } from '../../../redux/store';
import { useAuthContext } from '../../../auth/useAuthContext';
import { usePutRTKQReservationCancelMutation, usePutRTKQReservationUpdateMutation } from '../../../redux/rtkQuery/apiSlice';
import { useReservationPopoverState } from '../../@dashboard/reservation/list/useReservationPopoverState';

interface ReservationTableRowMenuI extends ReturnType<typeof useReservationPopoverState> {
    reservation: ReservationGeneral | null;
    methods?: UseFormReturn<EditReservationFormI, any>;
}
export function ReservationTableRowMenu(props: ReservationTableRowMenuI) {
    const {
        handleClosePopover,
        reservation,
        setSelectedRow,
        confirmAction,
        openConfirm,
        openEditDatePicker,
        openPopover,
        setConfirmAction,
        setOpenConfirm,
        setOpenEditDatePicker,
        methods: externalMethods,
        dropRequestUserMessage,
        handleOpenDropinForm,
        handleCloseDropinForm,
        handleDrop
    } = props;

    const defaultMethods = useForm<EditReservationFormI>()
    const methods = externalMethods || defaultMethods
    const dispatch = useDispatch();
    const { reset } = methods

    const [
        sendReservationModifyPut, // This is the mutation trigger function.
        {
            data: resvModifyData,
            error: resvModifyError,
            isUninitialized: resvModifyIsUninitialized,
            isLoading: resvModifyIsLoading,
            isSuccess: resvModifyIsSuccess,
            isError: resvModifyIsError
        },
    ] = usePutRTKQReservationUpdateMutation();

    const [
        sendReservationCancelPut, // This is the mutation trigger function.
        {
            data: resvCancelData,
            error: resvCancelError,
            isUninitialized: resvCancelIsUninitialized,
            isLoading: resvCancelIsLoading,
            isSuccess: resvCancelIsSuccess,
            isError: resvCancelIsError
        },
    ] = usePutRTKQReservationCancelMutation();

    const [confirmButtonLoading, setConfirmButtonLoading] = useState(false)

    useEffect(() => {
        if (reservation) {
            reset({
                id: reservation.id,
                guest_id: reservation.guest_id,
                listing_id: reservation.listing_id,
                check_in: reservation.check_in_ISO,
                check_out: reservation.check_out_ISO,
            })
        }
    }, [reservation, reset])

    const { user } = useAuthContext();

    //TODO: These should be static lookups, not buried in this component.
    const confirmDialogTitle = useMemo(() => {
        switch (confirmAction) {
            case 'cancel':
                return 'Cancel Booking';
            case 'drop':
                return 'Request Drop';
            case 'edit':
                return 'Change Dates';
            case 'edit_confirm':
                return 'Change Dates';
            default:
                return 'Are you sure?';
        }
    }, [confirmAction]);
    const confirmDialogContent = useMemo(() => {
        switch (confirmAction) {
            case 'cancel':
                return 'Are you sure you would like to cancel this booking?';
            case 'drop':
                return dropRequestUserMessage;
            case 'edit_confirm':
                return 'Are you sure you would like to change the dates of this booking?';
            default:
                return 'Are you sure?';
        }
    }, [confirmAction, dropRequestUserMessage]);

    const handleOpenConfirm = (action: confirmActionT) => {
        setConfirmAction(action);
        if (action !== 'edit') {
            setOpenConfirm(true);
            return;
        }
        setOpenEditDatePicker(true);
        handleClosePopover();
    };
    const handleCloseConfirm = () => {
        setOpenEditDatePicker(false);
        setOpenConfirm(false);
        setConfirmAction(null);
        setSelectedRow(null);
        handleClosePopover();
    };
    const handleCloseDatePicker = () => {
        setOpenEditDatePicker(false);
        handleClosePopover();
    };
    const handleCancel = methods.handleSubmit(async (data) => {
        console.log('Cancel Booking');

        if (!user || !data.id) {
            return;
        }

        const putResponse = await sendReservationCancelPut({ reservationID: data.id })
        // console.log('after', putResponse, 'objects', resvModifyData, resvModifyError, 'states', resvModifyIsUninitialized, resvModifyIsLoading, resvModifyIsSuccess, resvModifyIsError);

        handleCloseConfirm();
    });

    // const handleDrop = () => {
    //     console.log('Request', dropRequestUserMessage);
    //     handleCloseDropinForm()
    //     handleCloseConfirm();
    // };

    const handleEditDates = methods.handleSubmit(async (data) => {
        if (!user || !data.check_in || !data.check_out || !data.id) {
            return;
        }

        const putResponse = await sendReservationModifyPut({
            id: data.id,
            check_in: data.check_in,
            check_out: data.check_out
        });
        // console.log('after', putResponse, 'objects', resvModifyData, resvModifyError, 'states', resvModifyIsUninitialized, resvModifyIsLoading, resvModifyIsSuccess, resvModifyIsError);

        handleCloseConfirm();
    });
    if (!reservation) {
        return null
    }

    const values = methods.watch()
    return (
        <>
            {/* EDIT & CANCEL Confirmed */}
            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title={confirmDialogTitle}
                content={confirmDialogContent}
                action={
                    <FormProvider methods={methods} onSubmit={handleEditDates}>
                        <LoadingButton loading={methods.formState.isSubmitting || confirmButtonLoading} disableRipple variant="contained" type={confirmAction === 'edit_confirm' ? 'submit' : 'button'} color="error" sx={{ mr: 1 }} onClick={async (e) => {
                            e.stopPropagation()
                            setConfirmButtonLoading(true)
                            if (confirmAction === 'cancel') {
                                await handleCancel().catch((cancelError: any) => {
                                    console.error(cancelError)
                                });
                            }
                            if (confirmAction === 'drop') {
                                await handleDrop(reservation.id).catch((dropError: any) => {
                                    console.error(dropError)
                                });
                            }
                            setConfirmButtonLoading(false)
                        }}>
                            Confirm
                        </LoadingButton>
                    </FormProvider>} />
            {/* Table menu popover */}
            <MenuPopover
                open={openPopover}
                onClose={handleCloseConfirm}
                arrow="left-top"
                sx={{ width: 'auto', height: 'fit-content' }}
            >
                {(reservation.guest_is_owner && reservation.guest_type === ReservationGuestType.Owner)
                    ?
                    <>
                        <MenuItem onClick={() => handleOpenConfirm('edit')} sx={{ color: theme => theme.palette.primary.main }}>
                            <Iconify icon="eva:edit-2-outline" />Change Dates
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => handleOpenConfirm('cancel')} sx={{ color: theme => theme.palette.error.main }}>
                            <Iconify icon="eva:trash-2-outline" />Cancel Booking
                        </MenuItem>
                    </>
                    :
                    <MenuItem onClick={handleOpenDropinForm} sx={{ color: theme => theme.palette.primary.main }}>
                        <Iconify icon="eva:car-outline" />Request Drop-in
                    </MenuItem>
                }
            </MenuPopover>
            {/* Change Date & Confirm */}
            <ConfirmDialog
                open={openEditDatePicker}
                onClose={handleCloseDatePicker}
                title={confirmDialogTitle}
                content={
                    <FormProvider methods={methods} onSubmit={handleEditDates}>
                        <DatePickerForm listingId={reservation.listing_id} ignoredOwnerReservation={reservation} />
                    </FormProvider>
                }
                action={
                    <Button
                        disabled={values.check_in === null || values.check_out === null}
                        variant="contained"
                        onClick={() => {
                            handleOpenConfirm('edit_confirm');
                        }}
                        sx={{ mr: 1 }}
                    >
                        Change Dates
                    </Button>} />
        </>
    );
}

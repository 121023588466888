import {
  useContext,
  useReducer,
  createContext,
  useRef,
  PropsWithChildren,
} from "react"

enum EventProviderActionKind {
  CLIENT_ONLINE = 'CLIENT/ONLINE',
  CLIENT_OFFLINE = 'CLIENT/OFFLINE',
  CLIENT_VISIBLE = 'CLIENT/VISIBLE',
  CLIENT_INVISIBLE = 'CLIENT/INVISIBLE',
}

interface EventProviderState {
  online: boolean,
  visible: boolean,
}

interface EventProviderAction {
  type: EventProviderActionKind;
}

export function eventReducer(state: EventProviderState, action: EventProviderAction) {
  switch (action.type) {
    case EventProviderActionKind.CLIENT_ONLINE:
      return {
        online: true,
        visible: state.visible,
      }
    case EventProviderActionKind.CLIENT_OFFLINE:
      return {
        online: false,
        visible: state.visible,
      }
    case EventProviderActionKind.CLIENT_VISIBLE:
      return {
        online: state.online,
        visible: true,
      }
    case EventProviderActionKind.CLIENT_INVISIBLE:
      return {
        online: state.online,
        visible: false,
      }
    default:
      return state
  }
}

const INITIAL_STATE = {
  online: true,
  visible: true,
}

const WindowEventContext = createContext(INITIAL_STATE)

export default function WindowEventProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(eventReducer, INITIAL_STATE)
  const initialized = useRef(false)

  const handleFocus = () => dispatch({ type: EventProviderActionKind.CLIENT_VISIBLE })
  const handleFocusLost = () => dispatch({ type: EventProviderActionKind.CLIENT_INVISIBLE })
  const handleOnline = () => dispatch({ type: EventProviderActionKind.CLIENT_ONLINE })
  const handleOffline = () => dispatch({ type: EventProviderActionKind.CLIENT_OFFLINE })

  const handleVisibilityChange = () => {
    if (window.document.visibilityState === 'visible') {
      handleFocus()
    } else {
      handleFocusLost()
    }
  }

  if (!initialized.current) {
    if (typeof window !== 'undefined' && window.addEventListener) {
      // Handle focus events
      window.addEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false
      )
      window.addEventListener('focus', handleFocus, false)

      // Handle connection events
      window.addEventListener('online', handleOnline, false)
      window.addEventListener('offline', handleOffline, false)
      initialized.current = true
    }
  }

  return (
    <WindowEventContext.Provider value={state}>
      {children}
    </WindowEventContext.Provider>
  )
}

export function useWindowEvents() {
  return useContext(WindowEventContext)
}
import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CLOUD_FUNCTIONS } from "../../auth/FirebaseContext";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import { StandardErrorFallback } from "../../components/error-handling/StandardErrorFallback";
import { useSettingsContext } from "../../components/settings";
import { CheckEnvironment, SupportedEnvironments } from "../../config-global";
import { EmergencyFallbackErrorText } from "../../utils/mrr/errorHandling";
import CrashableChild from "./CrashableChild";
import { PATH_HOME } from "../../routes/paths";

export interface TestPageProps {
    unused: boolean[];
}

export default function TestPage( { unused }: TestPageProps) {
    // console.log('render test page w/ props: ' + unused);

    const { themeStretch } = useSettingsContext();

    const [devJSXCrashEnabled, setDevJSXCrashEnabled] = useState(false);
    const [showSimulatedErrorPage, setShowSimulatedErrorPage] = useState(false);

    const onTestAButtonClick = async () => {
        console.log('test A button clicked');

        const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, 'alwaysWarmEndpoint');

        await cloudFunction({})
            .then(async (result) => {
                console.log('always-warm result: ', result);
            })
            .catch((error) => {
                console.log('always-warm error: ', error);
            });
    }

    const onTestBButtonClick = () => {
        console.log('test B button clicked');

        throw new Error('Test page intentional throw.');
    }

    const brokenObject: any = { no: 'function'};

    const onTestCButtonClick = () => {
        console.log('test C button clicked');

        brokenObject.reduce((acc: any, curr: any) => acc + curr); // intentional bad invoke
    }

    const onTestDButtonClick = () => {
        console.log('test D button clicked');

        setDevJSXCrashEnabled(true);
    }

    const onTestEButtonClick = () => {
        console.log('test E button clicked');

        setShowSimulatedErrorPage(true);
    }

    if (!CheckEnvironment(SupportedEnvironments.Dev)) {
        // failsafe, this page doesn't have a prod route
        return null;
    }

    return (
        <>
            {showSimulatedErrorPage
            ?   (<StandardErrorFallback reportToSentry={false} errorInfo={null} customErrorMessage={EmergencyFallbackErrorText} />)
            :   (<>
                    <Helmet>
                        <title> DevTest | Guest Portal</title>
                    </Helmet>
                    <Container
                        maxWidth={
                            themeStretch
                                ? 'md'
                                : 'lg'
                        }
                    >
                        <CustomBreadcrumbs
                            heading="Dev"
                            links={[
                                { name: 'Home', href: PATH_HOME.root },
                                { name: 'Test' },
                            ]}
                        />

                        <LoadingButton loading={false} variant='outlined' onClick={onTestAButtonClick}>
                            Call Always-Warm
                        </LoadingButton>
                        <br />
                        <LoadingButton loading={false} variant='outlined' onClick={onTestBButtonClick}>
                            Basic Throw
                        </LoadingButton>
                        <br />
                        <LoadingButton loading={false} variant='outlined' onClick={onTestCButtonClick}>
                            Invoke Missing Fn
                        </LoadingButton>
                        <br />
                        <LoadingButton loading={false} variant='outlined' onClick={onTestDButtonClick}>
                            Force Crash in JSX
                        </LoadingButton>
                        <br />
                        <LoadingButton loading={false} variant='outlined' onClick={onTestEButtonClick}>
                            Show Fallback Error Component
                        </LoadingButton>
                        {devJSXCrashEnabled &&
                        <>
                            {
                                //FORCE THIS: "TypeError: g.map is not a function"
                                // brokenObject.map((v: any) => { return (<div>{v}</div>); }) // intentional bad invoke

                                //FORCE THIS: "TypeError: ??"
                                unused.map((v: any) => { return (<div>{v}</div>); }) // intentional bad invoke
                            }
                        </>
                        }
                        <CrashableChild />
                    </Container>
                </>)
            }
        </>
    )
}

//KEEP: Use this in index.tsx to let a url param drive sentry debug:
//
// const params = new URLSearchParams(window.location.search);
// const sentryDebug = params.get('sentry-debug');
// let sentryDebugEnabled = false;
// if (sentryDebug === 'true') {
//     // save this for the session
//     if (globalThis && !(globalThis as any)._debug_temp) {
//         console.log('globalThis setup in index');
//         (globalThis as any)._debug_temp = { savedSentryDebuggerSetting: true };
//     }
//     sentryDebugEnabled = true;
// }
// else if (globalThis && (globalThis as any)._debug_temp) {
//     sentryDebugEnabled = (globalThis as any)._debug_temp.savedSentryDebuggerSetting === true;
// }


// KEEP: Use this, e.g. in a test button callback, to toggle global test modes:
// if (globalThis) {
//     if ((globalThis as any)._debug_temp && (typeof (globalThis as any)._debug_temp.sentryTestEnabled === 'boolean')) {
//         (globalThis as any)._debug_temp.sentryTestEnabled = !(globalThis as any)._debug_temp.sentryTestEnabled;
//     }
//     else {
//         console.log('globalThis setup on test page');
//         (globalThis as any)._debug_temp = { sentryTestEnabled: true };
//     }
// }
//
// console.log('globalThis sentry test enabled: ', (globalThis as any)._debug_temp.sentryTestEnabled);
import { Button, ButtonProps, Typography } from "@mui/material"
import { NavLink } from "react-router-dom"
import Iconify from "../../../components/iconify"
import { StyledIcon } from "../../../components/nav-section/mini/styles"
import { ICONS } from "../../../layouts/dashboard/nav/config-navigation"
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths"
import { isExternalURL } from "../../../utils/mrr/url/url"

interface PortalNotificationActionAreaI extends ButtonProps {
    to?: string,
    text?: string,
}

export default function PortalNotificationActionArea({ text, to, sx, ...props }: PortalNotificationActionAreaI) {
    // const [openConfirm, setOpenConfirm] = useState(false)

    // const handleCloseConfirm = () => {
    //     setOpenConfirm(false)
    // }

    // const handleOpenConfirm = () => {
    //     setOpenConfirm(true)
    // }

    if (!to || !text) {
        return null
    }

    return (
        <>
            <NavButton
                sx={sx}
                text={text}
                to={to}
                {...props}
                navToExternalSite={isExternalURL(to)}
            // handleOpenConfirm={handleOpenConfirm}
            />
            {/* KEEP: We might want a confirm step on follow-link. If so, bring this back.
            <NavButton
                sx={sx}
                text={text}
                to={to}
                {...props}
                navToExternalSite={isExternalURL(to)}
                handleOpenConfirm={handleOpenConfirm}
            />
            <ConfirmDialog
                title={text}
                open={openConfirm}
                onClose={handleCloseConfirm}
                maxWidth='sm'
                content={
                    <Typography
                        variant="body2"
                        paragraph
                        maxWidth='sm'
                        overflow='hidden'
                        textOverflow='ellipsis'
                    >
                        {`You will be navigated to ${to}`}
                    </Typography>
                }
                action={
                    <NavButton
                        sx={{ ...sx, mr: 2 }}
                        text='Continue'
                        to={to}
                        {...props}
                        handleOpenConfirm={handleOpenConfirm}
                    />
                }
            />
            */}
        </>
    )
}


function NavButton({
    to,
    text,
    variant = 'contained',
    sx,
    size,
    // handleOpenConfirm,
    navToExternalSite = false
}
    : PortalNotificationActionAreaI & { navToExternalSite?: boolean }) {

    if (!to || !text) {
        return null
    }

    const matchedIcon = !navToExternalSite ? mapIcon(to) : undefined

    return <Button
        onClick={(e) => { e.stopPropagation() }}
        sx={sx}
        size={size}
        variant={variant}
        component={NavLink}
        to={to}
        target={navToExternalSite ? "_blank" : "_self"}
        startIcon={matchedIcon ? <StyledIcon>{matchedIcon}</StyledIcon> : <Iconify icon='eva:external-link-outline' />}
    >
        <Typography fontSize={size} whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
            {text}
        </Typography>
    </Button>
}

const parseRoot = (to: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const root = to.slice(0, to.indexOf('/', 1))
    return root
}

function mapIcon(to: string) {
    const toRoot = parseRoot(to)
    switch (toRoot) {
        case PATH_HOME.user.root:
            return ICONS.dashboard
        case PATH_HOME.general.app:
            return ICONS.dashboard
        case PATH_ADMIN.root:
            return ICONS.user
        case PATH_HOME.root:
            return ICONS.user
        default:
            return undefined
    }
}
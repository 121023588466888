import { TableCell, TableRow } from "@mui/material";
import { useMemo, useState } from "react";
import { TaskGeneral, TaskStatusMapped } from "../../../models/mrr/taskGeneral";
import MenuPopover from "../../../components/menu-popover/MenuPopover";
import { FDateFormat, fDate } from "../../../utils/formatTime";
import Label from "../../../components/label/Label";
import TaskDetails from "./TasksDetails";
import useResponsive from "../../../hooks/useResponsive";

type Props = {
    //   isSessionUser: boolean;
    row: TaskGeneral;
    selected: boolean;
    onViewRow: VoidFunction;
    onSelectRow: VoidFunction;
    onDeleteRow: VoidFunction;
    dense?: boolean
    showColumns: Set<keyof TaskGeneral>
};

export function TaskTableRow({ row, selected, onViewRow, onSelectRow, onDeleteRow, dense = true, showColumns }: Props) {

    const { assignee_name, status_mapped, dueDate, related_name, title, last_modified_date_ISO, unit_id } = row;

    const isDesktop = useResponsive('up', 'md')

    const dateFormat = useMemo(() => {
        if (isDesktop) {
            return FDateFormat.localized_date_time_full
        }
        return FDateFormat.localized_us_numeric_month_day_year
    }, [isDesktop])

    const [openConfirm, setOpenConfirm] = useState(false);

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const denseHeight = dense ? 52 : 72;

    return (
        <>
            <TableRow hover selected={selected} onClick={handleOpenPopover} sx={{ height: denseHeight }}>

                {showColumns.has('title') &&
                    <TableCell>
                        {title}
                    </TableCell>
                }
                {showColumns.has('status_mapped') &&
                    <TableCell>
                        <Label
                            variant="soft"
                            color={
                                //TODO: What should be mapped here? Once decided, move the color lookup to listingContants.ts.
                                (status_mapped === TaskStatusMapped.in_progress && 'primary') ||
                                (status_mapped === TaskStatusMapped.not_started && 'warning') ||
                                (status_mapped === TaskStatusMapped.completed && 'success') ||
                                (status_mapped === TaskStatusMapped.deffered && 'error') ||
                                'info'}>
                            {status_mapped}
                        </Label>
                    </TableCell>
                }
                {showColumns.has('assignee_name') &&
                    <TableCell>
                        {assignee_name}
                    </TableCell>
                }
                {showColumns.has('related_name') &&
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {isDesktop ? related_name || '-' : unit_id || '-'}
                    </TableCell>
                }
                {showColumns.has('dueDate') &&
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {fDate(dueDate, dateFormat)}
                    </TableCell>
                }
                {showColumns.has('last_modified_date_ISO') &&
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {fDate(last_modified_date_ISO, dateFormat)}
                    </TableCell>
                }
                {/* <TableCell align="right">
                <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                    <Iconify icon="eva:more-vertical-fill" />
                </IconButton>
            </TableCell> */}
            </TableRow>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                sx={{ width: 'auto' }}
            >
                <TaskDetails task={row} />
            </MenuPopover>
        </>
    );
}

import { useState } from "react"
import {
    Box,
    Collapse,
    Divider,
    Grid,
    Stack,
    Toolbar,
    Typography,
    alpha,
    styled
} from "@mui/material"
import { PortalNotification } from "../model/PortalNotification"
import Iconify from "../../components/iconify"
import { fToNow } from "../../utils/formatTime"
import Scrollbar from "../../components/scrollbar"

import { usePortalNotificationContext } from "../context/PortalNotificationProvider"
import "./PortalNotificationBanner.css"
import { MarkReadBtn } from "./sections/MarkReadBtn"
import { StyledBadge } from "./sections/StyledBadge"
import ViewButton from "./sections/ViewBtn"
import PortalNotificationActionArea from "./sections/PortalNotificationActionArea"

const NoticeRowStyle = {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
}

// master kill-switch for showing these banners
const pnBannersEnabled = true;

export default function PortalNotificationBanner() {
    const {
        showHighPriorityToolbar,
        showMediumPriorityToolbar,
        highPriorityPortalNotification,
        mediumPriorityPortalNotification,
        readIdSet
    } = usePortalNotificationContext()

    const [viewHighPriorityNewsletters, setViewHighPriorityNewsLetters] = useState(false)
    const handleHighPriorityViewButtonClick = () => {
        setViewMediumPriorityNewsLetters(false)
        setViewHighPriorityNewsLetters(prev => !prev)
    }
    const [viewMediumPriorityNewsletters, setViewMediumPriorityNewsLetters] = useState(false)
    const handleMediumPriorityViewButtonClick = () => {
        setViewHighPriorityNewsLetters(false)
        setViewMediumPriorityNewsLetters(prev => !prev)
    }

    const numUnreadHighPriority = highPriorityPortalNotification.filter((notification) => !readIdSet.has(notification.id)).length
    const numUnreadMediumPriority = mediumPriorityPortalNotification.length

    return (
        <>
            {pnBannersEnabled && showHighPriorityToolbar &&
                <Stack>
                    <NotificationBanner className="banner-priority-high">
                        <Stack
                            sx={NoticeRowStyle}
                            direction='row'
                        >
                            <Stack
                                spacing={2}
                                alignItems='center'
                                direction='row'
                                maxWidth={{
                                    xs: 280,
                                    sm: 400,
                                    md: 600,
                                    lg: 900
                                }}
                            >
                                {numUnreadHighPriority <= 1 &&
                                    <Iconify
                                        width={35}
                                        icon='eva:alert-triangle-outline'
                                    />
                                }
                                {numUnreadHighPriority > 1 &&
                                    <StyledBadge
                                        badgeContent={numUnreadHighPriority}
                                        color="warning"
                                    >
                                        <Iconify
                                            width={35}
                                            icon='eva:alert-triangle-outline' />
                                    </StyledBadge>
                                }
                                {/* {numUnreadHighPriority === 1 && <BannerIcon iconStr="eva:alert-triangle-outline" />} */}
                                <Typography
                                    variant="h6"
                                    overflow='hidden'
                                    textOverflow='ellipsis'
                                    textAlign='center'
                                    whiteSpace='nowrap'
                                    className="banner-text-color-white"
                                >
                                    {highPriorityPortalNotification.map((notification) => notification.subject).join(', ')}
                                </Typography>
                            </Stack>
                            <ViewButton onClick={handleHighPriorityViewButtonClick} open={viewHighPriorityNewsletters} />
                        </Stack>
                    </NotificationBanner>
                    <Scrollbar sx={{ maxHeight: '50vh' }}>
                        <Collapse
                            className="banner-uncollapsed"
                            in={viewHighPriorityNewsletters}
                        >
                            {highPriorityPortalNotification.map((notification) => (
                                <NotificationBannerRow
                                    divider
                                    notification={notification}
                                    key={`${notification.id}:high_priority_newsletter`}
                                />
                            ))}
                        </Collapse>
                    </Scrollbar>
                </Stack>
            }
            {pnBannersEnabled && showMediumPriorityToolbar &&
                <Stack>
                    <NotificationBanner
                        className="banner-priority-medium banner-text-color-white"
                    >
                        <Stack
                            sx={NoticeRowStyle}
                            direction='row'
                        >
                            <Stack
                                spacing={2}
                                alignItems='center'
                                direction='row'
                                maxWidth={{
                                    xs: 280,
                                    sm: 400,
                                    md: 600,
                                    lg: 900
                                }}
                            >
                                {numUnreadMediumPriority <= 1 &&
                                    <Iconify
                                        width={35}
                                        icon='eva:alert-circle-outline'
                                    />
                                }
                                {numUnreadMediumPriority > 1 && <StyledBadge
                                    badgeContent={numUnreadMediumPriority}
                                    color="warning"
                                >
                                    <Iconify
                                        width={35}
                                        icon='eva:alert-circle-outline'
                                    />
                                </StyledBadge>}
                                {/* {numUnreadMediumPriority === 1 && <BannerIcon iconStr='eva:alert-circle-outline' />} */}
                                <Typography
                                    variant="h6"
                                    overflow='hidden'
                                    textOverflow='ellipsis'
                                    textAlign='center'
                                    whiteSpace='nowrap'
                                >
                                    {mediumPriorityPortalNotification.map((notification) => notification.subject).join(', ')}
                                </Typography>
                            </Stack>
                            <ViewButton onClick={handleMediumPriorityViewButtonClick} open={viewMediumPriorityNewsletters} />
                        </Stack>
                    </NotificationBanner>
                    <Scrollbar sx={{ maxHeight: '50vh' }}>
                        <Collapse
                            in={viewMediumPriorityNewsletters}
                            className='banner-uncollapsed '
                            sx={(theme) => ({
                                bgcolor: theme.palette.common.white,
                                ...(viewMediumPriorityNewsletters &&
                                {
                                    borderBottom:
                                        `2px solid ${alpha(theme.palette.warning.light, .2)}`
                                })
                            })}
                        >
                            {mediumPriorityPortalNotification.map((notification) => (
                                <NotificationBannerRow
                                    divider
                                    notification={notification}
                                    key={`${notification.id}:high_priority_newsletter`}
                                />
                            ))}
                        </Collapse>
                    </Scrollbar>
                </Stack>
            }
        </>
    )
}

//-------------------------

const NotificationBanner = styled(Toolbar, {
})(() => ({
    '& .MuiSvgIcon-root': {
        right: 4,
    },
    height: 45,
}))

function NotificationBannerRow({ notification, divider }: { notification: PortalNotification, divider?: boolean }) {
    const { markRead, readIdSet, read } = usePortalNotificationContext()
    const {
        subject,
        message,
        id,
        last_modified_date_ISO,
        created_date_ISO,
        hasLink,
        actionLinkUrl,
        actionLinkText
    } = notification
    const handleRead = () => markRead([id])

    const markedRead = readIdSet.has(id) && new Date(read[id])
    const readRecipt = `Read ${markedRead
        ? fToNow(new Date(read[id]))
        : last_modified_date_ISO !== null
            ? fToNow(last_modified_date_ISO)
            : fToNow(created_date_ISO)}
                `

    return (
        <Box
            sx={{ w: 1 }}
        >
            <Stack
                alignItems='flex-start'
                justifyContent='flex-start'
                direction='column'
                width='100%'
                p={2}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    width='100%'
                    justifyContent='space-between'
                >
                    <Typography
                        variant="h6"
                        className="banner-text-color-primary"
                    >
                        {subject}
                    </Typography>
                </Stack>
                <Stack
                    alignItems='row'
                    spacing={1}
                    width='100%'
                >
                    <Typography
                        variant="body1"
                        className="banner-text-color-primary"
                    >
                        {message}
                    </Typography>

                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item>
                            {hasLink &&
                                <PortalNotificationActionArea
                                    to={actionLinkUrl}
                                    text={actionLinkText}
                                />
                            }
                        </Grid>
                        {!markedRead &&
                            <Grid item>
                                <MarkReadBtn read={handleRead} />
                            </Grid>
                        }
                        {markedRead &&
                            <Grid
                                item
                                gap={.5}
                                display='flex'
                                justifyContent='flex-end'
                                alignItems='center'
                                direction='row'
                            >
                                <Iconify
                                    color='success.main'
                                    icon='eva:done-all-outline'
                                    width='12px'
                                />
                                <Typography
                                    variant="caption"
                                    p={0}
                                    m={0}
                                    color='text.disabled'
                                >
                                    {readRecipt}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Stack>
            </Stack >
            {divider && <Divider sx={{ borderStyle: 'dotted' }} />}
        </Box>
    )
}


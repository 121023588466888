import { httpsCallable } from 'firebase/functions';
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useGetRTKQCasesQuery, useGetRTKQListingsQuery, useGetRTKQReservationsQuery } from 'src/redux/rtkQuery/apiSlice';
import { Roles } from '../../assets/data';
import { CLOUD_FUNCTIONS } from '../../auth/FirebaseContext';
import { useAuthContext } from '../../auth/useAuthContext';
import { cloud_getAllUserRecordsAsAdmin, cloud_getKnownUserRecord } from '../../utils/mrr/cloudFunctions';
import LoadingScreen from '../loading-screen/LoadingScreen';
import { ListingGeneral } from 'src/models/mrr/listingGeneral';
import { earliestPossibleDate } from 'src/utils/mrr/presetTimeRange';

// ----------------------------------------------------------------------

//NOTE: These can't change every render, or RTK Query will keep fetching!!
//      Try something like 'if Date.now() - 1 hour is > dateToday, set a new today'
// const occFilterEnd = new Date();
// const occFilterStart = new Date(occFilterEnd.getFullYear(), 0, 1);

const maxPreloadTime = 5 * 1000; //TODO: Make this an ENV build param.
const verboseTrackPreload = false; // keep this until the feature is proved out on staging
const verboseTrackingPrefix = '[PRELOADER] ';

const enableAdminPrefetch = false;	// This is a work-in-progress. It would be nice for admins
// to have certain calls pre-fectched, but it produces nasty
// errors when signing out as an admin-assumed session.

type PreloadModelType = {
	earliestSelectableDate: Date | null,
	hasProperties: boolean,
	hasSingleProperty: boolean,
	ownerListingIds: string[] | null
};

const initialModel = {
	earliestSelectableDate: null,
	hasProperties: false,
	hasSingleProperty: false,
	ownerListingIds: null
};

export const PreloadContext = createContext<PreloadModelType>(initialModel);


export default function PreloaderProvider({ children }: PropsWithChildren) {
	//TODO: We customize pre-fetch based on role. For example, admins don't
	//		need to prefetch listings (and shouldn't, because it's an error).
	const { user } = useAuthContext();

	let adminMode = false;
	if (user && user.role !== Roles.User) {
		// console.warn('preload in admin mode for role ' + user.role);
		adminMode = true;
	}
	// console.warn('preload admin mode: ' + adminMode);

	const [preloadWindowOpen, setPreloadWindowOpen] = useState(true);
	const [adminModeFetching, setAdminModeFetching] = useState(false);

	// const [outgoingModel, setOutgoingModel] = useState<PreloadModelType>(initialModel);

	useEffect(() => {
		if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'effect hit'); }

		if (!user) {
			if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'user not ready'); }
			return () => { };
		}

		if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'create timer'); }


		const preloadTimerId = setTimeout(() => {
			if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'tick'); }
			if (preloadWindowOpen) {
				if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'close window in timer'); }
				setPreloadWindowOpen(false);
			}
			else {
				// eslint-disable-next-line no-lonely-if
				if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'miss (window already closed)'); }
			}
		}, maxPreloadTime);

		return () => {
			if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'cleanup'); }
			clearTimeout(preloadTimerId);
		};
	}, [preloadWindowOpen, user]);

	// fetch anything here we want cached asap

	// ---- BEGIN ADMIN PREFETCH ----
	const fetchAllUsersData = useCallback(async () => {
		if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'admin user data being fetched'); }

		//NOTE: These results are ignored. We're just here to improve cold-starts in GCF.

		const getAllCloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_getAllUserRecordsAsAdmin);
		await getAllCloudFunction();

		const getKnownCloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_getKnownUserRecord);
		await getKnownCloudFunction({ email: user?.email });

		//TODO: This should include the edit-user endpoint, which is also likely to be a cold-start.

		setAdminModeFetching(false);
	}, [user?.email]);

	useEffect(() => {
		if (!enableAdminPrefetch || !adminMode) {
			return;
		}

		if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'admin use effect triggered'); }

		setAdminModeFetching(true);

		const executeFetch = async () => {
			await fetchAllUsersData();
		};

		executeFetch();
	}, [adminMode, fetchAllUsersData]);
	// ---- END ADMIN PREFETCH ----

	// ---- BEGIN NON-ADMIN PREFETCH ----
	const {
		data: listingsData,
		error: listingsError,
		isError: isListingsError,
		isFetching: isListingsFetching,
		isSuccess: isListingsSuccess
	} = useGetRTKQListingsQuery({}, { skip: adminMode }); // an "args" param is required; "options" is optional

	const {
		data: reservationsData,
		error: reservationsError,
		isError: isReservationsError,
		isFetching: isReservationsFetching,
		isSuccess: isReservationsSuccess
	} = useGetRTKQReservationsQuery({}, { skip: adminMode }); // an "args" param is required; "options" is optional

	const {
		data: casesData,
		error: casesError,
		isError: isCasesError,
		isFetching: isCasesFetching,
		isSuccess: isCasesSuccess
	} = useGetRTKQCasesQuery({}, { skip: adminMode }); // an "args" param is required; "options" is optional
	// ---- END NON-ADMIN PREFETCH ----

	const anythingStillFetching = adminMode
		? adminModeFetching
		: isListingsFetching || isReservationsFetching || isCasesFetching;

	if (preloadWindowOpen && !anythingStillFetching) {
		if (verboseTrackPreload) { console.log(verboseTrackingPrefix + 'close window in body'); }

		setPreloadWindowOpen(false);
	}

	const renderComponent = () => {
		if (preloadWindowOpen) {
			return <LoadingScreen />;
		}

		return children;
	};

	const preloaderState = useMemo(() => {
		if (!isListingsSuccess || !Array.isArray(listingsData)) {
			return initialModel;
		}

		let earliestSelectableDate = null;
		let hasProperties = false;
		let hasSingleProperty = false;
		let ownerListingIds = null;

		hasProperties = listingsData.length > 0;
		hasSingleProperty = listingsData.length === 1;


		// perform our one-time listing processing

		// set the owner's earliest effective date (once)
		const timeNow = Date.now();
		let timeEarliestDateFound = timeNow;
		listingsData.forEach((l: ListingGeneral) => {
			if (!l.effective_date_ISO) {
				return;
			}
			const dateEffective = l.effective_date_ISO;
			const timeEffective = dateEffective.getTime();
			if (timeEffective >= timeEarliestDateFound) {
				return;
			}
			timeEarliestDateFound = timeEffective;
		});

		if (timeEarliestDateFound !== timeNow) {
			earliestSelectableDate = new Date(timeEarliestDateFound);
		}
		else {
			// failsafe to ensure one-time preprocessing
			earliestSelectableDate = earliestPossibleDate;
		}

		// save a sorted list of the owner's listings' Ids (once)

		const listingIds = listingsData.map((v: ListingGeneral, i: number) => {
			if (v.unit_id) {
				return v.unit_id;
			}

			// we use fallbacks, because these fields are not always present

			if (v.name) {
				return v.name;
			}

			if (v.id) {
				return v.id;
			}

			return 'Listing ' + String.fromCharCode(65 + i); // fallback to 'A', 'B', ...
		});

		// ensure unique (which will also sort)
		const listingIdSet = new Set<string>(listingIds);

		const listingIdsOut = Array.from(listingIdSet);

		ownerListingIds = listingIdsOut;

		const outgoingModel = {
			earliestSelectableDate: earliestSelectableDate,
			hasProperties: hasProperties,
			hasSingleProperty: hasSingleProperty,
			ownerListingIds: ownerListingIds
		};

		return outgoingModel;
	}, [isListingsSuccess, listingsData]);

	return (
		<PreloadContext.Provider value={preloaderState}>
			{renderComponent()}
		</PreloadContext.Provider>
	);
}

import { Skeleton, Stack, Typography } from '@mui/material';

interface LoadingDetailsI {
    numRows: number;
}
export function LoadingDetails(props: LoadingDetailsI) {
    const { numRows } = props;

    const buildRows = new Array(numRows).fill('Details Loading...');

    return (
        <Stack spacing={2} p={2}>
            {buildRows.map((val, i) => (
                <Skeleton width='100%' variant='text' key={`loading_detail_${i}`}>
                    <Typography variant='body2'>{val}</Typography>
                </Skeleton>
            ))}
        </Stack>
    );
}

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './datePickerStyles.css';

// -------------------------------
import {
    Button,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import moment, { Moment } from "moment-timezone";
import { useState } from "react";
import {
    CalendarDayShape,
    DateRangePicker,
    FocusedInputShape
} from 'react-dates';
import { useFormContext } from 'react-hook-form';
import useResponsive from "../../../../hooks/useResponsive";
import DatePickerDay from './DatePickerDay';
import Iconify from '../../../../components/iconify';
import { brandConfig } from '../../../../config';

const minDate = moment().subtract(1, 'days')
const maxDate = moment().add(2, 'years')

export interface DatePickerProps {
    blockedDates: { check_in: Date, check_out: Date, sfID: String }[],
    startDateName: string,
    endDateName: string
}

moment.tz.setDefault(brandConfig.timezone);
// now with our custom box states
export function ReservationDatePicker({
    blockedDates,
    startDateName,
    endDateName }: DatePickerProps) {
    const { watch, setError, setValue, clearErrors } = useFormContext()
    const startDate = watch(startDateName)
    const endDate = watch(endDateName)

    const clearDates = () => {
        clearErrors()
        setStartDate(null)
        setEndDate(null)
    }
    const [focusedInput, setFocusInput] = useState<FocusedInputShape | null>(null)
    const theme = useTheme()
    document.documentElement.style.setProperty('--picker-background', theme.palette.background.paper)
    document.documentElement.style.setProperty('--picker-primary', theme.palette.primary.main)
    document.documentElement.style.setProperty('--picker-color', theme.palette.text.primary)
    document.documentElement.style.setProperty('--picker-outline', theme.palette.divider)
    const isFullscreen = useResponsive('down', 'sm')

    const numberOfMonths = isFullscreen ? 3 : 2
    const orientation = !isFullscreen ? 'horizontal' : 'vertical'

    const startDateForDateRangePicker = startDate ? moment(startDate) : null
    const endDateForDateRangePicker = endDate ? moment(endDate) : null

    const existingReservationsCheckIns = blockedDates.map((date) => date.check_in)
    const existingReservationsCheckOuts = blockedDates.map((date) => date.check_out)

    const selectionComplete = startDate !== null && endDate !== null
    const selectActive = startDate !== null && endDate === null

    const checkSelectionInBlockedDates = (start: Moment | null, end: Moment | null) => {
        if (start === null && end === null) {
            return false
        }

        // do these dates land on a reservation, or surround a reservation
        for (let i = 0; i < blockedDates.length; i++) {
            const { check_in, check_out } = blockedDates[i]
            if (start) {
                if (start.isBetween(check_in, check_out)) {
                    return true
                }
                if (start.isSame(check_in, 'day')) {
                    return true
                }
            }
            if (end) {
                if (end.isBetween(check_in, check_out)) {
                    return true
                }
                if (end.isSame(check_out, 'day')) {
                    return true
                }
            }
            if (start && end) {
                // do we span a reservation?
                if (start.isBefore(check_in) && end.isAfter(check_out)) {
                    return true
                }
            }
        }
        return false
    }

    const setStartDate = (a: Moment | null) => {
        const value = a ? a.toDate() : null
        setValue(startDateName, value)
    }
    const setEndDate = (a: Moment | null) => {
        const value = a ? a.toDate() : null
        setValue(endDateName, value)
    }

    return <DateRangePicker
        readOnly
        keepFocusOnInput={false}
        isOutsideRange={(day) => {
            const isBeforeToday = day.isBefore(new Date, 'day')

            if (isBeforeToday) {
                return true
            }
            return false
        }}
        isDayBlocked={(day) => {
            let isBlockedCheckin = false
            let isBlockedCheckOut = false
            if (day && existingReservationsCheckIns.find((compDate) =>
                day.isSame(compDate, 'day'))) {
                isBlockedCheckin = true
            }
            if (day && existingReservationsCheckOuts.find((compDate) =>
                day.isSame(compDate, 'day'))) {
                isBlockedCheckOut = true
            }
            if (isBlockedCheckin && isBlockedCheckOut) {
                // End of one reservation and start of the next
                return true
            }

            const blockingReservation = blockedDates.find((reservationBounds) => {
                return day.isBetween(
                    reservationBounds.check_in,
                    reservationBounds.check_out
                )
            })
            return !!blockingReservation
        }} // best for conflicting reservations
        small={isFullscreen}
        withPortal
        withFullScreenPortal={isFullscreen}
        numberOfMonths={numberOfMonths}
        hideKeyboardShortcutsPanel
        firstDayOfWeek={0} // starts on sunday
        minDate={minDate}
        maxDate={maxDate}
        showClearDates
        showDefaultInputIcon
        startDatePlaceholderText='Check In'
        startDate={startDateForDateRangePicker} // momentPropTypes.momentObj or null,
        startDateAriaLabel='Check In'
        startDateId={startDateName} // PropTypes.string.isRequired,
        endDatePlaceholderText='Check Out'
        endDateAriaLabel='Check Out'
        endDate={endDateForDateRangePicker} // momentPropTypes.momentObj or null,
        endDateId={endDateName} // PropTypes.string.isRequired,
        onDatesChange={({
            startDate: inputStartDate,
            endDate: inputEndDate
        }) => {
            clearErrors()
            const isBlocked = checkSelectionInBlockedDates(inputStartDate, inputEndDate)
            // console.log('blocked', isBlocked)
            if (isBlocked) {
                setValue(startDateName, null)
                setValue(endDateName, null)
                setFocusInput('startDate')
                setError(endDateName, { message: 'Selection blocked by another booking. Please try again.' })
                return
            }
            setStartDate(inputStartDate ? inputStartDate : null)
            setEndDate(inputEndDate ? inputEndDate : null)
        }} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(x) => {
            // if dialog is closed (i.e. focusedInput is null) and user clicked checkout date, override to focus startDate.
            if (x === 'endDate' && focusedInput === null) {
                x = 'startDate'
            }
            setFocusInput(x)

        }} // PropTypes.func.isRequired
        orientation={orientation}
        screenReaderInputMessage='Select a check in and out date.'
        renderNavPrevButton={(props) => {
            if (orientation === 'horizontal') {
                return (
                    <Button size='large' color='primary' {...props} sx={{ position: 'fixed', left: '10%' }} variant='outlined'>
                        <Iconify icon='ic:outline-arrow-back' />
                    </Button>
                )
            }
            return (
                <Button color='primary' size='large' {...props}
                    sx={{
                        '&:disabled': {
                            backgroundColor: theme.palette.text.secondary, // Change background color when disabled
                            color: theme.palette.text.disabled, // Change text color when disabled
                        },
                        position: 'fixed',
                        bottom: 0,
                        width: '50%',
                        borderRadius: 0
                    }} variant='contained'>
                    <Iconify icon='ic:outline-arrow-upward' />
                </Button>
            )
        }}
        renderNavNextButton={(props) => {
            if (orientation === 'horizontal') {
                return (
                    <Button size='large' color='primary' {...props} sx={{ position: 'fixed', right: '10%' }} variant='outlined'>
                        <Iconify icon='ic:outline-arrow-forward' />
                    </Button>
                )
            }
            return (
                <Button color='primary' size='large' {...props} sx={{ position: 'fixed', bottom: 0, right: 0, width: '50%', borderRadius: 0 }} variant='contained'>
                    <Iconify icon='ic:outline-arrow-downward' />
                </Button>
            )
        }}
        // renderMonthText={(month) => <Typography color='text.primary'>{month.format('MMMM YYYY')}</Typography>}
        renderCalendarDay={({ day, ...props }) => {
            let isBlockedCheckin = false
            let isBlockedCheckOut = false
            if (day) {
                if (existingReservationsCheckIns.find((compDate) =>
                    day.isSame(compDate, 'day'))) {
                    isBlockedCheckin = true
                }
                if (existingReservationsCheckOuts.find((compDate) =>
                    day.isSame(compDate, 'day'))) {
                    isBlockedCheckOut = true
                }
            }
            // if we are focused on a start_date then intercept onDayClick
            if (focusedInput === 'startDate' && isBlockedCheckin) {
                props.onDayClick = ((_day, event) => {
                    event.preventDefault()
                    event.stopPropagation()
                })
            }
            if (focusedInput === 'endDate' && isBlockedCheckOut) {
                props.onDayClick = ((_day, event) => {
                    event.preventDefault()
                    event.stopPropagation()
                })
            }
            return <CalendarDay
                isBlockedCheckin={isBlockedCheckin}
                isBlockedCheckOut={isBlockedCheckOut}
                selectActive={selectActive}
                selectionComplete={selectionComplete}
                clearDates={clearDates}
                debugInfo={{
                    focusedInput,
                    startDate,
                    endDate,
                    // eslint-disable-next-line react/prop-types
                    modifiers: props.modifiers || null,
                    selectActive,
                    selectionComplete
                }}
                day={day}
                {...props} />
        }}
        weekDayFormat='ddd'
        renderWeekHeaderElement={(week) =>
            <Typography
                color='text.primary'
                fontSize={10}
                textAlign='center'
                textOverflow='ellipsis'
                width={40}
                whiteSpace='nowrap'
            >
                {week}
            </Typography>
        }
        renderDayContents={(day, modifiers) => {
            const textColor = 'inherit'
            return <Stack justifyContent='center' alignItems='center'>
                <Typography
                    textAlign='center'
                    variant='body2'
                    fontWeight={modifiers.has('today')
                        ? 'bold'
                        : 'normal'}
                    sx={{ color: textColor }}
                >
                    {day.format('D')}
                </Typography>
            </Stack >
        }
        }
    />
}

interface CalendarDayI extends CalendarDayShape {
    isBlockedCheckin: boolean,
    isBlockedCheckOut: boolean,
    selectActive: boolean,
    selectionComplete: boolean,
    clearDates: VoidFunction
}

function CalendarDay({
    day,
    daySize,
    isOutsideDay,
    modifiers,
    isFocused,
    tabIndex,
    onDayClick,
    onDayMouseEnter,
    onDayMouseLeave,
    renderDayContents,
    ariaLabelFormat,
    phrases,
    isBlockedCheckin,
    isBlockedCheckOut,
    selectActive,
    selectionComplete,
    clearDates,
    debugInfo,
}: CalendarDayI & { debugInfo: any }) {

    if (!day || !modifiers) {
        return <DatePickerDay isValid={false} />
    }
    //------ Event handlers --------
    const onClick = onDayClick
        ? (event: any) => {
            onDayClick(day, event)
        }
        : undefined

    const onMouseEnter = onDayMouseEnter
        ? (event: any) => {
            // console.log("cell debugInfo", debugInfo)
            onDayMouseEnter(day, event)
        }
        : undefined

    const onMouseLeave = onDayMouseLeave
        ? (event: any) => {
            onDayMouseLeave(day, event)
        }
        : undefined

    //------------

    const isToday = modifiers.has('today')
    const isValid = modifiers.has('valid')
    const isFirstDayOfWeek = modifiers.has('first-day-of-week')
    const isLastDayOfWeek = modifiers.has('last-day-of-week')
    const isBlocked = modifiers.has('blocked')
    const isBlockedCalendar = modifiers.has('blocked-calendar')
    const isBlockedOutOfRange = modifiers.has('blocked-out-of-range')
    const isBlockedByMinNights = modifiers.has('blocked-minimum-nights')
    const isSelectedStart = modifiers.has('selected-start')
    const noSelectedEnd = modifiers.has('selected-start-no-selected-end')
    const isSelectedEnd = modifiers.has('selected-end')
    const isSelectedSpan = modifiers.has('selected-span')
    const isHovered = modifiers.has('hovered')
    const isHoveredSpan = modifiers.has('hovered-span')
    const isHighlighted = modifiers.has('highlighted-calendar')

    const Content = renderDayContents ? renderDayContents(day, modifiers) : null

    return (
        <DatePickerDay
            day={day}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            tabIndex={tabIndex}
            isToday={isToday}
            isValid={isValid}
            isFirstDayOfWeek={isFirstDayOfWeek}
            isLastDayOfWeek={isLastDayOfWeek}
            isBlocked={isBlocked}
            isBlockedCalendar={isBlockedCalendar}
            isBlockedOutOfRange={isBlockedOutOfRange}
            isBlockedByMinNights={isBlockedByMinNights}
            isSelectedStart={isSelectedStart}
            noSelectedEnd={noSelectedEnd}
            isSelectedEnd={isSelectedEnd}
            isSelectedSpan={isSelectedSpan}
            isHovered={isHovered}
            isHoveredSpan={isHoveredSpan}
            isFocused={isFocused}
            isBlockedCheckIn={isBlockedCheckin}
            isBlockedCheckOut={isBlockedCheckOut}
            selectActive={selectActive}
            selectionComplete={selectionComplete}
            isHighlighted={isHighlighted}
        >
            {Content}
        </DatePickerDay>
    )
}

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Iconify from '../../../../components/iconify';
import { Upload } from '../../../../components/upload';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  title?: string;
  onCreate?: VoidFunction;
  onUpdate?: VoidFunction;
  folderName?: string;
  onChangeFolderName?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  open: boolean;
  busy: boolean;
  supportedExtensionsDesc: string;
  supportedExtensionsRegEx: RegExp;
  fileCountLimit: number;
  onClose: VoidFunction;
  onUpload(a: (File | string)[]): Promise<void>;
}

export default function FileNewFolderDialogWithFilter({
  title = 'Upload Files',
  onClose,
  onCreate,
  onUpdate,
  folderName,
  onChangeFolderName,
  open,
  busy,
  supportedExtensionsDesc,
  supportedExtensionsRegEx,
  fileCountLimit,
  onUpload,
  ...other
}: Props) {
  const [files, setFiles] = useState<(File | string)[]>([]);

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {

      const filtered = acceptedFiles.filter((file) => {
        const fileAllowed = supportedExtensionsRegEx.test(file.name);
        if (!fileAllowed) {
          console.log('blocking file', file);
        }
        return fileAllowed;
      });

      const newFiles = filtered.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      let proposedFileSet = [...files, ...newFiles];

      if (proposedFileSet.length > fileCountLimit) {
        //NOTE: Keeping the end of the array feels more natural to the user.
        //      When you drag a file in, you expect it to replace the one that's there.
        console.log('too many files (' + proposedFileSet.length +'); keeping latest ' + fileCountLimit);
        proposedFileSet = proposedFileSet.slice(-fileCountLimit);
      }

      setFiles(proposedFileSet);
    },
    [fileCountLimit, files, supportedExtensionsRegEx]
  );

  const handleUpload = async () => {
    await onUpload(files)
    onClose();
  };

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 1, 3) }}> {title} </DialogTitle>
      <Typography variant='body1' sx={{ p: (theme) => theme.spacing(1, 3, 2, 3) }}> {supportedExtensionsDesc} </Typography>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {(onCreate || onUpdate) && (
          <TextField
            fullWidth
            label="Folder name"
            value={folderName}
            onChange={onChangeFolderName}
            sx={{ mb: 3 }}
          />
        )}

        <Upload
          multiple
          files={files}
          disabled={busy}
          busy={busy}
          onDrop={handleDrop}
          onRemove={handleRemoveFile}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          onClick={handleUpload}
          disabled={Array.isArray(files) && files.length === 0}
          loading={busy}
        >
          Upload
        </LoadingButton>

        {!!files.length && (
          <Button disabled={busy} variant="outlined" color="inherit" onClick={handleRemoveAllFiles}>
            Remove all
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            <Button variant="soft" onClick={onCreate || onUpdate}>
              {onUpdate ? 'Save' : 'Create'}
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}

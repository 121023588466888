import { Button } from "@mui/material";
import Iconify from "../../../components/iconify";

interface ViewButtonProps {
    onClick: VoidFunction,
    open: boolean
}

export default function ViewButton({
    onClick,
    open
}: ViewButtonProps) {
    const evaIcon = open ? 'eva:arrow-ios-upward-outline' : 'eva:arrow-ios-downward-outline'
    return (
        <Button
            className="banner-text-color-white"
            onClick={onClick}
            variant="text"
            startIcon={<Iconify icon={evaIcon} />}
            sx={{
                height: '35px',
                whiteSpace: 'nowrap'
            }}
        >
            View
        </Button>
    )
}
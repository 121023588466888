export enum ReservationStage {
    Confirmed = 'confirmed',
    // Pending = 'pending',
    Canceled = 'canceled',
    Internal_Unknown = 'unknown' //! This should never be displayed. It indicates a bad model.
};

// UPDATE, EXPLAIN THE CHANGE (on main) TO CONFIRMED-ONLY; filter resvs in rtkq

// This is used for the title on the reservation table
export const RESERVATION_STAGE_OPTIONS: string[] = ['confirmed'];

const stageKeys = Object.keys(ReservationStage);

// for (let i = 0; i < stageKeys.length; ++i) {
//     if (stageKeys[i] === 'Internal_Unknown') {
//         // leave this one out of the UI
//         continue;
//     }
//     RESERVATION_STAGE_OPTIONS.push(stageKeys[i].toLowerCase());
// }

import { parseISO } from "date-fns"
import { isAbsoluteURL, isExternalURL } from "../../utils/mrr/url/url";
//NOTE: A CheckEnvironment method is required!
const ENVIRONMENT = process.env.REACT_APP_ENV;

function CheckEnvironment() {
    if (!ENVIRONMENT) {
        throw new Error('Please add environment to env file.')
    }
    return ENVIRONMENT?.toLowerCase() === 'development'
}

type PortalNameT = 'Owner' | 'Ohana' | 'Guest'
const PortalName: PortalNameT = 'Owner'

function AssertPortalType(type: NewsletterType) {
    if (PortalName === 'Owner') {
        if (type !== NewsletterType.Owner) {
            throw new Error('Incompatable notification recived.')
        }
        return true
    }
    if (PortalName === 'Ohana') {
        if (type !== NewsletterType.Internal) {
            throw new Error('Incompatable notification recived.')
        }
        return true
    }
    if (PortalName === 'Guest') {
        if (type !== NewsletterType.Guest) {
            throw new Error('Incompatable notification recived.')
        }
        return true
    }
    throw new Error('Notifications misconfigured')
}

export enum PriorityType {
    'High' = 'High',
    'Medium' = 'Medium',
    'Low' = 'Low'
}

export enum NewsletterType {
    'Guest' = 'Guest',
    'Internal' = 'Internal',
    'Owner' = 'Owner'
}

// { [date.str]: PortalNotification['id'] }
export type ReadPortalNotification = Record<PortalNotification['id'], string>

export class PortalNotification {
    //? ISO dates are not use on the Map written to firestore. <UTC>
    created_date_ISO: Date

    last_modified_date_ISO: Date

    priority_sort_order: number

    actionLinkUrl: string = ''

    actionLinkText: string = ''

    hasLink: boolean = false

    constructor(
        public readonly active: boolean,
        public readonly id: string,
        public readonly priority: PriorityType,
        public readonly subject: string,
        public readonly message: string,
        private readonly _action_link_url: string,
        private readonly _action_link_text: string,
        private readonly _created_date_utc: string,
        private readonly _type: NewsletterType,
        private readonly _last_modified_date_utc: string,
    ) {
        !CheckEnvironment() ?? AssertPortalType(this._type)

        if (this._action_link_url) {
            this.hasLink = true
            this.actionLinkUrl = PortalNotification.parseActionURL(this._action_link_url)
            if (!this._action_link_text) {
                this.actionLinkText = 'More Info'
            } else {
                this.actionLinkText = this._action_link_text
            }
        }
        this.created_date_ISO = parseISO(this._created_date_utc)
        this.last_modified_date_ISO = parseISO(this._last_modified_date_utc)
        this.priority_sort_order = this.priority === PriorityType.High ? 0 : this.priority === PriorityType.Medium ? 1 : 2
    }

    private static parseActionURL(actionUrl: string) {

        if (isAbsoluteURL(actionUrl)) {
            return actionUrl;
        }

        if (actionUrl.startsWith('/')) {
            // the author provided us a leading slash
            return actionUrl;
        }

        return '/' + actionUrl;
    }
}

export function InstantiatePortalNotificationFromJSON(json: any) {
    return new PortalNotification(
        json.active,
        json.id,
        json.priority,
        json.subject,
        json.message,
        json.action_link_url,
        json.action_link_text,
        json.created_date_utc,
        json.type,
        json.last_modified_date_utc,
    )
}
import Stack from '@mui/material/Stack';
import { PropsWithChildren } from 'react';

interface CenteredStackProps extends PropsWithChildren {
    spacing?: number;
}

export function CenteredStack({ children, spacing }: CenteredStackProps) {
    return (
        <Stack alignItems='center' spacing={spacing} marginY={2}>
            {children}
        </Stack>
    );
}

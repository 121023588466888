import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { TableCell, TableRow, Link, Typography, Stack } from "@mui/material";
import ListingOrderItemGeneral, { ListingOrderItemStatus } from "../../../models/mrr/loi";
import MenuPopover from "../../../components/menu-popover/MenuPopover";
import { PATH_HOME } from "../../../routes/paths";
import Label from "../../../components/label/Label";
import LoiDetails from "../../@dashboard/loi/LoiDetails";
import { FDateFormat, fDate } from "../../../utils/formatTime";
import useResponsive from "../../../hooks/useResponsive";
import { fCurrency } from "../../../utils/formatNumber";

type Props = {
    singleListing: boolean;
    row: ListingOrderItemGeneral;
    selected: boolean;
    onViewRow: VoidFunction;
    onSelectRow: VoidFunction;
    onDeleteRow: VoidFunction;
    dense?: boolean
    showColumns: Set<keyof ListingOrderItemGeneral>
};

export function LoiTableRow({
    singleListing,
    row,
    selected,
    onViewRow,
    onSelectRow,
    onDeleteRow,
    dense = true,
    showColumns
}: Props) {

    const {
        title,
        status,
        name_detailed,
        // type_plus_subtype,
        sub_type,
        listing_id,
        last_modified_date_ISO,
        unit_id,
        amount,
        discount,
        total_amount,
        tax_amount,
        end_date_ISO
    } = row;

    const denseHeight = dense ? 52 : 72;

    const isDesktop = useResponsive('up', 'md')
    const isMobile = useResponsive('down', 'sm')
    // const dateFormat = useMemo(() => {
    //     if (isDesktop) {
    //         return FDateFormat.localized_date_time_full
    //     }
    //     return FDateFormat.localized_us_numeric_month_day_year
    // }, [isDesktop])

    const [openConfirm, setOpenConfirm] = useState(false);

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    return (
        <>
            <TableRow hover selected={selected} onClick={handleOpenPopover} sx={{ height: denseHeight }}>
                {!singleListing && showColumns.has('name_detailed') &&
                    <TableCell>
                        <Link color='primary' component={NavLink} to={PATH_HOME.property.view(listing_id)}>
                            {isDesktop ? name_detailed : unit_id}
                        </Link>
                    </TableCell>
                }
                {showColumns.has('title') && isMobile &&
                    <TableCell sx={{ maxWidth: { xs: 75 }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {title}
                    </TableCell>
                }
                {showColumns.has('title') && !isMobile &&
                    <TableCell>
                        {title}
                    </TableCell>
                }
                {showColumns.has('amount') &&
                    <TableCell>
                        {/* {fCurrency(amount, true)
                            + " + "
                            + fCurrency(tax_amount, true)
                            + " = "
                            + fCurrency(total_amount)
                        } */}
                        {discount > 0 &&
                            <Stack>
                                <Stack direction='row'>
                                    <Typography
                                        variant="caption"
                                        color='text.disabled'
                                        sx={{ textDecoration: 'line-through' }}>
                                        {fCurrency(amount, true)}
                                    </Typography>
                                    {/* <Typography
                                        variant="caption"
                                        color='success.main'
                                        sx={{ ml: 1 }}>
                                        {fCurrency(amount, true)}
                                    </Typography> */}
                                </Stack>
                                <Typography
                                    variant="body2"
                                >{fCurrency(total_amount, true)}</Typography>
                            </Stack>
                        }
                        {!discount &&
                            <Typography
                                variant="body2"
                            >
                                {fCurrency(total_amount, true)}
                            </Typography>
                        }
                    </TableCell>
                }
                {showColumns.has('status') &&
                    <TableCell>
                        <Label
                            variant="soft"
                            color={
                                (status === ListingOrderItemStatus.approved && 'primary') ||
                                (status === ListingOrderItemStatus.close && 'warning') ||
                                'info'
                            }>
                            {status}
                        </Label>
                    </TableCell>
                }
                {showColumns.has('sub_type') &&
                    <TableCell>
                        {sub_type}
                    </TableCell>
                }
                {showColumns.has('last_modified_date_ISO') &&
                    <TableCell>
                        {fDate(last_modified_date_ISO, FDateFormat.localized_us_numeric_month_day_year)}
                    </TableCell>
                }
                {showColumns.has('end_date_ISO') &&
                    <TableCell>
                        {fDate(end_date_ISO, FDateFormat.localized_us_numeric_month_day_year)}
                    </TableCell>
                }
                {/* <TableCell align="right">
                    <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
                </TableCell> */}
            </TableRow>
            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-center"
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                sx={{ width: 'auto' }}
            >
                <LoiDetails loi={row} />
            </MenuPopover>
        </>
    );
}

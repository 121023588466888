import { Card, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { addDays, isBefore, isSameDay } from 'date-fns';
import { useCallback } from 'react';
import Chart, { useChart } from '../../../../components/chart';
import { CheckEnvironment, SupportedEnvironments } from '../../../../config-global';
import { ReservationGeneral } from '../../../../models/mrr/reservationGeneral';
import { ONE_DAY_MS, fDate, yearChartMonthLabels } from '../../../../utils/formatTime';
import filterCanceledReservations from '../../../../utils/mrr/filterCanceledReservation';
import { ReservationStage } from '../../../../utils/mrr/reservationConstants';

// ----------------------------------------------------------------------
// We will always show days, with one column for each week.
// X - A date as, 'MMM, DD'
// y - The aggregate price for that date
// name - Day of week

const daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function reservationOnThisDay(reservation: ReservationGeneral, dayToCheck: Date) {

    // return true if a day in this reservation matches (EXCEPT the last day, which is check-out)

    let cursorDate = reservation.check_in_ISO;
    const endDate = reservation.check_out_ISO;
    while (isBefore(cursorDate, endDate)) {
        if (isSameDay(cursorDate, dayToCheck)) {
            return true;
        }
        cursorDate = addDays(cursorDate, 1);
    }

    return false;
}

function BuildHeatmapData(
    reservations: ReservationGeneral[],
    startDate: Date,
    endDate: Date
) {
    // Sanity check for bad reservation states. We've hit this in the past, and it
    // could come back as reservation queries keep changing.
    if (CheckEnvironment(SupportedEnvironments.Dev)) {
        reservations.forEach((r, i) => {
            if (r.stage_mapped !== ReservationStage.Confirmed) {
                throw new Error('HeatMap counting unconfirmed reservation');
            }
        });
    }

    //TODO: These should be ApexAxisChartSeries, but that type is maddeningly
    //      difficult to create (and it shouldn't be; feels broken). If you
    //      have tons of time, try to set it.
    const seriesArray: any[] = [];

    //NOTE: IMPORTANT:
    //  Working with the HeapMap, you may break something, and your samples disappear.
    //  If that happens, it might be due to this (very strange) problem:
    //  When ApexCharts/Minimal/React decides you've got a bad configuration, it gets
    //  stuck in a state that does not allow the chart. Really stuck - cycle browser
    //  and VSCode, and it can still be stuck! If you see a Chrome warning 'invalid
    //  chart type', that's it.
    //
    //  FIX: Set the series to these test values. I don't know why, but that gets
    //       you out of the bad state. Fix your config, and you can view your
    //       samples again. SHRUG.
    //
    //KEEP: Switching to these repairs the stuck state!
    // if (true) {
    //     const series = [{name: "Series 1",data: [{x: 'W3',y: 22},{x: 'W1',y: 10}]}];
    //     return series;
    // }

    //TODO: Lift this out, and make sure it's pulling from the theme!
    const colorSamples = '#0088ff';

    seriesArray.push({ name: daysOfWeek[0], color: colorSamples, data: [] });
    seriesArray.push({ name: daysOfWeek[1], color: colorSamples, data: [] });
    seriesArray.push({ name: daysOfWeek[2], color: colorSamples, data: [] });
    seriesArray.push({ name: daysOfWeek[3], color: colorSamples, data: [] });
    seriesArray.push({ name: daysOfWeek[4], color: colorSamples, data: [] });
    seriesArray.push({ name: daysOfWeek[5], color: colorSamples, data: [] });
    seriesArray.push({ name: daysOfWeek[6], color: colorSamples, data: [] });

    let cursorTime = startDate.getTime();

    const endOfYearTime = endDate.getTime();

    const getReservationsThatIncludeDay = (dayTime: number) => {
        const filterDay = new Date(dayTime);
        const filtered = reservations.filter((reservation, i) => {
            return reservationOnThisDay(reservation, filterDay)
        });
        return filtered;
    };

    // loop over the year, day-by-day, creating a sample for each day
    while (cursorTime <= endOfYearTime) {
        let value = 0;
        const dayOfWeekName = fDate(cursorTime, 'E', true);
        const dayOfWeekIndex = daysOfWeek.indexOf(dayOfWeekName);
        const reservationsThatIncludeThisDay = getReservationsThatIncludeDay(cursorTime);
        if (reservationsThatIncludeThisDay.length > 0) {
            reservationsThatIncludeThisDay.forEach((resv) => { value += resv.nightly_rate; });
        }
        const valueRounded = Math.round(value);
        seriesArray[dayOfWeekIndex].data.push({ x: fDate(cursorTime, 'E PP', true), y: valueRounded });

        cursorTime += ONE_DAY_MS;
    }

    return seriesArray as ApexAxisChartSeries;
}

export interface ChartHeatMapYearProps {
    reservations: ReservationGeneral[];
    startDate: Date;
    endDate: Date;
    filterCanceled?: boolean
}

const verboseLogging = false;
function createHtmlString(date: string, value: number) {
    //TODO: Idealy this should be a proper React component. Not sure this tech will
    //      let us pass one in, but it's worth investigating.
    return "<div style=\"padding: 10px\";>" + date + "<br /><span><b>" + (value > 0 ? ('$' + value) : 'No Bookings') + "</b></span></div>";
}

type ValueBounds = { empties: number, min: number, max: number };

export default function ChartHeatMapYear(props: ChartHeatMapYearProps) {
    const {
        reservations: unfilteredReservations,
        startDate,
        endDate,
        filterCanceled = true
    } = props;
    const theme = useTheme()
    const enableResize = useMediaQuery(theme.breakpoints.up('xs'))
    const reservations = filterCanceled ? filterCanceledReservations(unfilteredReservations) : unfilteredReservations

    //KEEP: It's handy in debug to have these ordered.
    // reservations = reservations.sort((a, b) => a.check_in_ISO.getTime() - b.check_in_ISO.getTime());

    const valueBounds: ValueBounds = { empties: 0, min: 0, max: 0 };
    const buildHeatMapData = useCallback(() => BuildHeatmapData(reservations, startDate, endDate), [reservations, startDate, endDate])
    const series = buildHeatMapData()
    series.forEach(s => {
        s.data.forEach((d: any) => {
            if (d.y > valueBounds.max) {
                valueBounds.max = d.y;
            }

            if (d.y < valueBounds.min) {
                valueBounds.min = d.y;
            }

            if (d.y <= 0) {
                ++valueBounds.empties;
            }
        })
    })

    // console.log('heatmap analysis', valueBounds);

    if (verboseLogging) {
        // console.warn('HEATMAP DATA', series)
        console.table(series);
    }
    const chartOptions = useChart({
        stroke: {
            colors: [theme.palette.background.paper]
        },
        labels: yearChartMonthLabels,
        xaxis: {
            type: 'datetime',
            offsetX: 14,
            sorted: true,
            labels: { datetimeUTC: false }
        },
        legend: {
            show: false // No need to see 'Booked' and 'Unbooked'
        },
        tooltip: {
            custom: (options) => {

                //NOTE: The options object is very convoluted, but this really is how to read it:
                const sampleInfo = options.w.config.series[options.seriesIndex].data[options.dataPointIndex];
                const date = sampleInfo.x;
                const value = sampleInfo.y;
                return createHtmlString(date, value);
            }
        },
        plotOptions: {
            heatmap: {
                distributed: false,   // don't see a difference
                radius: 0,
                enableShades: true,
                shadeIntensity: 1.0,
                colorScale: {
                    ranges: [
                        {
                            from: Number.MIN_SAFE_INTEGER,
                            to: 0,
                            color: theme.palette.background.neutral,
                            name: 'Unbooked',
                        },
                        {
                            from: 1,
                            to: Math.max(1, valueBounds.max), // the darkest shade will be the max sample
                            color: theme.palette.primary.main,
                            name: 'Booked',
                        }
                    ]
                }
            },
        },
        chart: {
            type: "heatmap",
        },
    })
    return (
        <Stack>
            <Card sx={{ minWidth: theme.breakpoints.values.xs, p: 2 }}>
                <Typography variant='subtitle2'>Booking Heatmap</Typography>
                <Chart type="heatmap" series={series} options={chartOptions} height='100%' width={!enableResize ? theme.breakpoints.values.sm : '100%'} />
            </Card>
        </Stack>
    )
}

import { Dispatch, SetStateAction, useState } from 'react';
// @mui
import {
    Button,
    Grid,
} from '@mui/material';
// utils
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// import { fToNow } from '../../../utils/formatTime';
import MenuPopover from '../../../components/menu-popover';
import { PATH_PAGE } from '../../../routes/paths';
import { useAuthContext } from '../../../auth/useAuthContext';
import useResponsive from '../../../hooks/useResponsive';
import { SignInAsOtherUserForm, SignInAsOtherUserResolver } from '../../../pages/dashboard/SignInAsOtherUserResolver';

// ----------------------------------------------------------------------
interface ReturnToAdminPopoverI {
}

export default function ReturnToAdminPopover(props: ReturnToAdminPopoverI) {
    const navigate = useNavigate()

    const isMobile = useResponsive('down', 'sm')

    const { user } = useAuthContext()

    const methods = useForm({
        resolver: yupResolver(SignInAsOtherUserResolver),
        defaultValues: { user: null },
        mode: 'onChange'
    })

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleLogOut = async () => {
        handleClosePopover()
        //[[USER LOGOUT]]
        navigate(PATH_PAGE.logout, { replace: true })
    };
    if (!user) {
        return null
    }
    //? This is to display a select drop down to sign in as another user
    //! currently not going with this ui decision but might circle back.
    const showForm = false

    return (
        <>
            <Button disableRipple variant='text' size='small' onClick={handleOpenPopover}>{isMobile ? user.name : `Signed In As ${user.name}`}</Button>
            <MenuPopover open={openPopover} onClose={handleClosePopover}>
                <Grid container spacing={2} px={2} direction='column' alignItems='center'>
                    {/*
                    Note that we can not yet use this sign in drop down until a route is created to get all users without admin
                */}
                    {showForm ?
                        <Grid item>
                            <SignInAsOtherUserForm methods={methods} currentUser={user} handleLogOut={handleLogOut} />
                        </Grid>
                        :
                        <Grid item>
                            <Button disableRipple fullWidth variant='text' onClick={handleLogOut}>
                                Log Out
                            </Button>
                        </Grid>}
                </Grid>
            </MenuPopover>
        </>
    );
}
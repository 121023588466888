import { Box, Stack, Drawer } from '@mui/material';
import { useContext, useMemo } from 'react';
import useResponsive from '../../../hooks/useResponsive';
import { CheckEnvironment, NAV, SupportedEnvironments } from '../../../config-global';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import navigationConfig, { navConfigAdmin, navDemo } from './config-navigation';
import NavAccount from './NavAccount';
import NavToggleButton from './NavToggleButton';
import { useAuthContext } from '../../../auth/useAuthContext';
import { Roles, allowRoleToSeeDevTab, routeRoleAsAdmin } from '../../../assets/data';
import { PATH_HOME } from '../../../routes/paths';
import { PreloadContext } from '../../../components/preloader/PreloaderProvider';
import { getSessionRole } from '../../../utils/mrr/emulator/signInAsOtherUser';
// import { ADMIN_STORED_IN_SESSION_ROLE } from '../../../auth/FirebaseContext';

// ----------------------------------------------------------------------

const filterForTimeshareUsers = ['properties', 'calendar', 'cases']

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

const devMode = CheckEnvironment(SupportedEnvironments.Dev)

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useAuthContext()

  const preloadState = useContext(PreloadContext);

  const { hasProperties, hasSingleProperty } = preloadState

  const originRole = getSessionRole()

  const sessionCanSeeAdminPages = routeRoleAsAdmin(user?.role);
  const sessionCanSeeDevTab = allowRoleToSeeDevTab(originRole)
  const isDesktop = useResponsive('up', 'lg');

  const handleMobileMenuItemClick = () => {
    if (isDesktop) {
      return;
    }
    onCloseNav()
  }
  const navConfig = useMemo(() => {
    // do not mutate navigationConfig
    const configForNavigation = Array.from(navigationConfig)
    if (!user?.role || user.role !== Roles.User) {
      return configForNavigation
    }
    if (hasProperties) {
      return configForNavigation
    }
    // Filter out Properties / My Property, Calendar, Cases and (all) Reports.
    const timeshareGeneralGroups = []
    for (let menuGroup = 0; menuGroup < navigationConfig.length; ++menuGroup) {
      const group = navigationConfig[menuGroup]
      if (group.subheader !== 'general') {
        continue;
      }
      const newGroupItems = [...group.items].filter((menuIem) => !filterForTimeshareUsers.includes(menuIem.title))
      const newGroup = { subheader: group.subheader, items: newGroupItems }
      timeshareGeneralGroups.push(newGroup)

    }
    return timeshareGeneralGroups
  }, [hasProperties, user])

  let foundPropertiesMenuItem = false;
  for (let menuGroup = 0; !foundPropertiesMenuItem && menuGroup < navConfig.length; ++menuGroup) {
    const group = navConfig[menuGroup];
    if (group.subheader !== 'general') {
      continue;
    }
    for (let groupItem = 0; groupItem < group.items.length; ++groupItem) {
      if (group.items[groupItem].path !== PATH_HOME.property.root) {
        continue;
      }

      // This needs to be reset both ways, in case a single-property user logs out then a multi-property logs in.
      //NOTE: That would be rare in the wild, but could be common to Support users.
      group.items[groupItem].title = hasSingleProperty ? 'my property' : 'properties'; // the menu capitalizes these
      foundPropertiesMenuItem = true;
      break;
    }
  }

  let navData = sessionCanSeeAdminPages ? navConfigAdmin : navConfig

  if (
    devMode // env var is development
    && sessionCanSeeDevTab // will check session users role to be sure they are a developer
  ) {
    navData = [...navData, ...navDemo]
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >

      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo full />

        <NavAccount />

      </Stack>

      <NavSectionVertical data={navData} handleCloseDrawer={handleMobileMenuItemClick} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

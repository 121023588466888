import { getYear, isValid, parseISO, } from "date-fns"
import { ListingGeneral } from "./mrr/listingGeneral"

export class Statement {
    public readonly transferred_date_ISO: Date | null = null
    public readonly start_date_ISO: Date
    public readonly end_date_ISO: Date
    public listing: ListingGeneral | null = null
    constructor(
        public readonly id: string,
        public readonly amount: number,
        public readonly account_name: string,
        public readonly account_id: string,
        public readonly type: string,
        private readonly _transferred_date: string,
        private readonly _start_date: string,
        private readonly _end_date: string,
        public readonly payout_type?: string,
    ) {
        if (isValid(new Date(this._transferred_date))) {
            this.transferred_date_ISO = parseISO(this._transferred_date)
        }
        this.start_date_ISO = parseISO(this._start_date)
        this.end_date_ISO = parseISO(this._end_date)
    }

    assignListing(listing?: ListingGeneral) {
        if (!listing) return
        this.listing = listing
    }

    get startDate() {
        return this._start_date
    }
    get endDate() {
        return this._end_date
    }
}

export class StatementListBase {
    public readonly start_date_ISO: Date
    public readonly end_date_ISO: Date
    public readonly statement_years_reported: string[]
    public readonly statment_account_names_reported: string[]
    constructor(
        private readonly _start_date: string,
        private readonly _end_date: string,
        public readonly report_type: string,
        public readonly statements: Statement[] = []
    ) {
        this.start_date_ISO = parseISO(this._start_date)
        this.end_date_ISO = parseISO(this._end_date)

        let years_reported: string[] = []
        let account_names_reported: string[] = []

        statements.forEach((statement) => {
            years_reported = [...years_reported, String(getYear(statement.end_date_ISO))]
            account_names_reported = [...account_names_reported, statement.account_name]
        })

        this.statement_years_reported = Array.from(new Set(years_reported))
        this.statment_account_names_reported = Array.from(new Set(account_names_reported))
    }
}


function instantiateStatementFromJSON(json: any) {
    return new Statement(
        json.id,
        json.amount,
        json.account_name,
        json.account_id,
        json.type,
        json.transferred_date,
        json.start_date,
        json.end_date,
        json.payout_type,
    )
}


export function instantiateStatementListFromJSON(json: any) {
    const statementModels = json.statements.map((statementJSON: any) => instantiateStatementFromJSON(statementJSON))
    return new StatementListBase(
        json.start_date,
        json.end_date,
        json.report_type,
        statementModels
    )
}

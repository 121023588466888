// User
export const cloud_confirmOneTimePassword = 'confirmOneTimePassword';
export const cloud_editUserRecord = 'editUserRecord';
export const cloud_emailOneTimePassword = 'emailOneTimePassword';
export const cloud_getSignInMethods = 'getSignInMethods';
export const cloud_resendWelcomeEmail = 'resendWelcomeEmail';
export const cloud_updateUserBrand = 'updateUserBrand';
export const cloud_userResetPassword = 'userResetPassword';
export const cloud_userSignInViaEmail = 'userSignInViaEmail';
export const cloud_userSignUp = 'userSignUp';
export const cloud_verifyEmailWithExternalStore = 'verifyEmailWithExternalStore';

// Admin
export const cloud_createUserAsAdmin = 'createUserAsAdmin';
export const cloud_deleteUser = 'deleteUser';
export const cloud_getKnownUserRecord = 'getKnownUserRecord';
export const cloud_sendSignInLinkEmail = 'sendSignInLinkEmail';
export const cloud_signInAsOtherUser = 'signInAsOtherUser';
export const cloud_verifyEmailAsAdmin = 'verifyEmailAsAdmin';

// Api
export const cloud_getAllCasesForAccount = 'getAllCasesForAccount';
export const cloud_getAllListingsForAccount = 'getAllListingsForAccount';
export const cloud_getAllListingLOIsForAccount = 'getAllListingLOIsForAccount';
export const cloud_getAllReservationsForAccount = 'getAllReservationsForAccount';
export const cloud_getAllTasksForAccount = 'getAllTasksForAccount';
export const cloud_getAllUserRecordsAsAdmin = 'getAllUserRecordsAsAdmin';
export const cloud_getOwnerReport = 'getOwnerReport';
export const cloud_getOwnerStatements = 'getOwnerStatements';
export const cloud_getObjectAttachments = 'getObjectAttachments';
export const cloud_getPortalNotification = 'getPortalNotification';
export const cloud_getSingleCase = 'getSingleCase'; // NOT IN USE
export const cloud_getSingleListing = 'getSingleListing';
export const cloud_getSingleReservation = 'getSingleReservation';
export const cloud_getSingleTask = 'getSingleTask'; // NOT IN USE
export const cloud_getStatement = 'getStatement';
export const cloud_patchLMI = 'patchLMI';
export const cloud_postCaseComment = 'postCaseComment';
export const cloud_postCOIPdf = 'postCOIPdf';
export const cloud_postReservation = 'postReservation';
export const cloud_postReservationChangeRequest = 'postReservationChangeRequest';
export const cloud_putReservationCancel = 'putReservationCancel';
export const cloud_putReservationUpdate = 'putReservationUpdate';

export const cloud_makeSalesforceRequest = 'makeSalesforceRequest';

// This creates an array of string Ids that meet the following rules:
//  - No empty-string Ids.
//  - No duplicate Ids.
//
// Those that don't pass are thrown out. If the output is empty, it throws.
export function assembleUniqueIds(inputIds: string | string[]) {
  let outputIds: string[] = [];
  const idSet = new Set<string>();

  if (Array.isArray(inputIds)) {
    outputIds = outputIds.concat(inputIds);
  }
  else {
    outputIds.push(inputIds);
  }

  // ensure no duplicates and no empties
  for (let i = 0; i < outputIds.length; ++i) {
    if (outputIds[i] === '') {
      console.warn('empty Id in subset; removed');
      outputIds.splice(1, i);
      --i;
      continue;
    }

    if (idSet.has(outputIds[i])) {
      console.warn('duplicate Id in subset; removed');
      outputIds.splice(1, i);
      --i;
      continue;
    }

    //TODO: Invoke an optional, boolean callback here: 'validateId(outputIds[i])'

    idSet.add(outputIds[i]);
  }

  if (inputIds.length <= 0) {
    throw new Error('no valid Ids in input');
  }

  return outputIds;
};

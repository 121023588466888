import { Navigate, useLocation } from 'react-router-dom';
import { PATH_ADMIN, PATH_HOME } from '../routes/paths';
import { Roles } from '../assets/data';
import { ADMIN_STORED_IN_SESSION_ROLE } from './FirebaseContext';

// ----------------------------------------------------------------------
type AuthGuardProps = {
  children: React.ReactNode;
};

// We use "admin" as our path for protected pages, but there is no technical
// relation between these. It could be any string.
const developerPathNames = ['/demo', '/user'];

export default function DeveloperGuard({ children }: AuthGuardProps) {
  const { pathname } = useLocation();

  // console.log('AUTH GUARD', user, user ? user.role : 'n/a', pathname);
  const sessionRole = window.sessionStorage.getItem(ADMIN_STORED_IN_SESSION_ROLE)
  if (sessionRole !== Roles.Developer) {
    return <Navigate to={PATH_HOME.root} replace />
  }

  if (developerPathNames.findIndex((path) => pathname.startsWith(path)) === -1) {
    // this role can only visit admin and developer routes
    return <Navigate to={PATH_ADMIN.user.list} replace />
  }

  return <> {children} </>;
}

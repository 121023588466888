import { styled } from "@mui/material";
import { CellColors, ColorType, DayProps, MapColorsToDays, SetBlockingColors, SetSelected } from "./colorUtils";

const DatePickerDay = styled('td')<DayProps>(
    ({ theme, ...dayProps }) => {
        // Styles
        const dayWidth = 38;
        const dayHeight = 38;
        const boxSizing = 'border-box';

        const initialColors = new CellColors()

        // if (dayProps.isValid) {
        //     console.warn("break", dayProps)
        // }
        const colors: ColorType = {
            reservedColor: theme.palette.text.secondary,
            selectedColor: theme.palette.primary.lighter,
            defaultColor: theme.palette.background.paper,
            textOutOfRangeColor: theme.palette.text.disabled,
            textColor: 'inherit'
        }
        SetBlockingColors(dayProps, initialColors, colors)
        SetSelected(dayProps, initialColors, colors)

        const isBlockedCheckInAndCheckOut = dayProps.isBlockedCheckIn && dayProps.isBlockedCheckOut

        return {
            width: dayWidth,
            height: dayHeight,
            boxSizing: boxSizing,
            ...(!dayProps.isBlockedOutOfRange && isBlockedCheckInAndCheckOut && {
                backgroundImage: `url(/assets/datePicker/EndOfReservationAndStartOfNext_${theme.palette.mode}.svg)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }),
            ...(!isBlockedCheckInAndCheckOut && { background: MapColorsToDays(initialColors, colors) }),
            ...(dayProps.isHovered
                && !dayProps.isBlockedCalendar
                && !(dayProps.isBlockedCheckIn && dayProps.isBlockedCheckOut)
                && {
                //TODO: There is a bug here; minor visual issue that fails to hover a check-out day.
                //      The fix is to prevent check-out days from being 'blocked-calendar'.

                // border: `1px inset ${Color.textOutOfRangeColor}`
                border: `1px solid #368`
            })
        }
    })

export default DatePickerDay
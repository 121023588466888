import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';

import apiMessage from './slices/apiMessage';

//TODO: If we keep this (here), fix the cycle.

// eslint-disable-next-line import/no-cycle
import { apiSlice } from './rtkQuery/apiSlice';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  apiMessage: apiMessage,
  product: persistReducer(productPersistConfig, productReducer),
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default rootReducer;

// theme
import palette from '../../theme/palette';
//
import { ThemeColorPresetsValue } from './types';

// ----------------------------------------------------------------------

const themePalette = palette('light');

export const presets = [
  //These elements will make up the selectable options for color presets
  // DEFAULT
  {
    name: 'default',
    lighter: '#4a82e3',
    light: '#2265d8',
    main: '#1b3664',
    dark: '#143c80',
    darker: '#0d2754',
    contrastText: '#FFFFFF',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#CCF4FE',
    light: '#68CDF9',
    main: '#078DEE',
    dark: '#0351AB',
    darker: '#012972',
    contrastText: '#FFFFFF',
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#FFFFFF',
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#a4c5df',
    light: '#7fadd2',
    main: '#5893C4',
    dark: '#3d7dae',
    darker: '#5996c5',
    contrastText: '#FFFFFF'
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: themePalette.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#FFFFFF',
  },
  // MRR GREEN
  {
    name: 'green',
    lighter: '#b2dcad',
    light: '#91cd89',
    main: '#6ebd64',
    dark: '#52a848',
    darker: '#418439',
    contrastText: '#FFFFFF',
  }
];

export const defaultPreset = presets[0];
export const cyanPreset = presets[1];
export const purplePreset = presets[2];
export const bluePreset = presets[3];
export const orangePreset = presets[4];
export const redPreset = presets[5];
export const greenPreset = presets[6];

export const presetsOption = presets.map((color) => ({
  name: color.name,
  value: color.main,
}));

export function getPresets(key: ThemeColorPresetsValue, themeMode: 'dark' | 'light') {
  if (themeMode === 'dark') {
    return {
      default: {
        name: 'default',
        lighter: '#a4c5df',
        light: '#7fadd2',
        main: '#5893C4',
        dark: '#3d7dae',
        darker: '#5996c5',
        contrastText: '#FFFFFF'
      },
      cyan: cyanPreset,
      purple: purplePreset,
      blue: {
        name: 'blue',
        lighter: '#4a82e3',
        light: '#2265d8',
        main: '#1b3664',
        dark: '#143c80',
        darker: '#0d2754',
        contrastText: '#FFFFFF',
      },
      orange: orangePreset,
      red: redPreset,
      green: greenPreset
    }[key];
  }
  return {
    default: defaultPreset,
    cyan: cyanPreset,
    purple: purplePreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    green: greenPreset
  }[key];
}

import { useEffect, useId, useState } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useSnackbar } from 'notistack';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { CLOUD_FUNCTIONS } from '../../auth/FirebaseContext';
import { InstantiateUserFromJSON, UserAccountGeneral } from '../../models/mrr/userAccountGeneral';
import { cloud_getAllUserRecordsAsAdmin } from '../../utils/mrr/cloudFunctions';
import { safelyReadErrorMessage } from '../../utils/mrr/errorHandling';
import FormProvider from '../../components/hook-form/FormProvider';
import { RHFAutocomplete } from '../../components/hook-form';
import signInAsOtherUser from '../../utils/mrr/emulator/signInAsOtherUser';
import { AuthUserType } from '../../auth/types';
import { Roles } from '../../utils/mrr/userConstants';
// import { ReservationGeneral } from '../../models/mrr/reservationGeneral';

interface SignInAsOtherUserFormI {
    methods: UseFormReturn<{
        user: null;
    }, any>;
    currentUser: AuthUserType;
    handleLogOut?: () => Promise<void>
}
// ----------------------------------------------------------------------
export const SignInAsOtherUserResolver = Yup.object().shape({
    user: Yup.object().shape({ label: Yup.string(), value: Yup.object() }).required("Please select a user to emulate."),
});
// ----------------------------------------------------------------------
export function SignInAsOtherUserForm(props: SignInAsOtherUserFormI) {
    const {
        methods, currentUser, handleLogOut
    } = props;
    const { handleSubmit, formState: { isValid } } = methods;

    const formId = useId();

    const [allUsers, setAllUsers] = useState<any[]>([]);

    const [waitingForData, setWaitingForData] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    const [isChangingUser, setIsChangingUser] = useState(false);

    // fetch our user data on load (one time only)
    useEffect(() => {
        if (!currentUser) {
            return
        }
        setWaitingForData(true);

        if (currentUser.role === Roles.User) {
            console.log('>>> RESOLVER: no fetch for this role:', currentUser.role, currentUser.displayName);
            return;
        }
        console.log('>>> RESOLVER: fetching users as', currentUser.role, currentUser.displayName);

/*KEEP
        const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_getAllUserRecordsAsAdmin);

        cloudFunction().then((result) => {
            if (!result) {
                throw new Error('missing all user records result');
            }
            if (!Array.isArray(result.data)) {
                throw new Error('non-array all user records data');
            }
            const usersModels: UserAccountGeneral[] = result.data.map((userJSON: any) => {
                const modelInstance = InstantiateUserFromJSON(userJSON);
                return modelInstance;
            })

            setAllUsers(usersModels)
        }).catch((error) => {
            enqueueSnackbar(safelyReadErrorMessage(null, error), { variant: 'error' })
        })

        setWaitingForData(false)
*/
    }, [waitingForData, currentUser, enqueueSnackbar]);

    const onSubmit = handleSubmit(async (target: any) => {
        setIsChangingUser(true);
        await signInAsOtherUser(false, currentUser?.id, target.user.id).then(() => {
            setIsChangingUser(false);
        }).catch((errorMessage) => {
            console.log(errorMessage);
            setIsChangingUser(false);
            enqueueSnackbar(errorMessage, { variant: 'error' });
        });
    });

    return (
        <FormProvider id={formId} methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2}>
                <Typography variant="h4" paragraph>
                    Switch User
                </Typography>
                <RHFAutocomplete
                    loading={waitingForData}
                    name='user'
                    options={allUsers.filter((user) => user.id !== currentUser?.id)}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    label='User' />
                <Grid container justifyContent={handleLogOut ? 'space-between' : 'flex-end'}>
                    {handleLogOut &&
                        <Grid item>
                            <Button variant='text' onClick={handleLogOut}>
                                Log Out
                            </Button>
                        </Grid>
                    }
                    <Grid item justifyContent='flex-end'>
                        <LoadingButton disabled={!isValid} type='submit' variant="contained" loading={isChangingUser}>
                            Sign In As User
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Stack>
        </FormProvider>
    );
}

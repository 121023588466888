// routes
import { PATH_ADMIN, PATH_HOME } from '../../../routes/paths';
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

export const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

export const navDemo = [
  {
    subheader: 'Development',
    items: [
      { title: 'widgets', path: '/demo/widgets', icon: ICONS.analytics },
    ]
  },
]

const navConfig = [
  //! It is very important that we maintain General as first in this array and properties as second in its items
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'dashboard', path: PATH_HOME.general.app, icon: ICONS.dashboard },
      // { title: 'timeshares', path: PATH_DASHBOARD.general.timeshare, icon: ICONS.calendar },
      { title: 'properties', path: PATH_HOME.property.root, icon: ICONS.banking }, //TODO: better icon
      { title: 'bookings', path: PATH_HOME.general.booking, icon: ICONS.booking },
      { title: 'statements', path: PATH_HOME.general.statements, icon: ICONS.file },
      { title: 'calendar', path: PATH_HOME.calendar, icon: ICONS.calendar },
      { title: 'cases', path: PATH_HOME.case.root, icon: ICONS.external }, //TODO: better icon
      // { title: 'booking heatmap', path: PATH_DASHBOARD.booking.heatmap, icon: ICONS.analytics },
    ],
  },
  // REPORTS
  //-----------------------------------------------------------------------
  {
    subheader: 'reports',
    items: [
      { title: 'occupancy', path: PATH_HOME.reports.occupancy, icon: ICONS.analytics },
      { title: 'revenue', path: PATH_HOME.reports.revenue, icon: ICONS.invoice },
      { title: 'booking', path: PATH_HOME.reports.bookings, icon: ICONS.folder },
    ]
  },
];

export default navConfig;

export const navConfigAdmin = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'admin',
    items: [
      // USER
      // {
      // title: 'user',
      // path: PATH_ADMIN.user.root,
      // icon: ICONS.user,
      // children: [
      // { title: 'profile', path: PATH_ADMIN.user.profile },
      // { title: 'cards', path: PATH_ADMIN.user.cards },
      { title: 'users', path: PATH_ADMIN.user.list, icon: ICONS.user },
      // { title: 'emulator', path: PATH_ADMIN.user.emulator },
      { title: 'create user', path: PATH_ADMIN.user.new, icon: ICONS.external },
      // { title: 'edit account', path: PATH_ADMIN.user.account },
      { title: 'Notifications', path: PATH_HOME.user.notifications, icon: ICONS.chat },
      // ],
      // },
    ],
  },
];

import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue, includeCents: boolean = false) {
  const format = number ? includeCents ? numeral(number).format('$0,0.00') : numeral(number).format('$0,0') : '$0';

  if (includeCents) {
    return format
  }

  return result(format, '.00');
}

export function fPercent(number: InputValue, integerOnly = false) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return integerOnly ? result(format, '') : result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fCondensedCurrency(value: number) {
  if (value < 1000) {
    return fCurrency(value)
  }

  if (value < 1000000) {
    return fCurrency(Math.floor(value / 1000)) + 'K'
  }

  return fCurrency(Math.floor(value / 1000000)) + 'M'
}

export function isAbsoluteURL(urlStr: string) {
    return urlStr.indexOf('http://') === 0 || urlStr.indexOf('https://') === 0;
};

export function isExternalURL(urlStr: string) {
    //WARNING: This will not work as expected on localhost!d

    //NOTE: This should decide whether a given url is internal. It does NOT validate the url, or even confirm
    //      whether an internal route exists. Use with caution.
    //
    // Example external:
    // "wikipedia.org"
    // "https://wikipedia.org"
    //
    // Example internal:
    // "owner.mrr.com/profile"
    // "https://owner.mrr.com/profile"

    const urlIsAbsolute = isAbsoluteURL(urlStr);

    let hostOfUrlStr = '';

    if (!urlIsAbsolute) {
        return false
    }

    hostOfUrlStr = (new URL(urlStr)).host;


    const currentHost = window.location.host;

    const urlIsExternal = !hostOfUrlStr.includes(currentHost);

    // console.warn('isExternalURL >>> ',
    // 'urlStr',urlStr,
    // 'urlIsAbsolute',urlIsAbsolute,
    // 'hostOfUrlStr',hostOfUrlStr,
    // 'currentHost',currentHost,
    // 'urlIsExternal',urlIsExternal);

    return urlIsExternal;
};

import { isValid, parseISO } from 'date-fns';
import { InstantiateReservationFromJSON, ReservationGeneral } from './reservationGeneral';
import { InstantiateListingFromJSON, ListingGeneral } from './listingGeneral';
import { CaseGeneral, InstantiateCaseFromJSON } from './caseGeneral';

class AssigneeGeneral {
    constructor(
        public readonly id: string,
        public readonly name: string,
        public readonly objects: any[],
        public readonly user_ids: string[]
    ) {
    }
}
function InstantiateAssigneeFromJSON(json: any) {
    return new AssigneeGeneral(
        json.id,
        json.name,
        json.objects,
        json.user_ids
    );
}
type AssigneeType = 'Group';
enum TaskPriority {
    urgent = "URGENT",
    hight = "High",
    normal = "Normal",
    low = "Low",
    lowest = "Lowest"
}
export enum TaskStatus {
    completed = "Completed",
    not_started = "Not Started",
    in_progress = "In Progress",
    on_hold = "On Hold",
    deffered = "Deferred",
    escalated = "Escalated",
}
export enum TaskStatusMapped {
    completed = "completed",
    not_started = "not started",
    in_progress = "in progress",
    on_hold = "on hold",
    deffered = "deferred",
    escalated = "escalated",
}
enum TaskSubType {
    tidy = 'Tidy'
}
export enum TaskType {
    clean = 'Clean',
    maintenance = "Maintenance",
    drop = "Drop",
    inspection = "Inspection",
}
enum RelatedType {
    LOI = 'Listing_Order_Item__c',
    ROI = 'Reservation_Order_Item__c',
    Listing = 'Listing__c',
    Reservation = 'Reservation__c',
    Case = 'Case'
}

export class TaskGeneral {
    public readonly created_at_ISO: Date | null;
    public readonly last_modified_date_ISO: Date | null;
    public readonly dueDate: Date | null;
    public readonly assignee_name: string
    public readonly related_name?: string
    public readonly status_mapped: string
    public readonly unit_id: string
    public readonly start_time: Date | null
    public readonly completed_time: Date | null
    constructor(
        public readonly assignee: AssigneeGeneral,
        public readonly assignee_id: string,
        public readonly assignee_type: AssigneeType,
        private readonly _created_date: string,
        private readonly _due_date: string,
        public readonly id: string,
        public readonly is_claimable: boolean,
        public readonly is_closed: boolean,
        public readonly is_high_priority: boolean,
        public readonly is_overdue: boolean,
        public readonly is_timed: boolean,
        private readonly _last_modified_date: string,
        public readonly priority: TaskPriority,
        public readonly priority_value: 0 | 1 | 2 | 3,
        public readonly related: ReservationGeneral | ListingGeneral | CaseGeneral | null,
        public readonly related_id: string,
        public readonly related_type: RelatedType,
        private readonly _status: TaskStatus,
        public readonly sub_type: TaskSubType,
        public readonly title: string,
        public readonly type?: TaskType | string,
        private readonly _start_time?: string,
        private readonly _completed_datetime?: string
    ) {
        this.created_at_ISO = isValid(new Date(this._created_date)) ? parseISO(this._created_date) : null;
        this.last_modified_date_ISO = isValid(new Date(this._last_modified_date)) ? parseISO(this._last_modified_date) : null;
        this.dueDate = isValid(new Date(this._due_date)) ? parseISO(this._due_date) : null;
        this.assignee_name = this.assignee.name;
        // eslint-disable-next-line no-nested-ternary
        this.related_name = this.related ? this.related instanceof CaseGeneral ? this.related.case_number : this.related.name_detailed : '';
        this.status_mapped = this._status ? this._status.toLowerCase() : '';
        this.unit_id = this.related ? this.related.unit_id : '';
        this.start_time = this._start_time && isValid(new Date(this._start_time)) ? parseISO(this._start_time) : null
        this.completed_time = this._completed_datetime && isValid(new Date(this._completed_datetime)) ? parseISO(this._completed_datetime) : null
    }
}
export function InstantiateTaskFromJSON(json: any) {
    const assignee = InstantiateAssigneeFromJSON(json.assignee);
    let relatedTaskClass: ListingGeneral | ReservationGeneral | CaseGeneral | null = null

    if (json.related && json.related.type) {
        switch (json.related_type) {
            case 'Guest__c':
            case 'Guest': {
                relatedTaskClass = InstantiateReservationFromJSON(json.related)
            }
                break;
            case 'Listing__c':
            case 'Listing': {
                relatedTaskClass = InstantiateListingFromJSON(json.related)
            }
                break;
            case 'Case__c': //? Sanity check. Cases should never be a custom object.
            case 'Case': {
                relatedTaskClass = InstantiateCaseFromJSON(json.related)
            }
                break;
            default:
                relatedTaskClass = null
        }
    }

    return new TaskGeneral(
        assignee,
        json.assignee_id,
        json.assignee_type,
        json.created_date,
        json.due_datetime,
        json.id,
        json.is_claimable,
        json.is_closed,
        json.is_high_priority,
        json.is_overdue,
        json.is_timed,
        json.last_modified_date,
        json.priority,
        json.priority_value,
        relatedTaskClass,
        json.related_id,
        json.related_type,
        json.status,
        json.sub_type,
        json.title,
        json.type,
        json.start_time,
        json.completed_datetime
    );
}
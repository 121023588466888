import { UaEventOptions } from "react-ga4/types/ga4";

export enum GAEventTransport {
    beacon = 'beacon',
    xhr = 'xhr',
    image = 'image'
}

export enum GAEventName {
    DATE_RANGE_CHANGE = 'date_range_change',
    NEW_BOOKING = 'new_booking',
    OPEN_POPOVER = 'open_popover',
    STATEMENT_DOWNLOAD = 'download_statement',
    TABLE_SORT = 'table_sort'
}


/**
 * @abstract
 * @class GAEventBase - base event for react-ga4
 * @param {GAEventName} name - name of event
 * @param {boolean} conversion - whether the event was a conversion (i.e. a booking, sale, etc...)
 * @param {string} context - categorization term, appended to the name (optional)
 * @param {string} subContext - further category detail (optional)
 * @method getParams
 * @description Record<string, string | number | boolean> - second param of react-ga4.event function.
 */
export abstract class GAEventBase {
    public readonly name: string

    constructor(
        readonly eventName: GAEventName,
        readonly conversion: boolean,
        readonly context: string = '',
        readonly subContext: string = '',
    ) {
        this.name = this.eventName
        if (context) {
            this.name += `_${this.context}`
        }
        if (subContext) {
            this.name += `_${this.subContext}`
        }
    }

    abstract getParams(): Record<string, string | number | boolean>
}

export class GAEventPopover extends GAEventBase {
    constructor(
        readonly popover_name: string
    ) {
        super(GAEventName.OPEN_POPOVER, false, popover_name)
    }

    // eslint-disable-next-line class-methods-use-this
    getParams() {
        return {
        }
    }
}

export class GAEventPopoverCalendar extends GAEventPopover {
    constructor(
        readonly popover_name: string,
        private readonly _selection_type: 'task' | 'reservation' //TODO: Make an enum, if this grows
    ) {
        super(popover_name)
    }

    getParams() {
        const baseParams = super.getParams();

        return {
            ...baseParams,
            selection_type: this._selection_type
        }
    }
}

export class GAEventDateRangeChange extends GAEventBase {
    constructor(
        readonly picker_name: string,
        private readonly _date_range: string,
        private readonly _valid: boolean,
        readonly subContext: string = '',
    ) {
        super(GAEventName.DATE_RANGE_CHANGE, false, picker_name, subContext)
    }

    getParams() {
        return {
            date_range: this._date_range,
            valid: this._valid
        }
    }
}

export class GAEventNewBooking extends GAEventBase {
    constructor(
        private readonly _as_owner: boolean,
        private readonly _guest_pays: boolean,
        private readonly _nights_booked: number
    ) {
        super(GAEventName.NEW_BOOKING, true) // true: record conversion
    }

    getParams() {
        return {
            as_owner: this._as_owner,
            guest_pays: this._guest_pays,
            nights_booked: this._nights_booked
        }
    }
}

export class GAEventStatementDownload extends GAEventBase {
    constructor(
        private readonly _date: string
    ) {
        super(GAEventName.STATEMENT_DOWNLOAD, true) // true: record conversion
    }

    getParams() {
        return {
            date: this._date
        }
    }
}

export class GAEventTableSort extends GAEventBase {
    constructor(
        readonly table_name: string,
        private readonly _ascending: boolean,
        private readonly _column: string
    ) {
        super(GAEventName.TABLE_SORT, false, table_name)
    }

    getParams() {
        return {
            sort_by: this._column + ' ' + (this._ascending ? 'asc' : 'desc')
        }
    }
}

// ------------------------------------------------------
// The code below is from an older (pre GA4) system.

export enum GAAction {
    Click = 'Click',
    Open = 'Open',
    Request = 'Request'
}

/**
 * @deprecated
 * @class GAEvent
 * Will construct an event for google analytics
 * @param {string} category - E.g. 'User', 'Navigation', 'App Editing', etc.
 * @param {GAAction} action - Clicked Delete', 'Added a component', 'Deleted account', etc.
 * @param {string} label - E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 * @param {number} value - E.g. a rating, a score, etc.
 * @param {boolean} nonInteraction -  If an event is not triggered by a user interaction, but instead by our code (e.g. on page load)
 * @param {GAEventTransport} transport -  This specifies the transport mechanism with which hits will be sent. Valid values include 'beacon', 'xhr', or 'image'.
 * @method increment -  increases this.value by one.
 * @method decrement -  decreases this.value by one.
 * @returns {GAEvent} Google Analytics Event
 */

export class GAEvent implements UaEventOptions {

    constructor(
        public readonly category: string,
        public readonly action: GAAction,
        public readonly label: string = '',
        public value: number = 0,
        public readonly nonInteraction: boolean = false,
        public readonly transport: GAEventTransport = GAEventTransport.xhr
    ) {
        console.warn('Depreciated Option')
    }

    increment() {
        ++this.value;
    }

    decrement() {
        --this.value;
    }

}

import { Stack, Skeleton } from '@mui/material';
import { useId } from 'react';
import useResponsive from '../../hooks/useResponsive';

export function HeatMapLoading() {
    const id = useId()
    const isMobile = useResponsive('down', 'sm');
    const isTablet = useResponsive('between', 'sm', 'md');
    const numColums = isMobile
        ? 200
        : isTablet
            ? 300
            : 360;
    return (
        <Stack height='auto' justifyContent='center'>
            <Stack width='100%' pt={1} pb={2} px={3} direction='row' justifyContent='flex-start' alignItems='center' flexWrap='wrap'>
                {new Array(numColums).fill('a').map((l, k) => (
                    <LoadingSkeleton key={k + id} />
                ))}
            </Stack>
        </Stack>
    );
}
function LoadingSkeleton() {
    return <Skeleton
        variant="rectangular"
        sx={{
            backgroundColor: (theme) => theme.palette.background.neutral,
            width: {
                xs: 10,
                sm: 13,
                md: 20,
                ld: 20
            },
            height: {
                xs: 10,
                sm: 13,
                md: 16,
                ld: 16
            },
            m: 0.1,
        }} />;
}

import { Divider, Grid, Stack, Typography } from "@mui/material";
import { DetailColumn, DetailContainer, DetailRow } from "../../../layouts/simple/DetailColumnAndRow";
import ListingOrderItemGeneral, { ListingOrderItemStatus } from "../../../models/mrr/loi";
import Label from "../../../components/label/Label";
import { FDateFormat, fDate } from "../../../utils/formatTime";
import { fCurrency } from "../../../utils/formatNumber";
import { endOfWeek, parseISO } from "date-fns";

interface Props {
    loi: ListingOrderItemGeneral
}

export default function LoiDetails({
    loi,
}: Props) {

    const { title,
        name_detailed,
        status,
        type,
        sub_type,
        name,
        last_modified_date_ISO,
        end_date_ISO,
        amount,
        discount,
        total_amount,
        tax_amount
    } = loi

    return (
        <DetailContainer>
            <Grid item>
                <Typography variant='h5'>
                    {name}
                </Typography>
            </Grid>
            <DetailRow>
                {/* Title */}
                <DetailColumn fullWidth>
                    <Typography variant="overline" sx={{ color: 'text.disabled' }}>Title</Typography>
                    <Typography variant="body1">{title}</Typography>
                </DetailColumn>
            </DetailRow>
            <DetailRow>
                {/* Property */}
                <DetailColumn fullWidth>
                    <Typography variant="overline" sx={{ color: 'text.disabled' }}>Property</Typography>
                    <Typography variant="body1">{name_detailed}</Typography>
                </DetailColumn>
            </DetailRow>
            <DetailRow>
                {/* Type */}
                <DetailColumn >
                    <Typography variant="overline" sx={{ color: 'text.disabled' }}>Type</Typography>
                    <Typography variant="body1">{type || '-'}</Typography>
                </DetailColumn>
                {/* Status */}
                <DetailColumn>
                    <Stack spacing={.5}>
                        <Typography variant="overline" sx={{ color: 'text.disabled' }}>Status</Typography>
                        <Label
                            width='fit-content'
                            variant="soft"
                            color={
                                (status === ListingOrderItemStatus.approved && 'primary') ||
                                (status === ListingOrderItemStatus.close && 'warning') ||
                                'info'
                            }>
                            {status}
                        </Label>
                    </Stack>
                </DetailColumn>
            </DetailRow>
            <DetailRow>
                {/* Sub Type */}
                <DetailColumn fullWidth>
                    <Typography variant="overline" sx={{ color: 'text.disabled' }}>Sub Type</Typography>
                    <Typography variant="body1">{sub_type || 'Other'}</Typography>
                </DetailColumn>
            </DetailRow>
            <DetailRow>
                {/* Updated */}
                <DetailColumn fullWidth>
                    <Typography variant="overline" sx={{ color: 'text.disabled' }}>Updated</Typography>
                    <Typography variant="body1">{fDate(last_modified_date_ISO, FDateFormat.localized_date_time_full)}</Typography>
                </DetailColumn>
            </DetailRow>
            {end_date_ISO !== null &&
                <DetailRow>
                    {/* Close Date */}
                    <DetailColumn fullWidth>
                        <Typography variant="overline" sx={{ color: 'text.disabled' }}>Close Date</Typography>
                        <Typography variant="body1">{fDate(end_date_ISO, FDateFormat.localized_date_time_full)}</Typography>
                    </DetailColumn>
                </DetailRow>}
            <DetailRow>
                {!Number.isNaN(amount) &&
                    <DetailColumn third>
                        {/* Amount */}
                        <Typography variant="overline" sx={{ color: 'text.disabled' }}>Amount</Typography>
                        <Typography variant="body1">{fCurrency(amount, true)}</Typography>
                    </DetailColumn>
                }
                {!Number.isNaN(discount) &&
                    <DetailColumn third>
                        {/* discount */}
                        <Typography variant="overline" sx={{ color: 'text.disabled' }}>Discount</Typography>
                        <Typography variant="body1">{fCurrency(discount, true)}</Typography>
                    </DetailColumn>
                }
                {!Number.isNaN(tax_amount) &&
                    <DetailColumn third>
                        {/* discount */}
                        <Typography variant="overline" sx={{ color: 'text.disabled' }}>Taxes</Typography>
                        <Typography variant="body1">{fCurrency(tax_amount, true)}</Typography>
                    </DetailColumn>
                }
            </DetailRow>
            {!Number.isNaN(total_amount) &&
                <DetailRow>
                    <DetailColumn fullWidth>
                        <Typography variant="overline" sx={{ color: 'text.disabled' }}>Total Amount</Typography>
                        <Typography variant="body1">{fCurrency(total_amount, true)}</Typography>
                    </DetailColumn>
                </DetailRow>
            }

        </DetailContainer>
    );
}

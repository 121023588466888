import { CardContent, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";

export function StyledCardContent({ children, id, sx }: PropsWithChildren & { id?: string; sx?: SxProps }) {
    return (
        <CardContent
            id={id}
            sx={{ p: 3, ...sx }}
        >
            {children}
        </CardContent>
    );
}

import { ReservationStage } from '../../../utils/mrr/reservationConstants';
import { ReservationGeneral } from '../../../models/mrr/reservationGeneral';
import { CalendarReservationContentMode } from '../../../models/mrr/calendarEvents';
import { headerType } from '../../../components/table/useDynamicTableHeaders';

// ----------------------------------------------------------------------
const TABLE_HEAD: headerType[] = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'guest_name', label: 'Guest Name', align: 'left' },
    { id: 'guest_type', label: 'Guest Type', align: 'left' },
    { id: 'check_in_ISO', label: 'Check In', align: 'left' },
    { id: 'check_out_ISO', label: 'Check Out', align: 'left' },
    { id: 'nights', label: 'Nights', align: 'left' },
    { id: 'nightly_rate', label: 'Nightly Rate', align: 'left' },
    { id: 'rental_amount', label: 'Amount', align: 'left' },
    { id: 'booking_date_ISO', label: 'Booked On', align: 'left' },
    { id: '', align: 'right' },
];
const EXTRA_ALL_HEADERS: headerType[] = [{ id: 'unit_id', label: 'Property', align: 'left' }];
const TABLE_HEAD_ALL = [TABLE_HEAD[0], ...EXTRA_ALL_HEADERS, ...TABLE_HEAD.slice(1)];
export const createReservationTableHead = (mode: CalendarReservationContentMode) => {
    if (mode === CalendarReservationContentMode.All) {
        return TABLE_HEAD_ALL;
    }
    return TABLE_HEAD;
};
export const defaultStageFilter: string = ReservationStage.Confirmed;

export const mobileBreakpointKeys: (keyof ReservationGeneral | '')[] = ['name', 'check_in_ISO', 'nights', 'rental_amount', ''];
export const tabletBreakpointKeys: (keyof ReservationGeneral | '')[] = ['name', 'unit_id', 'guest_name', 'check_in_ISO', 'nights', 'rental_amount', ''];
export const desktopBreakpointKeys: (keyof ReservationGeneral | '')[] = ['name', 'unit_id', 'guest_name', 'guest_type', 'check_in_ISO', 'check_out_ISO', 'nightly_rate', 'rental_amount', ''];

import { Helmet } from 'react-helmet-async';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router';
import fTitle from '../../utils/mrr/formatTitle';
import { SignInWithEmailLinkForm } from './SignInWithEmailLinkForm';
import LoginLayout from '../../layouts/login/LoginLayout';
import Logo from '../../components/logo';
import { PATH_USER } from '../../routes/paths';
import { useAuthContext } from '../../auth/useAuthContext';
import LoadingScreen from '../../components/loading-screen';
import LoginFlowProvider, { SignInStep, useLoginFlow } from '../../auth/LoginProvider';
import SelectVerificationMethod from '../../components/login/SelectVerificationMethod';
import { ConfirmationResult } from 'firebase/auth';
import ConfirmCode from '../../components/login/ConfirmCode';
import AuthLoginForm from '../../sections/auth/AuthLoginForm';
import { CheckEnvironment, SupportedEnvironments } from '../../config-global';

// ----------------------------------------------------------------------

export default function OobSignInPage({ emailOnly }: { emailOnly?: boolean } = { emailOnly: false }) {
    return (
        <>
            <Helmet>
                <title>{fTitle('Login')}</title>
            </Helmet>
            <div id='hidden-recaptcha-element' />
            <LoginFlowProvider>
                <LoginWithEmail emailOnly={emailOnly} />
            </LoginFlowProvider>
        </>
    );
}

// ----------------------------------------------------------------------

function LoginWithEmail({ emailOnly }: { emailOnly?: boolean } = { emailOnly: false }) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar();

  const {
    setAlertFromOOBAction,
    signInStep,
    setSignInStep,
    setConfirmationResult,
    phoneToVerify
  } = useLoginFlow();

    useEffect(() => {
    if (state) {
      if (state.error) {
        enqueueSnackbar(state.error, { autoHideDuration: 6 * 1000, variant: 'error' });
      };
      if (state.alert) {
        setAlertFromOOBAction(state.alert);
      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, enqueueSnackbar]);

    return (
        <LoginLayout>
            <Stack
                direction='row'
                position='relative'
                alignItems='center'
                justifyContent='flex-start'
                mb={2.5}
            >
                <Logo
                    onMouseDown={(event) => {
                        if (event.altKey && event.shiftKey && event.target) {
                            navigate(PATH_USER.login)
                        }
                    }}
                    disabledLink
                    height={28}
                    width={28}
                    mr={1}
                />
                <Typography variant="h4">Login to the Owner Portal</Typography>
                {/*//!-------- Disabled Self-Serviced Sign Up --------!//*/}
                {/* <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2">New user?</Typography>

                    <Link component={NavLink} to={PATH_USER.register} variant="subtitle2">
                        Create an account
                    </Link>
                </Stack> */}
            </Stack>

      {signInStep === SignInStep.Initial &&
        <SignInWithEmailLinkForm next={(skip?: boolean) => {
          if (skip) {
            setSignInStep(SignInStep.Confirm);
            return;
          }
          setSignInStep(SignInStep.SelectMethod);
        }} />}
      {signInStep === SignInStep.SelectMethod &&
        <SelectVerificationMethod
          next={(confirmResult?: ConfirmationResult) => {
            setSignInStep(SignInStep.Confirm);
            setConfirmationResult(confirmResult)
          }}
          back={() => { setSignInStep(SignInStep.Initial) }} />}
      {signInStep === SignInStep.Confirm &&
        <ConfirmCode
          back={() => {
            if (phoneToVerify && !emailOnly) {
              setSignInStep(SignInStep.SelectMethod)
              return;
            }
            setSignInStep(SignInStep.Initial);
          }}
        />}

      {signInStep === 'Developer Login' && <AuthLoginForm
        back={() => { setSignInStep(SignInStep.Initial) }}
      />}
      {(CheckEnvironment(SupportedEnvironments.Dev) && signInStep === SignInStep.Initial) &&
        <Button
          color='warning'
          sx={{ mt: 2 }}
          variant='soft'
          onClick={() => { setSignInStep("Developer Login") }}>
          Developer Login
        </Button>
      }
        </LoginLayout>
    )
}
import { parseISO } from "date-fns";
import { CaseSubTypes, CaseTypes, CaseStatus, CasePriority } from "../../utils/mrr/caseConstants";
import { ListingGeneral } from "./listingGeneral";

export interface CaseCommentI {
  timestamp: string,
  creator_name: string,
  body: string,
  sentByUser?: boolean
}

export class CaseGeneral {
  public readonly typeWithSubtype: string
  public readonly check_out_ISO: Date | null
  public readonly next_check_in_ISO: Date | null
  public listing: ListingGeneral | null = null
  public listing_id: string | null = null
  public readonly last_modified_date_ISO: Date

  constructor(
    public readonly id: string,
    public readonly name: string, // is same as case_number
    public readonly case_number: string, // is same as name
    public readonly subject: string,
    public readonly comments: CaseCommentI[],
    public readonly status: CaseStatus,
    public readonly is_closed: boolean,
    public readonly priority: CasePriority,
    public readonly is_escalated: boolean,
    public readonly unit_id: string,
    public readonly origin: string,
    public readonly owner_id: string,
    public readonly owner_name: string,
    public readonly turnaround: boolean,
    private readonly _check_out: string | undefined,
    private readonly _next_check_in: string | undefined,
    private readonly _last_modified_date: string,
    private readonly _type: CaseTypes | string,
    private readonly _subType: CaseSubTypes,
  ) {
    this.typeWithSubtype = CaseGeneral.MapTypeWithSubTypeToEnums(this._type, this._subType)
    this.check_out_ISO = this._check_out ? parseISO(this._check_out) : null
    this.next_check_in_ISO = this._next_check_in ? parseISO(this._next_check_in) : null
    this.last_modified_date_ISO = parseISO(this._last_modified_date)
  }

  private static MapTypeWithSubTypeToEnums(type: string, subType: string) {
    const lowerCasedType = type ? type.toLowerCase() : '';
    const lowerCasedSubType = subType ? subType.toLowerCase() : '';

    return `${lowerCasedType} ${lowerCasedSubType}`
  }

  setListing(listing: ListingGeneral) {
    this.listing = listing
    this.listing_id = listing.id
  }

  get subType() {
    return this._subType
  }
};

export function InstantiateCaseFromJSON(jsonRecord: any) {
  const newModel = new CaseGeneral(
    jsonRecord.id,
    jsonRecord.name,
    jsonRecord.case_number,
    jsonRecord.subject,
    jsonRecord.comments,
    jsonRecord.status,
    jsonRecord.is_closed,
    jsonRecord.priority,
    jsonRecord.is_escalated,
    jsonRecord.unit_id,
    jsonRecord.origin,
    jsonRecord.owner_id,
    jsonRecord.owner_name,
    jsonRecord.turnaround,
    jsonRecord.check_out,
    jsonRecord.next_check_in,
    jsonRecord.last_modified_date,
    jsonRecord.type,
    jsonRecord.sub_type
  );

  return newModel;
};

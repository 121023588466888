import { useMemo } from 'react';
import useResponsive from '../../hooks/useResponsive';

export function useTableWidth() {
    const isMobile = useResponsive('down', 'sm');
    const isTablet = useResponsive('between', 'sm', 'md');
    const isDesktop = useResponsive('up', 'md');

    const minWidth = useMemo(() => {
        if (isMobile) {
            return 300;
        }
        if (isTablet) {
            return 500;
        }
        if (isDesktop) {
            return 800;
        }
        return 'unset';
    }, [isMobile, isTablet, isDesktop]);

    return minWidth;
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Link, Stack, Typography, styled } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, Link as RouterLink, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { CLOUD_FUNCTIONS } from '../../auth/FirebaseContext';
import { useAuthContext } from '../../auth/useAuthContext';
import { RHFTextField } from '../../components/hook-form';
import FormProvider from '../../components/hook-form/FormProvider';
import Iconify from '../../components/iconify/Iconify';
import { PATH_USER } from '../../routes/paths';
import { cloud_emailOneTimePassword, cloud_getSignInMethods, cloud_userSignInViaEmail } from '../../utils/mrr/cloudFunctions';
import { safelyReadErrorMessage } from '../../utils/mrr/errorHandling';
import { supportEmail } from '../../utils/mrr/contactUs';
import { brandConfig } from '../../config';
import { EMAIL_REGEX } from '../../utils/mrr/uiConstants';
import { SignInMethod, useLoginFlow } from '../../auth/LoginProvider';
import { MenuPopoverProps } from '../../components/menu-popover';
import { ContactUsPopover } from '../dashboard/ContactUsPopover';

interface SignInWithEmailLinkFormFieldValues {
    email: string;
}

const SignInWithEmailLinkFormScheme = Yup.object().shape({
    email: Yup.string().required('Email is required').matches(EMAIL_REGEX, 'Email must be valid')
});

type SignInWithEmailLinkFormProps = {
    next(skip?: boolean): void
}

export function SignInWithEmailLinkForm({ next }: SignInWithEmailLinkFormProps) {
    const [searchParams] = useSearchParams()
    const { emailToVerify, setEmailToVerify, setPhoneToVerify, alertFromOOBAction, setSignInMethod } = useLoginFlow();

    useEffect(() => {
        if (alertFromOOBAction) {
            window.history.replaceState(null, '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertFromOOBAction])
    const defaultFormEmail = searchParams.has('email') ? searchParams.get('email')! : emailToVerify

    const methods = useForm<SignInWithEmailLinkFormFieldValues>({
        resolver: yupResolver(SignInWithEmailLinkFormScheme),
        defaultValues: { email: defaultFormEmail },
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isSubmitting, isValid },
        trigger,
        setError
    } = methods

    useEffect(() => {
        // This will validate the input email if prefilled by search params
        if (defaultFormEmail !== '') {
            trigger()
        }
    }, [defaultFormEmail, trigger])

    const onSubmit = handleSubmit(async (data) => {
        const request = httpsCallable<{ email: string, brand: string }, Record<string, any>>(CLOUD_FUNCTIONS, cloud_getSignInMethods);
        const { error, email, phone } = (await request({ email: data.email, brand: brandConfig.brandCode })).data;
        if (error || !email) {
            setError('email', { message: 'Please try again with a different email.' })
            return;
        }
        setEmailToVerify(email || '');
        setPhoneToVerify(phone || '');
        const shouldSkipSelectVerificationMethod = !phone;
        if (shouldSkipSelectVerificationMethod) {
            setSignInMethod(SignInMethod.Email);
            const sendEmailCodeRequest = httpsCallable<{ email: string }, boolean>(CLOUD_FUNCTIONS, cloud_emailOneTimePassword);
            const sentSignInEmailCode = (await sendEmailCodeRequest({ email })).data;
            if (!sentSignInEmailCode) {
                setError('email', { message: 'Sorry we could not email you a code. Please try again.' })
                return;
            }
        }
        next(shouldSkipSelectVerificationMethod)
    })
    return (
        <FormProvider name='login_form' id='login_form' methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2.5}>
                <WelcomeAlert />
                <StyleInput
                    disabled={isSubmitting}
                    InputProps={
                        {
                            endAdornment:
                                <Iconify
                                    color='inherit'
                                    icon='eva:email-outline'
                                    ml={1} />
                        }
                    }
                    name="email"
                    label="Email address"
                    type='email'
                    autoFocus />
                <Stack direction='column' spacing={1}>
                    <LoadingButton
                        disabled={isSubmitting || !isValid}
                        size='large'
                        loading={isSubmitting}
                        type="submit"
                        variant='contained'
                    >
                        Continue
                    </LoadingButton>

                    <HelpLink arrow='top-left' />
                </Stack>
            </Stack>
        </FormProvider>
    );
}

//-----------------

const StyleInput = styled(RHFTextField, {
})(({ theme }) => ({
    input: {
        '&:-webkit-autofill': {
            webkitBoxShadow: 'none !important',
            webkitTextFillColor: 'inherit',
            webkitBackgroundClip: 'text !important'
        }
    }
}))


function WelcomeAlert() {
    return (
        <Alert severity="info">
            <Stack>
                <Typography variant='h6'>
                    {brandConfig.greeting}!
                </Typography>
                <Typography>
                    Please enter your email address to continue.
                </Typography>
            </Stack>
        </Alert>
    )
}

// function LinkSentSuccess({ email, returnToLogin }: { email: string, returnToLogin: VoidFunction }) {
//     return (
//         <>
//             <Stack alignItems='center'>
//                 <EmailLinkSentIcon color='primary' sx={{ mb: 5 }} height={120} />
//                 <Stack spacing={1}>
//                     <Typography variant='h4' textAlign='center'>
//                         Login Link Sent
//                     </Typography>
//                     <Typography variant='body1' textAlign='center'>
//                         Your login link has been sent to:
//                         <br />
//                         <b>{email}</b>
//                     </Typography>
//                     <Typography variant='body2' textAlign='center'>
//                         Please check your spam folder if not in inbox.
//                     </Typography>
//                 </Stack>
//             </Stack>
//             <SplitRow>
//                 <Button
//                     variant='text'
//                     startIcon={<Iconify icon="mdi:keyboard-arrow-left" width={16} />}
//                     onClick={returnToLogin}
//                 >
//                     Return to Login
//                 </Button>
//                 <HelpLink arrow='top-right' />
//             </SplitRow>
//         </>
//     )
// }

function HelpLink({ arrow }: { arrow?: MenuPopoverProps['arrow'] }) {
    const [open, setOpen] = useState<HTMLElement | null>(null)
    const handleOpenContactUs = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget)
    }
    const handleCloseContactUs = () => {
        setOpen(null)
    }

    return (
        <>
            <Link
                sx={{ cursor: 'pointer' }}
                onClick={handleOpenContactUs}
                color='primary'>
                Help?
            </Link>
            <ContactUsPopover openSupportPopOver={open} handleCloseSupportPopOver={handleCloseContactUs} />
        </>
    )
}
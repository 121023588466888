import { Dispatch, SetStateAction } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';

interface DropInFormDialogI {
    open: boolean;
    onClose: VoidFunction;
    setMessage: Dispatch<SetStateAction<string>>;
    message: string;
    handleOpenConfirm: VoidFunction;
}
export function DropInFormDialog({ open, onClose, message, setMessage, handleOpenConfirm }: DropInFormDialogI) {
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle sx={{ pb: 2 }}>Request</DialogTitle>
            <DialogContent>
                <Typography variant='body2'>
                    Please tell us more about this request?
                </Typography>
                <div style={{ marginTop: '1rem' }}>
                    <TextField fullWidth multiline autoFocus placeholder='Enter message...' label='Message' name='userMessage' value={message} onChange={(event) => {
                        setMessage(event.target.value);
                    }} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleOpenConfirm} disabled={!message}>Continue</Button>
            </DialogActions>
        </Dialog>
    );
}

import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useReducer } from "react";
import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from "../../config-global";
import { ActionMapType } from "../types";
import { useAuthContext } from "../useAuthContext";
import { GAEventBase } from "./GAEvent";

const VERBOSE_LOGGING = false

// Initialize Analytics
if (!GA_MEASUREMENT_ID) {
    throw new Error('Missing required GA_MEASUREMENT_ID')
}

enum Types {
    INITIAL = 'INITIAL',
    LOADING = 'LOADING'
}

type AnalyticsStateType = {
    isInitialized: boolean,
}

type Payload = {
    [Types.INITIAL]: {
        isInitialized: boolean;
    };
    [Types.LOADING]: {
        isLoading: boolean;
    };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const reducer = (state: AnalyticsStateType, action: ActionsType) => {
    if (action.type === Types.INITIAL) {
        return {
            isInitialized: true,
            isLoading: false,
        };
    }
    if (action.type === Types.LOADING) {
        return {
            ...state,
            isLoading: true,
        };
    }
    return state;
};

const initialReducerState: AnalyticsStateType = {
    isInitialized: false,
};

interface AnalyticsContextI extends AnalyticsStateType {
    sendAnalytic: (fieldObject: any) => void
    setAnalytic: (fieldObject: any) => void
    sendEvent: (event: GAEventBase) => void
}

const initialState = {
    ...initialReducerState,
    sendAnalytic: () => { throw new Error('Analytics not initialized.') },
    setAnalytic: () => { throw new Error('Analytics set not initialized.') },
    sendEvent: () => { throw new Error('Analytics event not initialized.') },
}

export const AnalyticsContext = createContext<AnalyticsContextI>(initialState)

export function AnalyticsProvider({ children }: PropsWithChildren) {
    ReactGA.initialize([
        {
            trackingId: GA_MEASUREMENT_ID!,
            gaOptions: {
            },
            gtagOptions: {
            }
        },
        // {
        //   Another ID...
        // }
    ])
    const { user } = useAuthContext()
    const [state, dispatch] = useReducer(reducer, initialReducerState)

    const setLoading = (isLoading: boolean) => {
        dispatch({
            type: Types.LOADING,
            payload: {
                isLoading
            }
        })
    }

    const {
        isInitialized,
        send: gaSend,
        set,
        event: gaEvent,
        reset,
    } = ReactGA

    // check initialized analytics
    useEffect(() => {
        dispatch({
            type: Types.INITIAL,
            payload: {
                isInitialized
            }
        })
    }, [isInitialized])

    useEffect(() => {
        if (isInitialized && user && user?.id) {
            set({ userId: user.id })
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, user])

    const event = useCallback((concreteEvent: GAEventBase) => {
        if (!isInitialized) {
            console.warn('Analytics is not initailized.')
            return
        }

        VERBOSE_LOGGING && console.warn('Sending analytics Event', concreteEvent.name)
        setLoading(true)
        gaEvent(concreteEvent.name, concreteEvent.getParams())
        setLoading(false)


    }, [gaEvent, isInitialized])

    const send = useCallback((fileObject: any) => {
        if (!isInitialized) {
            console.warn('Analytics is not initailized.')
            return
        }
        gaSend(fileObject)

    }, [gaSend, isInitialized])


    const value = useMemo(() => ({
        ...state,
        sendAnalytic: send,
        setAnalytic: set,
        sendEvent: event
    }), [state, send, set, event])


    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    )
}

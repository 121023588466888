import { GCF_BASE_URL } from "../../config-global";

const base_url = GCF_BASE_URL

const supportedImageContentTypes = new Set(['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'])

export interface ViewableAttachment {
    id: string;
    title: string;
    image: URL;
    description: string;
}

export default class AttachmentMeta implements ViewableAttachment {
    public readonly image: URL = new URL(base_url + '/getAttachmentFile')

    public readonly description: string;

    public readonly title: string;

    public readonly isImage: boolean;

    constructor(
        public readonly id: string,
        public readonly content_type: string,
        public readonly name: string,
        public readonly owner_id: string,
        public readonly owner_name: string,
        public readonly parent_id: string
    ) {
        this.description = owner_name + '_' + name
        this.title = name
        this.isImage = supportedImageContentTypes.has(content_type)
    }

    createViewableImageData(external_id: string) {
        if (!this.isImage) {
            throw new Error('Invalid file type.')
        }
        this.image.searchParams.set('attachment', this.id)
        this.image.searchParams.set('oid', external_id)
        return {
            id: this.id,
            title: this.title,
            image: this.image,
            description: this.description,
        }
    }
}

export function InstantiateAttachmentMetaFromJSON(json: any) {
    return new AttachmentMeta(
        json.id,
        json.content_type,
        json.name,
        json.owner_id,
        json.owner_name,
        json.parent_id
    )
}

export enum UserStatus {
  Active = 'active',
  // Pending = 'pending',  // not sync'd to Salesforce
  Disabled = 'disabled' // TBD
};

// This array is used by filter options, so it injects an 'all' before the enum'd statuses above.
export const USER_STATUS_OPTIONS = ['all'];  // TBD

const statusKeys = Object.keys(UserStatus);

for (let i = 0; i < statusKeys.length; ++i) {
  USER_STATUS_OPTIONS.push(statusKeys[i].toLowerCase());
}

// This is shown in the drop-down to be explicit, but stripped from outgoing payloads.
export const SalutationNone = '<none>';

export const SalutationOptions: string[] = [
  '',
  SalutationNone,
  'Mr.',  // straight from Salesforce
  'Ms.',  // straight from Salesforce
  'Mrs.', // straight from Salesforce
  'Dr.',  // straight from Salesforce
  'Prof.' // straight from Salesforce
];

export enum Roles {
  Admin = 'admin',
  Developer = 'developer',
  Support = 'support',
  User = 'user'
};

export const roleNames = Object.values(Roles);

// first letter only
export const rolesCapitalized = roleNames.map(x => x.charAt(0).toUpperCase() + x.slice(1));

// these roles can access 'admin' routes
export const adminRoleNames = roleNames.filter(v => v !== Roles.User);

export function allowRoleToAffectNonUsers(role: Roles) {
  return role === Roles.Admin || role === Roles.Developer;
};

export function allowRoleToModifyRoles(role: Roles) {
  return role === Roles.Admin || role === Roles.Developer;
};

export function allowRoleToSeeDevTab(role: Roles | null | string) {
  return role === Roles.Developer;
};

export function allowRoleToSendSignInLink(role: Roles) {
  return role !== Roles.User;
};

export function allowRoleToSignInAsOther(role: Roles) {
  return role !== Roles.User;
};

export function checkRoleUsesSalutation(role: Roles) {
  return role === Roles.User;
};

export function routeRoleAsAdmin(role: Roles) {
  return role !== Roles.User;
};

export function roleCanSeePortalNotifications(role: Roles) {
  return role === Roles.User;
};
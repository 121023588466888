import { useMemo } from 'react';
import { addMonths, endOfYear, startOfYear, subMonths } from 'date-fns';
import useResponsive from '../../hooks/useResponsive';

// We use a separate query for the heatmap's data (current year only).
const today = new Date()
export const heatMapDateStart = startOfYear(new Date());
export const heatMapDateEnd = endOfYear(new Date());
const mobileDateGap = 2;
const tabletDateGap = 3;

export function useHeatmapDates() {
    const isMobile = useResponsive('down', 'sm');
    const isTablet = useResponsive('between', 'sm', 'md');

    const startDate = useMemo(() => {
        if (isMobile) {
            return subMonths(today, mobileDateGap);
        }
        if (isTablet) {
            return subMonths(today, tabletDateGap);
        }
        return heatMapDateStart;
    }, [isMobile, isTablet]);

    const endDate = useMemo(() => {
        if (isMobile) {
            return addMonths(today, mobileDateGap);
        }
        if (isTablet) {
            return addMonths(today, tabletDateGap);
        }
        return heatMapDateEnd;
    }, [isMobile, isTablet]);
    return [startDate, endDate];
}

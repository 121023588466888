import { createContext, useEffect, useContext, useMemo, useCallback } from 'react';
// hooks
import useLocalStorage from '../../hooks/useLocalStorage';
// utils
import localStorageAvailable from '../../utils/localStorageAvailable';
//
import { SettingsContextProps } from './types';
import { defaultPreset, getPresets, presetsOption } from './presets';
import { brandConfig } from '../../config';

// ----------------------------------------------------------------------
const defaultSettings = brandConfig.themeSettings

export const initialState: SettingsContextProps = {
  ...defaultSettings,
  // Mode
  onToggleMode: () => { },
  onChangeMode: () => { },
  // Direction
  onToggleDirection: () => { },
  onChangeDirection: () => { },
  onChangeDirectionByLang: () => { },
  // Layout
  onToggleLayout: () => { },
  onChangeLayout: () => { },
  // Contrast
  onToggleContrast: () => { },
  onChangeContrast: () => { },
  // Color
  onChangeColorPresets: () => { },
  presetsColor: defaultPreset,
  presetsOption: [],
  // Stretch
  onToggleStretch: () => { },
  // Reset
  onResetSetting: () => { },
};

// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

// ----------------------------------------------------------------------

type SettingsProviderProps = {
  children: React.ReactNode;
};

export function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings);
  const guaranteedSettings = getGuaranteedSettings(settings)
  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';

  const isArabic = langStorage === 'ar';

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic]);

  // Mode
  const onToggleMode = useCallback(() => {
    const themeMode = guaranteedSettings.themeMode === 'light' ? 'dark' : 'light';
    setSettings({ ...guaranteedSettings, themeMode });
  }, [setSettings, guaranteedSettings]);

  const onChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeMode = event.target.value;
      setSettings({ ...guaranteedSettings, themeMode });
    },
    [setSettings, guaranteedSettings]
  );

  // Direction
  const onToggleDirection = useCallback(() => {
    const themeDirection = guaranteedSettings.themeDirection === 'rtl' ? 'ltr' : 'rtl';
    setSettings({ ...guaranteedSettings, themeDirection });
  }, [setSettings, guaranteedSettings]);

  const onChangeDirection = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeDirection = event.target.value;
      setSettings({ ...guaranteedSettings, themeDirection });
    },
    [setSettings, guaranteedSettings]
  );

  const onChangeDirectionByLang = useCallback(
    (lang: string) => {
      const themeDirection = lang === 'ar' ? 'rtl' : 'ltr';
      setSettings({ ...guaranteedSettings, themeDirection });
    },
    [setSettings, guaranteedSettings]
  );

  // Layout
  const onToggleLayout = useCallback(() => {
    const themeLayout = guaranteedSettings.themeLayout === 'vertical' ? 'mini' : 'vertical';
    setSettings({ ...guaranteedSettings, themeLayout });
  }, [setSettings, guaranteedSettings]);

  const onChangeLayout = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeLayout = event.target.value;
      setSettings({ ...guaranteedSettings, themeLayout });
    },
    [setSettings, guaranteedSettings]
  );

  // Contrast
  const onToggleContrast = useCallback(() => {
    const themeContrast = guaranteedSettings.themeContrast === 'default' ? 'bold' : 'default';
    setSettings({ ...guaranteedSettings, themeContrast });
  }, [setSettings, guaranteedSettings]);

  const onChangeContrast = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeContrast = event.target.value;
      setSettings({ ...guaranteedSettings, themeContrast });
    },
    [setSettings, guaranteedSettings]
  );

  // Color
  const onChangeColorPresets = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const themeColorPresets = event.target.value;
      setSettings({ ...guaranteedSettings, themeColorPresets });
    },
    [setSettings, guaranteedSettings]
  );

  // Stretch
  const onToggleStretch = useCallback(() => {
    const themeStretch = !guaranteedSettings.themeStretch;
    setSettings({ ...guaranteedSettings, themeStretch });
  }, [setSettings, guaranteedSettings]);

  // Reset
  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings);
  }, [setSettings]);

  const { themeColorPresets, themeMode } = guaranteedSettings

  const memoizedValue = useMemo(
    () => ({
      ...guaranteedSettings,
      // Mode
      onToggleMode,
      onChangeMode,
      // Direction
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      onToggleLayout,
      onChangeLayout,
      // Contrast
      onChangeContrast,
      onToggleContrast,
      // Stretch
      onToggleStretch,
      // Color
      onChangeColorPresets,
      presetsOption,
      presetsColor: getPresets(themeColorPresets, themeMode),
      // Reset
      onResetSetting,
    }),
    [
      guaranteedSettings,
      // Mode
      onToggleMode,
      onChangeMode,
      // Direction
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      onToggleLayout,
      onChangeLayout,
      onChangeContrast,
      // Contrast
      onToggleContrast,
      // Stretch
      onToggleStretch,
      // Color
      onChangeColorPresets,
      // Reset
      onResetSetting,
      themeMode,
      themeColorPresets
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}


// we use this helper because the Minimal local storage tools are not reliable
function getGuaranteedSettings(potentialSettings: any) {
  return potentialSettings ? potentialSettings : defaultSettings;
}
import { useMemo } from 'react';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------
export type headerType = { id: string, label?: string, align?: string, width?: `${string}px`, minWidth?: `${string}px` }

type reservedColumnID = '' | 'menu' | 'toggle' | 'drop_down' | 'attachments'

interface useDynamicTableHeadersI<T> {
    tableHeaders: headerType[];
    breakpoints?: {
        sm?: ((keyof T) | reservedColumnID)[];
        md?: ((keyof T) | reservedColumnID)[];
        lg?: ((keyof T) | reservedColumnID)[];
        xl?: ((keyof T) | reservedColumnID)[];
    };
}

// ---------------------------------------------------------------

export function useDynamicTableHeaders<T>(props: useDynamicTableHeadersI<T>) {
    const isMobile = useResponsive('down', 'sm');
    const isTablet = useResponsive('between', 'sm', 'md');
    const isDesktop = useResponsive('between', 'md', 'lg')
    const isLargeScreen = useResponsive('up', 'lg')


    const { tableHeaders, breakpoints } = props;

    const dynamicHeaders = useMemo(() => {
        if (!breakpoints) {
            return tableHeaders
        }

        const { sm, md, lg, xl } = breakpoints

        if (isMobile && sm) {
            return tableHeaders.filter((header) => sm.includes(header.id as any));
        }
        if (isTablet && md) {
            return tableHeaders.filter((header) => md.includes(header.id as any));
        }
        if (isDesktop && lg) {
            return tableHeaders.filter((header) => lg.includes(header.id as any));
        }
        if (isLargeScreen && xl) {
            return tableHeaders.filter((header) => xl.includes(header.id as any));
        }
        return tableHeaders;

    }, [breakpoints, isMobile, isTablet, isDesktop, isLargeScreen, tableHeaders]);

    // We may want to build out the content similar to the table head but using a type to drive what type of component i.e link, text, menu
    const tableContentColumnsToShow = useMemo(() => {
        return new Set(dynamicHeaders.map((dynamicTableHeader) => dynamicTableHeader.id));
    }, [dynamicHeaders]);

    return [dynamicHeaders, tableContentColumnsToShow] as [th: headerType[], c: Set<keyof T>];
}

// @mui
import { Theme } from '@mui/material/styles';
import {
  Box,
  Switch,
  SxProps,
  TablePagination,
  FormControlLabel,
  TablePaginationProps,
  InputLabel,
} from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
//

// ----------------------------------------------------------------------

type Props = {
  dense?: boolean;
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
  showDenseToggle?: boolean
};

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  showDenseToggle = true,
  ...other
}: Props & TablePaginationProps) {
  const isMobile = useResponsive('down', 'sm')
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        labelRowsPerPage={isMobile ? '# per page' : undefined}
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
      />

      {onChangeDense && showDenseToggle && (
        <FormControlLabel
          label="Dense"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: 'absolute',
            },
          }}
        />
      )}
    </Box>
  );
}

import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 180;

type Props = {
  earliestSelectableDate: Date;
  latestSelectableDate: Date;
  notAllowedText: string;
  errorText: string;
  setErrorText: Dispatch<SetStateAction<string>>;
  enableSubmit: boolean;       // submit button removed by design
  reportBeingLoaded: boolean;  // submit button removed by design
  filterEndDate: Date | null;
  filterStartDate: Date | null;
  optionsPreset: string[];
  valuePreset: string;
  centered: boolean;
  onChangePreset: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterEndDate: (value: Date | null) => void;
  onFilterStartDate: (value: Date | null) => void;
  onRequestReport: () => void;
  onResetFilter: VoidFunction;
};

const datePickerFormat = 'MM/dd/yyyy';

export default function PresetTimeRangePickerToolbar({
  earliestSelectableDate,
  latestSelectableDate,
  notAllowedText,
  errorText,
  setErrorText,
  enableSubmit,
  reportBeingLoaded,
  filterEndDate,
  filterStartDate,
  optionsPreset: optionsService,
  valuePreset,
  centered,
  onChangePreset,
  onFilterEndDate,
  onFilterStartDate,
  onRequestReport,
  onResetFilter,
}: Props) {
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [openEndPicker, setOpenEndPicker] = useState(false);

  const handleDateError = (e: any, dateLabel: string) => {
    if (e === null) {
      // console.log('clear ' + dateLabel + ' date error');
      setErrorText('');
      return;
    }
    console.error(dateLabel + ' date error', e);

    let displayedText = e.message ? e.message : 'Invalid ' + dateLabel + ' date.';

    if (dateLabel === 'start' || dateLabel === 'end') {
      if (e === 'minDate') {
        displayedText = 'Earliest date is ' + format(earliestSelectableDate, datePickerFormat);
      }
      else if (e === 'maxDate') {
        displayedText = 'Latest date is ' + format(latestSelectableDate, datePickerFormat);
      }
    }

    setErrorText(displayedText);
  };

  return (
    <Stack
      alignItems={centered ? "center" : "flex-start"}
      justifyContent="flex-end"
    >
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction={{
          xs: 'column',
          sm: 'row',
          // md: 'row',
        }}
      >
        <TextField
          fullWidth
          select
          label="Date Range"
          value={valuePreset}
          onChange={onChangePreset}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: { maxHeight: 220 },
              },
            },
          }}
          sx={{
            maxWidth: { md: INPUT_WIDTH },
            textTransform: 'capitalize',
          }}
        >
          {optionsService.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>

        <DatePicker
          label="Start date"
          open={openStartPicker}
          onClose={() => { setOpenStartPicker(false); }}
          maxDate={latestSelectableDate}
          minDate={earliestSelectableDate}
          value={filterStartDate}
          onChange={onFilterStartDate}
          // onChange={(e: any) => { console.log('ON CHANGE START', e); onFilterStartDate(e); }}
          onError={(e: any) => { handleDateError(e, 'start'); }}
          renderInput={(params) => {
            // console.warn('START render input', params);
            if (params.inputProps) {
              params.inputProps.readOnly = true; // no manual edits (they're really clumsy in Mui 5)

              // no edit-text cursor, since we open the picker on click
              params.inputProps.style = { cursor: 'pointer' };
            }
            return (
              <TextField
                {...params}
                fullWidth
                sx={{
                  maxWidth: { md: INPUT_WIDTH }, cursor: 'pointer'
                }}
                onClick={() => { setOpenStartPicker(true); }}
              />
            );
          }}
        />

        <DatePicker
          label="End date"
          open={openEndPicker}
          onClose={() => { setOpenEndPicker(false); }}
          maxDate={latestSelectableDate}
          minDate={earliestSelectableDate}
          value={filterEndDate}
          onChange={onFilterEndDate}
          // onChange={(e: any) => { console.log('ON CHANGE END', e); onFilterEndDate(e); }}
          onError={(e: any) => { handleDateError(e, 'end'); }}
          renderInput={(params) => {
            // console.warn('END render input', params);
            if (params.inputProps) {
              params.inputProps.readOnly = true; // no manual edits (they're really clumsy in Mui 5)

              // no edit-text cursor, since we open the picker on click
              params.inputProps.style = { cursor: 'pointer' };
            }
            return (
              <TextField
                {...params}
                fullWidth
                sx={{
                  maxWidth: { md: INPUT_WIDTH }, cursor: 'pointer'
                }}
                onClick={() => { setOpenEndPicker(true); }}
              />
            );
          }}
        />

        <Button
          // color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:close-fill" />}
        >
          Reset
        </Button>

        {/* KEEP: This is a good, working control. It's just hidden for now in lieu of a
                  state vars that pull data on every date change.
        <LoadingButton
          disabled={!enableSubmit}
          loading={reportBeingLoaded}
          variant='contained'
          sx={{ flexShrink: 0 }}
          onClick={onRequestReport}
        >
          Generate Report
        </LoadingButton>
        */}
      </Stack>

      {(errorText !== '' || notAllowedText !== '') &&
        <Typography
          variant="subtitle2"
          color="error"
          sx={{ pt: 3 }}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            (errorText !== '') ? errorText : (notAllowedText !== '') ? notAllowedText : ''
          }
        </Typography>
      }
    </Stack>
  );
}

import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { isValid } from 'date-fns';
import {
  useState
} from 'react';
import { NavLink } from 'react-router-dom';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import Scrollbar from '../../../components/scrollbar';
import useResponsive from '../../../hooks/useResponsive';
import PortalNotificationActionArea from '../../../portalNotification/components/sections/PortalNotificationActionArea';
import { PortalNotificationContextI, usePortalNotificationContext } from '../../../portalNotification/context/PortalNotificationProvider';
import {
  PortalNotification,
  PriorityType
} from '../../../portalNotification/model/PortalNotification';
import { PATH_HOME } from '../../../routes/paths';
import { fToNow } from '../../../utils/formatTime';
import { GAEventPopover, useAnalyticsContext } from '../../../auth/analytics';

// ----------------------------------------------------------------------
export default function PortalNotificationsPopover() {
  const { sendEvent } = useAnalyticsContext()
  const isDesktop = useResponsive('up', 'md')
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [loadingMarkAll, setLoadingMarkAll] = useState(false)
  const [selected, setSelected] = useState<string | null>(null)
  const {
    notifications,
    markRead,
    totalUnRead,
    readIdSet,
    read,
    refresh } = usePortalNotificationContext()

  //Opens menu
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {

    setOpenPopover(event.currentTarget);

    sendEvent(new GAEventPopover('notifications'))
  };
  //Closes menu
  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  //Mark all read. <Will compare ids to the read set to save on request>
  const handleMarkAllRead = async () => {
    console.log('starting', readIdSet.entries())
    setLoadingMarkAll(true)
    // get all unread ids
    const ids = notifications.reduce((accum, { id }) => {
      if (!readIdSet.has(id)) {
        accum = [...accum, id]
      }
      return accum
    }, [] as PortalNotification['id'][])
    // mark all unread ids
    await markRead(ids)
    setLoadingMarkAll(false)
  }

  return (
    <>
      <IconButtonAnimate
        color={openPopover
          ? 'primary'
          : 'default'}
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40
        }}
      >
        <Badge
          badgeContent={totalUnRead}
          color="error">
          <Iconify
            icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{
          minWidth: {
            xs: 320,
            md: 500
          },
        }}>
        <Scrollbar sx={{ minHeight: 200, maxHeight: 400 }}>
          <Stack p={2} direction='row'>
            <Box sx={{ flexGrow: 1 }}>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Typography variant="subtitle1">Notifications</Typography>
                {totalUnRead > 0 && (
                  <Tooltip title=" Mark all as read">
                    <Button
                      color="primary"
                      sx={{ whiteSpace: 'nowrap' }}
                      onClick={handleMarkAllRead}
                      disabled={loadingMarkAll}
                      startIcon={<Iconify
                        icon="eva:done-all-fill"
                        color={loadingMarkAll
                          ? 'text.disabled'
                          : totalUnRead === 0
                            ? 'success'
                            : 'primary'} />}
                    >
                      {isDesktop ? 'Mark all as read' : 'Mark all'}
                    </Button>
                  </Tooltip>
                )}
              </Stack>
              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}>
                You have {totalUnRead} unread messages
              </Typography>
            </Box>
          </Stack>

          <Divider sx={{ borderStyle: 'dotted' }} />

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{
                  py: 1,
                  px: 2.5,
                  typography: 'overline'
                }}>
                Unread
              </ListSubheader>
            }
          >
            {notifications.filter(
              ({ id }) => !readIdSet.has(id)).map(
                (data) =>
                  <PortalNotificationPopoverMenuItem
                    setSelected={setSelected}
                    selected={selected === data.id}
                    notification={data}
                    key={data.subject}
                    markRead={markRead}
                    readOn={read[data.id]
                      ? new Date(read[data.id])
                      : null} />
              )}
          </List>
          <Divider sx={{ borderStyle: 'dotted' }} />
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                Read
              </ListSubheader>
            }
          >
            {notifications.filter(
              ({ id }) => readIdSet.has(id)).map(
                (notification) => (
                  <PortalNotificationPopoverMenuItem
                    setSelected={setSelected}
                    selected={selected === notification.id}
                    key={notification.id + '_achknowledged'}
                    notification={notification}
                    readOn={read[notification.id] ? new Date(read[notification.id]) : null} />
                ))}
          </List>
          <Stack width='100%'>
            <Divider flexItem sx={{ borderStyle: 'dotted', width: '100%', my: 1 }} />
            <Button fullWidth component={NavLink} to={PATH_HOME.user.notifications} onClick={refresh}>See All</Button>
          </Stack>
        </Scrollbar>
      </MenuPopover >
    </>
  );
}

// ----------------------------------------------------------------------
function mapNotificationColor(type: PriorityType): BadgeProps['color'] {
  switch (type) {
    case PriorityType.High:
      return 'error';
    case PriorityType.Medium:
      return 'warning';
    default:
      return 'primary'
  }
}

function PortalNotificationPopoverMenuItem(
  { notification, markRead, readOn, selected, setSelected }:
    {
      notification: PortalNotification,
      markRead?: PortalNotificationContextI['markRead'],
      readOn: Date | null,
      selected: boolean,
      setSelected: (id: string | null) => void
    }
) {

  const [collapsed, setCollapsed] = useState(true)

  const expandInfo = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (collapsed === true) {
      setSelected(notification.id)
      setCollapsed(false)
    } else {
      setSelected(null)
      setCollapsed(true)
    }
  }

  const badgeColor = mapNotificationColor(notification.priority)
  return (
    <ListItemButton
      disableGutters
      defaultValue={notification.id}
      selected={selected}
      id={notification.id + '-listItem_btn'}
      onClick={expandInfo}
      sx={{
        px: 2
      }}
    >
      {!isValid(readOn) &&
        <StyledBadge
          variant='dot'
          color={badgeColor}
          sx={{
            position: 'absolute',
            right: 14,
            top: 12,
          }} />
      }
      <Stack sx={{ width: '100%' }}>
        {collapsed &&
          <ListItemText
            disableTypography
            primary={notification.subject}
            secondary={
              <Stack
                direction="row"
                sx={{
                  mt: 0.5,
                  typography: 'caption',
                  color: 'text.disabled'
                }}
              >
                <Iconify
                  icon={readOn
                    ? 'eva:done-all-outline'
                    : 'eva:clock-fill'}
                  width={16}
                  sx={{
                    mr: 0.5,
                    color: isValid(readOn)
                      ? 'success.main'
                      : 'inherit'
                  }}
                />
                {isValid(readOn)
                  ? fToNow(readOn)
                  : fToNow(notification.last_modified_date_ISO)
                }
              </Stack>
            }
          />
        }
        {!collapsed &&
          <ListItemText
            disableTypography
            primary={notification.subject}
            secondary={
              <Stack sx={{ width: '100%' }}>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{
                    mt: 0.5,
                    typography: 'caption',
                    color: 'text.primary',
                  }}
                >
                  {notification.message}
                </Stack>
                {notification.hasLink &&
                  <PortalNotificationActionArea
                    sx={{
                      maxWidth: '33.3%',
                      my: 1
                    }}
                    size='small'
                    to={notification.actionLinkUrl}
                    text={notification.actionLinkText}
                  />
                }
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  typography='caption'
                  color='text.disabled'
                  width='100%'
                >
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <Iconify
                      icon={readOn
                        ? 'eva:done-all-outline'
                        : 'eva:clock-fill'}
                      width={16}
                      sx={{
                        mr: 0.5,
                        color: isValid(readOn)
                          ? 'success.main'
                          : 'inherit'
                      }}
                    />
                    {isValid(readOn)
                      ? fToNow(readOn)
                      : fToNow(notification.last_modified_date_ISO)
                    }
                  </div>
                  {typeof markRead === 'function' &&
                    <Button
                      onClick={() => {
                        setCollapsed(true)
                        markRead([notification.id])
                      }
                      }
                      size='small'
                      color='primary'
                      startIcon={
                        <Iconify
                          icon="eva:book-outline"
                        />
                      }
                    >
                      mark read
                    </Button>
                  }
                </Stack>
              </Stack>
            }
          />
        }
      </Stack>
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------
const StyledBadge = styled(Badge)(({ theme, color }) => ({
  '& .MuiBadge-badge': {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    color: theme.palette.background.paper,
    '&::after': {
      backgroundColor: 'inherit',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.9)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.5)',
      opacity: 0,
    },
  },
}));
import { FirebaseError } from "firebase/app";
import { AuthErrorCodes } from 'firebase/auth';
import { FunctionsErrorCode, FunctionsError } from 'firebase/functions';
import { FirestoreErrorCode, FirestoreError } from 'firebase/firestore';

// Auth Errors
const firebaseAuthErrorInteral = AuthErrorCodes.INTERNAL_ERROR;
const firebaseAuthErrorNetworkRequestFailed = AuthErrorCodes.NETWORK_REQUEST_FAILED;
// const firebaseErrorTimeOut = AuthErrorCodes.TIMEOUT;

// DB Errors
// const firestoreErrorInternal: FirestoreErrorCode = 'internal';
const firestoreErrorUnknown: FirestoreErrorCode = 'unknown';
const firestoreErrorClientOffline: FirestoreErrorCode = 'unavailable';
const firestoreErrorDataLoss: FirestoreErrorCode = 'data-loss';

// GCF Errors
// const functionsErrorInternal: FunctionsErrorCode = 'functions/internal';
const functionsErrorAlreadyExists: FunctionsErrorCode = 'functions/already-exists';
const functionsErrorCancelled: FunctionsErrorCode = 'functions/cancelled';
const functionsErrorInvalid: FunctionsErrorCode = 'functions/invalid-argument'
const functionsErrorNotFound: FunctionsErrorCode = 'functions/not-found'
const functionsErrorAborted: FunctionsErrorCode = 'functions/aborted'
// ? Have not seen these yet.
// const functionsErrorUnknown: FunctionsErrorCode = 'functions/unknown';
// const functionsErrorClientOffline: FunctionsErrorCode = 'functions/unavailable';
// const functionsErrorDataLoss: FunctionsErrorCode = 'functions/data-loss';

// Messages
const messageClientOffline = "Failed to get document because the client is offline."
/**
 * @param firebaseError - an error. (works off error.code or error.message)
 * @returns false if this error should not be reported to sentry.
 */
export function shouldReportToSentryFirebaseError(firebaseError: FirebaseError | FirestoreError | FunctionsError | any) {
    if (firebaseError === messageClientOffline || firebaseError?.message === messageClientOffline) {
        return false;
    }
    switch (firebaseError?.code) {
        // Add any firebase error codes you would like to ignore from sentry here.
        case firebaseAuthErrorInteral:
        case firebaseAuthErrorNetworkRequestFailed:
        case firestoreErrorClientOffline:
        case firestoreErrorDataLoss:
        case firestoreErrorUnknown:
        case functionsErrorAlreadyExists:
        case functionsErrorCancelled:
        case functionsErrorInvalid:
        case functionsErrorNotFound:
        case functionsErrorAborted:
            // case firebaseErrorTimeOut:
            // case firestoreErrorInternal:
            // case functionsErrorInternal:
            // case functionsErrorUnknown:
            // case functionsErrorClientOffline:
            // case functionsErrorDataLoss:
            return false;
        default:
            return true;
    }
    return true;
}
;

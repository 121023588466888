import { LabelColor } from "../../components/label";

export const escapeButtonLabel = 'Back';

export const showTableTabCounts = true;

export type TableTabInfo = { value: string, label: string, color: LabelColor | undefined, count: number };

//NOTE: Yup's email validator is broken. See: https://github.com/jquense/yup/issues/507
// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

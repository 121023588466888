import { TaskStatusMapped } from "../../../models/mrr/taskGeneral";

export function priorityColorByValue(priority_value: number) {
    switch (priority_value) {
        case 0:
            return 'error';
        case 1:
            return 'primary';
        case 2:
            return 'secondary';
        default:
            return 'info';
    }
}

export function statusColorByStatusMapped(statusMapped: string) {
    switch (statusMapped) {
        case TaskStatusMapped.in_progress:
            return 'primary'
        case TaskStatusMapped.not_started:
            return 'warning'
        case TaskStatusMapped.completed:
            return 'success'
        case TaskStatusMapped.deffered:
            return 'error'
        default:
            return 'info'
    }
}
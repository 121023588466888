import './locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------
import './development.css'
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { PropsWithChildren } from 'react';
import * as Sentry from "@sentry/react";
import { store, persistor } from './redux/store';
import Router from './routes';
import ThemeProvider from './theme';
import ThemeLocalization from './locales';
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';
import { MotionLazyContainer } from './components/animate';
import { ThemeSettings, SettingsProvider } from './components/settings';

import { AuthProvider } from './auth/FirebaseContext';
// eslint-disable-next-line import/no-named-as-default
import ReduxMessageProvider from './components/error-message/ErrorMessageProvider';
import PortalNotificationProvider, { usePortalNotificationContext } from './portalNotification/context/PortalNotificationProvider';
import WindowEventProvider, { useWindowEvents } from './portalNotification/hooks/windowEvent/EventProvider';
import TimerBackoffProvider, { TimerBackoffType } from './portalNotification/hooks/TimerBackoff/TimerBackoffProvider';
import { StandardErrorFallback } from './components/error-handling/StandardErrorFallback';
import { EmergencyFallbackErrorText } from './utils/mrr/errorHandling';
import { AnalyticsProvider } from './auth/analytics';

// ----------------------------------------------------------------------
//! GA4 is intelligent enough to measure all of these interactions automatically.
//? ReactGA.send({hitType:'pageview', page: window.location.pathname})

export default function App() {
	return (
		<Sentry.ErrorBoundary
			beforeCapture={(scope) => {
				console.warn('sentry captured error', scope);
				scope.setTag('error scope', 'root sentry');
			}}
			fallback={<StandardErrorFallback reportToSentry={false} errorInfo={null} customErrorMessage={EmergencyFallbackErrorText} />}
		>
			<AuthProvider>
				<AnalyticsProvider>
					<HelmetProvider>
						<ThemeColor />
						<ReduxProvider store={store}>
							<PersistGate loading={null} persistor={persistor}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<SettingsProvider>
										<BrowserRouter>
											<ScrollToTop />
											<MotionLazyContainer>
												<ThemeProvider>
													<ThemeSettings>
														<ThemeLocalization>
															<SnackbarProvider>
																<ReduxMessageProvider>
																	<StyledChart />
																	<PortalNotificationProvider>
																		<WindowEventProvider>
																			<TimerBackoffNotificationProvider>
																				<Router />
																			</TimerBackoffNotificationProvider>
																		</WindowEventProvider>
																	</PortalNotificationProvider>
																</ReduxMessageProvider>
															</SnackbarProvider>
														</ThemeLocalization>
													</ThemeSettings>
												</ThemeProvider>
											</MotionLazyContainer>
										</BrowserRouter>
									</SettingsProvider>
								</LocalizationProvider>
							</PersistGate>
						</ReduxProvider>
					</HelmetProvider>
				</AnalyticsProvider>
			</AuthProvider>
		</Sentry.ErrorBoundary>
	);
}

function ThemeColor() {
	return (
		<Helmet>
			<meta name="theme-color" content="#fff" />
		</Helmet>
	)
}

function TimerBackoffNotificationProvider({ children }: PropsWithChildren) {
	const { refresh } = usePortalNotificationContext()
	const { visible } = useWindowEvents()
	return (
		<TimerBackoffProvider active={visible} onIntervalComplete={refresh} type={TimerBackoffType.exponential}>
			{children}
		</TimerBackoffProvider>
	)
}

import { Button } from "@mui/material";
import Iconify from "../../../components/iconify";

export function MarkReadBtn({ read }: { read: VoidFunction; }) {
    return (
        <Button
            onClick={read}
            variant="text"
            startIcon={<Iconify icon='eva:done-all-outline' />}
            sx={{
                height: '35px',
                textTransform: 'none'
            }}
        >
            Mark read
        </Button>
    );
}

import MenuPopover from "../../../components/menu-popover/MenuPopover";
import { ReservationGeneral } from "../../../models/mrr/reservationGeneral";
import { ReservationStage } from "../../../utils/mrr/reservationConstants";
import ReservationDetails from "../../@dashboard/reservation/ReservationDetails";
import { useReservationPopoverState } from "../../@dashboard/reservation/list/useReservationPopoverState";

interface ReservationDetailsPopoverI {
    popoverState: ReturnType<typeof useReservationPopoverState>,
    reservation: ReservationGeneral
}

export default function ReservationDetailsPopover(props: ReservationDetailsPopoverI) {
    const { popoverState, reservation } = props
    return (
        <MenuPopover
            open={popoverState.selectedRow}
            onClose={() => popoverState.setSelectedRow(null)}
            arrow="left-center"
            sx={{ width: 'auto', height: 'fit-content' }}
        >
            <ReservationDetails
                disableActions={reservation.stage_mapped === ReservationStage.Canceled}
                reservation={reservation}
                {...popoverState}
            />
        </MenuPopover>
    )
}
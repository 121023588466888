import { useState } from 'react';
import { confirmActionT } from '../ReservationDetails';
import { httpsCallable } from 'firebase/functions';
import { CLOUD_FUNCTIONS } from '../../../../auth/FirebaseContext';
import { cloud_makeSalesforceRequest, cloud_postReservationChangeRequest } from '../../../../utils/mrr/cloudFunctions';
import { useSnackbar } from 'notistack';
import { endpoint_postReservationChange } from '../../../../utils/mrr/cloudEndpointNames';



export const useReservationPopoverState = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const [openDropinForm, setOpenDropinForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState<HTMLElement | null>(null);
    const [openEditDatePicker, setOpenEditDatePicker] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [confirmAction, setConfirmAction] = useState<confirmActionT | null>(null);
    const [dropRequestUserMessage, setDropRequestUserMessage] = useState('')

    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    const handleCloseDropinForm = () => {
        setDropRequestUserMessage('')
        setOpenDropinForm(false);
    };
    const handleOpenCancelConfirmed = () => {
        setOpenConfirm(true)
        setConfirmAction('cancel')
        handleClosePopover()
    };
    const handleOpenDropConfirmed = () => {
        setOpenConfirm(true)
        setConfirmAction('drop')
        handleClosePopover()
    };
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setOpenPopover(event.currentTarget);
    };
    const handleOpenDropinForm = () => {
        setOpenDropinForm(true);
    };
    const handleDrop = async (id: string) => {
        if (!id) {
            throw new Error('Problem making reservation request.')
        }
        if (!dropRequestUserMessage) {
            // exit confirm mode and return to form (User will never be able to get here)
            setOpenConfirm(false)
            return
        }

        try {
            const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_makeSalesforceRequest + '?' + endpoint_postReservationChange);

            const data = {
                method: 'POST',
                path: '/api/owner/reservation/change_request',
                params: {id: id},
                body: {
                    message: dropRequestUserMessage
                }
            };

            await cloudFunction(data)
            enqueueSnackbar("Drop-in request received.")
        } catch (error) {
            enqueueSnackbar("Problem with Drop-in request.")
            console.error('problem making dropin request', error)
        }

        //Last
        handleCloseDropinForm()
        setOpenConfirm(false)
        setConfirmAction(null)
    }
    return {
        openPopover,
        handleClosePopover,
        setOpenPopover,
        selectedRow,
        setSelectedRow,
        openEditDatePicker,
        setOpenEditDatePicker,
        openConfirm,
        setOpenConfirm,
        confirmAction,
        setConfirmAction,
        handleOpenCancelConfirmed,
        handleOpenPopover,
        handleOpenDropConfirmed,
        handleCloseDropinForm,
        handleOpenDropinForm,
        openDropinForm,
        dropRequestUserMessage,
        setDropRequestUserMessage,
        handleDrop
    };
};

import { Alert, Button, ButtonGroup, Link, Stack, Tooltip, Typography } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { ConfirmationResult, signInWithPhoneNumber } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { AUTH, CLOUD_FUNCTIONS } from "../../auth/FirebaseContext";
import { SignInMethod, useLoginFlow } from "../../auth/LoginProvider";
import { cloud_emailOneTimePassword, cloud_updateUserBrand } from "../../utils/mrr/cloudFunctions";
import { formatLastFour } from "../../utils/mrr/formatLastFour";
import Iconify from "../iconify";
import { brandConfig } from "../../config";


type SelectVerificationMethodProps = {
    next(confirm?: ConfirmationResult): void,
    back(): void,
};

const SelectVerificationMethod = ({ next, back }: SelectVerificationMethodProps) => {
    const { emailToVerify, phoneToVerify, setSignInMethod, recaptcha } = useLoginFlow();
    const [error, setError] = useState<string | null>(null);
    const [sendingCode, setSendingCode] = useState<null | SignInMethod>(null);

    const handleSignInMethod = async (methodValue: SignInMethod) => {
        setSendingCode(methodValue);
        if (methodValue === SignInMethod.Email) {
            const request = httpsCallable<{ email: string, brand: string }, boolean>(CLOUD_FUNCTIONS, cloud_emailOneTimePassword);
            const sentSignInEmailCode = await request({ email: emailToVerify, brand: brandConfig.brandCode });
            if (!sentSignInEmailCode) {
                setError('Sorry we could not email you a code. Please try again.')
                setSendingCode(null);
                return;
            }
            setSignInMethod(methodValue);
            setSendingCode(null);
            next();
            return
        }
        if (methodValue === SignInMethod.Phone && recaptcha) {
            await signInWithPhoneNumber(AUTH, phoneToVerify, recaptcha).then(async (nextPayload) => {
                const phoneBrandRequest = httpsCallable<{ email: string, brand: string }, boolean>(CLOUD_FUNCTIONS, cloud_updateUserBrand);
                await phoneBrandRequest({ email: emailToVerify, brand: brandConfig.brandCode });
                setSignInMethod(methodValue);
                setSendingCode(null);
                next(nextPayload);
            }).catch((e) => {
                setSendingCode(null);
                if (e instanceof FirebaseError && e.code === 'auth/too-many-requests') {
                    setError('Too many attempts. Please try again later.')
                    return;
                };
                setError('Sorry we could not send you a code. Please try again.')
            })
        }
    };
    const loadingIcon = <Iconify icon='line-md:loading-twotone-loop' width='1.8rem' />
    return (
        <Stack spacing={2} direction='column'>
            {!error && <Alert severity="info">
                To continue, please select a verification method.
                <br />
                You will be sent a 6-digit code for login.
            </Alert>}
            {error && <Alert severity="warning">
                {error}
            </Alert>}
            <ButtonGroup fullWidth orientation='vertical' variant='outlined'>
                <Tooltip title={`Send a verification code to ${emailToVerify}.`} placement='top'>
                    <Button disabled={sendingCode !== null} onClick={async () => { await handleSignInMethod(SignInMethod.Email) }} value={SignInMethod.Email} sx={{ textTransform: 'none', display: 'flex', justifyContent: 'flex-start', p: 2 }} >
                        <Stack direction='row' alignItems='center' justifyContent='flex-start' width='100%'>
                            {sendingCode !== SignInMethod.Email ? <Iconify icon='line-md:email' width='2rem' /> : loadingIcon}
                            <Typography variant='body1' px={2} textAlign='start'>
                                Email code to {emailToVerify.slice(0, 4)}••••••
                            </Typography>
                        </Stack>
                    </Button>
                </Tooltip>
                <Tooltip title={`Text phone ending in ${formatLastFour(phoneToVerify)} a verification code.`} placement='bottom'>
                    <Button disabled={sendingCode !== null} onClick={async () => { await handleSignInMethod(SignInMethod.Phone) }} value={SignInMethod.Phone} sx={{ textTransform: 'none', display: 'flex', justifyContent: 'flex-start', p: 2 }} >
                        <Stack direction='row' alignItems='center' justifyContent='flex-start' width='100%'>
                            {sendingCode !== SignInMethod.Phone ? <Iconify icon='line-md:phone' width='1.8rem' /> : loadingIcon}
                            <Typography variant='body1' px={2} textAlign='start' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
                                Text code to ••••••{formatLastFour(phoneToVerify)}
                            </Typography>
                        </Stack>
                    </Button>
                </Tooltip>
            </ButtonGroup>
            <Link onClick={back} sx={{ width: 'fit-content', cursor: 'pointer' }}>Back</Link>
        </Stack>
    )
};

export default SelectVerificationMethod;
// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../empty-content';
import CommonLoadingIndicator from '../mrr/CommonLoadingIndicator';

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
};

export default function TableLoading({ isNotFound }: Props) {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <>
            <EmptyContent
              title="Loading..."
              sx={{
                '& span.MuiBox-root': { height: 160 },
              }}
            />
            <CommonLoadingIndicator isNotFound={isNotFound} />
          </>
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

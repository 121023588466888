// ----------------------------------------------------------------------

// import heatmap from "../sections/_examples/extra/map/heatmap";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_ADMIN = '/admin';
const ROOTS_USER = '/user';
const ROOTS_HOME = '/';

export const OOB_ACTION = 'action';
export const OOB_CONFIRM_EMAIL = 'confirm-email';
export const OOB_EMAIL_VERIFIED = 'email-verified';
export const OOB_FORGOT_PASSWORD = 'forgot-password';
export const OOB_SET_PASSWORD = 'set-password';

// ----------------------------------------------------------------------

export const PATH_USER = {
  root: ROOTS_USER,
  login: path(ROOTS_USER, '/login'),
  register: path(ROOTS_USER, '/register'),
  loginUnprotected: path(ROOTS_USER, '/login-unprotected'),
  registerUnprotected: path(ROOTS_USER, '/register-unprotected'),
  verify: path(ROOTS_USER, '/verify'),
  forgotPassword: path(ROOTS_USER, '/' + OOB_FORGOT_PASSWORD),
  newPassword: path(ROOTS_USER, '/' + OOB_SET_PASSWORD),
  action: path(ROOTS_USER, '/' + OOB_ACTION),
  confirm: path(ROOTS_USER, '/' + OOB_CONFIRM_EMAIL),
  notVerified: path(ROOTS_USER, '/not-verified')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  error: '/error',
  logout: '/logout'
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  blank: path(ROOTS_ADMIN, '/blank'),
  general: {
    app: path(ROOTS_ADMIN, '/app'),
  },
  user: {
    root: path(ROOTS_ADMIN, '/user'),
    profile: path(ROOTS_ADMIN, '/user/profile'),
    // cards: path(ROOTS_ADMIN, '/user/cards'),
    list: path(ROOTS_ADMIN, '/user/list'),
    emulator: path(ROOTS_ADMIN, '/user/emulator'),
    new: path(ROOTS_ADMIN, '/user/new'),
    // edit: (name: string) => path(ROOTS_ADMIN, `/user/${name}/edit`),
    edit: (email: string) => path(ROOTS_ADMIN, `/user/${email}/edit`),
    account: path(ROOTS_ADMIN, '/user/account'),
  },
  property: {
    root: path(ROOTS_ADMIN, '/property'),
    // profile: path(ROOTS_ADMIN, '/property/profile'),
    // list: path(ROOTS_ADMIN, '/property/list'),
    // new: path(ROOTS_ADMIN, '/property/new'),
    edit: (id: string) => path(ROOTS_ADMIN, `/property/${id}/edit`)
  },
  notVerified: path(ROOTS_ADMIN, '/not-verified')
};

export const PATH_HOME = {
  root: ROOTS_HOME,
  kanban: '/kanban',
  calendar: '/calendar',
  fileManager: '/files-manager',
  permissionDenied: '/permission-denied',
  blank: '/blank',
  general: {
    rtkqtest: '/rtkqtest',
    app: '/app',
    ecommerce: '/ecommerce',
    analytics: '/analytics',
    banking: '/banking',
    booking: '/booking',
    file: '/file',
    timeshare: '/timeshare',
    statements: '/statements',
  },
  reports: {
    bookings: '/reports/bookings',
    occupancy: '/reports/occupancy',
    revenue: '/reports/revenue',
  },
  mail: {
    root: '/mail',
    all: '/mail/all',
  },
  chat: {
    root: '/chat',
    new: '/chat/new',
    view: (name: string) => `/chat/${name}`,
  },
  user: {
    root: '/user',
    // new: path(ROOTS_DASHBOARD, '/user/new'),
    // list: path(ROOTS_DASHBOARD, '/user/list'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: '/user/profile',
    account: '/user/account',
    notifications: '/user/notifications'
    // // edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // edit: (email: string) => path(ROOTS_DASHBOARD, `/user/${email}/edit`),
  },
  booking: {
    list: '/booking',
    view: (id: string) => `/booking/${id}`,
    edit: (id: string) => `/booking/edit/${id}`,
    create: `/booking/create`,
    createFromListing: (id: string) => `/booking/create/${id}`,
    calendar: '/booking-calendar',
    heatmap: '/booking-heatmap'
  },
  property: {
    root: '/property',
    view: (id: string) => `/property/${id}`,
    // profile: path(ROOTS_DASHBOARD, '/listing/overview'),
    // list: path(ROOTS_DASHBOARD, '/property/list'),
    edit: (id: string) => `/property/${id}/edit`,
  },
  case: {
    root: '/case'
  },
  eCommerce: {
    root: '/e-commerce',
    shop: '/e-commerce/shop',
    list: '/e-commerce/list',
    checkout: '/e-commerce/checkout',
    new: '/e-commerce/product/new',
    view: (name: string) => `/e-commerce/product/${name}`,
    edit: (name: string) => `/e-commerce/product/${name}/edit`,
    demoEdit: '/e-commerce/product/nike-blazer-low-77-vintage/edit',
    demoView: '/e-commerce/product/nike-air-force-1-ndestrukt',
  },
  invoice: {
    root: '/invoice',
    list: '/invoice/list',
    new: '/invoice/new',
    view: (id: string) => `/invoice/${id}`,
    edit: (id: string) => `/invoice/${id}/edit`,
    demoEdit: '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit',
    demoView: '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
  },
  blog: {
    root: '/blog',
    posts: '/blog/posts',
    new: '/blog/new',
    view: (title: string) => `/blog/post/${title}`,
    demoView: '/blog/post/apply-these-7-secret-techniques-to-improve-event',
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
import { Typography, Grid, Stack, } from '@mui/material';
import { FDateFormat, fDate } from '../../../utils/formatTime';
import Label from '../../../components/label/Label';
import { priorityColorByValue, statusColorByStatusMapped } from './mapColorHelpers';
import { DetailRow, DetailColumn, DetailContainer } from '../../../layouts/simple/DetailColumnAndRow';
import { TaskGeneral } from '../../../models/mrr/taskGeneral';

// ----------------------------------------------------------------------
export type confirmActionT = 'edit' | 'cancel' | 'drop' | 'edit_confirm'
type Props = {
	task: TaskGeneral;// | null | undefined;
};
// ----------------------------------------------------------------------

export default function TaskDetails({
	task,
}: Props) {

	return (
		<DetailContainer>
			<Grid item>
				<Typography variant='h5'>
					{task.title}
				</Typography>
				{task.related_name &&
					<Typography
						variant='subtitle1'
						color='text.disabled'
					>
						{task.related_name}
					</Typography>
				}
			</Grid>

			<DetailRow>
				{/* Priority */}
				<DetailColumn>
					<Stack spacing={.5}>
						<Typography variant="overline" sx={{ color: 'text.disabled' }}>Priority</Typography>
						<Label
							width='fit-content'
							variant="soft"
							color={priorityColorByValue(task.priority_value)}>
							{task.priority}
						</Label>
					</Stack>
				</DetailColumn>
				{/* Status */}
				<DetailColumn>
					<Stack spacing={.5}>
						<Typography variant="overline" sx={{ color: 'text.disabled' }}>Status</Typography>
						<Label
							width='fit-content'
							variant="soft"
							color={statusColorByStatusMapped(task.status_mapped)}>
							{task.status_mapped}
						</Label>
					</Stack>
				</DetailColumn>
			</DetailRow>

			<DetailRow>
				{/* Type */}
				<DetailColumn>
					<Typography variant="overline" sx={{ color: 'text.disabled' }}>Type</Typography>
					<Typography variant="body1">{task.type || '-'}</Typography>
				</DetailColumn>
				{/* Sub Type */}
				<DetailColumn>
					<Typography variant="overline" sx={{ color: 'text.disabled' }}>Sub Type</Typography>
					<Typography variant="body1">{task.sub_type || '-'}</Typography>
				</DetailColumn>
			</DetailRow>

			<DetailRow>
				{/* Assignee */}
				<DetailColumn fullWidth>
					<Typography variant="overline" sx={{ color: 'text.disabled' }}>Assigned To</Typography>
					<Typography variant="body1">{task.assignee_name}</Typography>
				</DetailColumn>
			</DetailRow>

			<DetailRow>
				{/* Created On */}
				<DetailColumn>
					<Typography variant="overline" sx={{ color: 'text.disabled' }}>Created On</Typography>
					<Typography variant="body1">{fDate(task.created_at_ISO, FDateFormat.localized_us_numeric_month_day_year)}</Typography>
				</DetailColumn>
				{/* Due Date */}
				<DetailColumn>
					<Typography variant="overline" sx={{ color: 'text.disabled' }}>Due Date</Typography>
					<Typography variant="body1">{fDate(task.dueDate, FDateFormat.localized_us_numeric_month_day_year)}</Typography>
				</DetailColumn>
			</DetailRow>
			{task.start_time &&
				<DetailRow>
					{/* Created On */}
					<DetailColumn>
						<Typography variant="overline" sx={{ color: 'text.disabled' }}>Started</Typography>
						<Typography variant="body1">{fDate(task.start_time, FDateFormat.localized_us_numeric_month_day_year)}</Typography>
					</DetailColumn>
					{/* Due Date */}
					<DetailColumn>
						<Typography variant="overline" sx={{ color: 'text.disabled' }}>Completed</Typography>
						<Typography variant="body1">{task.completed_time ? fDate(task.completed_time, FDateFormat.localized_us_numeric_month_day_year) : '-'}</Typography>
					</DetailColumn>
				</DetailRow>
			}
			<DetailRow>
				<DetailColumn fullWidth>
					<Typography variant="overline" sx={{ color: 'text.disabled' }}>Updated</Typography>
					<Typography variant="body1">{fDate(task.last_modified_date_ISO, FDateFormat.localized_date_time_full)}</Typography>
				</DetailColumn>
			</DetailRow>
		</DetailContainer>
	);
}
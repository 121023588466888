import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useReducer, useRef } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { isBefore, isValid } from "date-fns";
import { PriorityType, PortalNotification, ReadPortalNotification, InstantiatePortalNotificationFromJSON } from "../model/PortalNotification";
import { useAuthContext } from "../../auth/useAuthContext";
import { portalNotificationsFirestoreKey, usePortalNotificationsFetcher } from "../hooks/usePortalNotificationsFetcher";
import { roleCanSeePortalNotifications } from "../../assets/data";
import { DB } from "../../auth/FirebaseContext";
import { AuthUserType } from "../../auth/types";

enum NotificationActionKind {
    READ = 'READ',
    REFRESH = 'REFRESH',
    CLEAR = 'CLEAR',
}

// An interface for our actions
interface PortalNotificationAction {
    type: NotificationActionKind;
    payload: PortalNotificationState;
}

// An interface for our state
interface PortalNotificationState {
    notifications: PortalNotification[];
    read: ReadPortalNotification;
}
// Our reducer function that uses a switch statement to handle our actions
function PortalNotificationReducer(state: PortalNotificationState, action: PortalNotificationAction) {
    const { type, payload } = action;
    switch (type) {
        case NotificationActionKind.READ:
        case NotificationActionKind.REFRESH:
        case NotificationActionKind.CLEAR:
            return {
                ...payload,
            };
        default:
            return state;
    }
}
const initailState = {
    notifications: [],
    read: {},
    readIdSet: new Set<PortalNotification['id']>(),
    markRead: async () => undefined,
    totalUnRead: 0,
    lastRefresh: new Date(),
    refresh: async () => undefined,
    childrenTopMargin: 0,
    highPriorityPortalNotification: [],
    mediumPriorityPortalNotification: [],
    showHighPriorityToolbar: false,
    showMediumPriorityToolbar: false
}
export interface PortalNotificationContextI extends PortalNotificationState {
    readIdSet: Set<PortalNotification['id']>,
    markRead: (id: PortalNotification['id'][]) => Promise<void>,
    totalUnRead: number,
    refresh: () => Promise<void>,
    childrenTopMargin: number,
    highPriorityPortalNotification: PortalNotification[],
    mediumPriorityPortalNotification: PortalNotification[],
    showHighPriorityToolbar: boolean,
    showMediumPriorityToolbar: boolean
}
// Initial Context definition
export const PortalNotificationContext =
    createContext<PortalNotificationContextI>
        (initailState)

const standardTopMargin = 56;

// Context Provider
export default function PortalNotificationProvider(props: PropsWithChildren) {
    //NOTE: This line may need to be custom in each app. We just need a Firebase user.
    const { user } = useAuthContext()
    const isOwner = roleCanSeePortalNotifications(user?.role)
    const { children } = props
    const { docRef, handleRequest } = usePortalNotificationsFetcher()
    const [state, dispatch] = useReducer(PortalNotificationReducer, initailState)
    const readIdSet = useMemo(() => new Set(Object.keys(state.read)), [state])
    const initialized = useRef(false)

    const clear = () => {
        dispatch({
            payload: initailState,
            type: NotificationActionKind.CLEAR
        })
    }

    const markRead = useCallback(
        async (ids: PortalNotification['id'][]) => {
            // Api call here
            const { notifications, read } = state // from the reducer state
            const updatedRead = { ...read } // { [notification.id] : date in utc }
            ids.forEach((id) => {
                updatedRead[id] = new Date().toUTCString()
                readIdSet.add(id)
            })
            if (user) {
                try {
                    if (docRef === null) {
                        return
                    }
                    const userDoc = await getDoc(docRef)
                    const updatedDoc = {
                        ...userDoc.data(),
                        'read_notification_id': updatedRead
                    }
                    await setDoc(docRef, updatedDoc)
                } catch (error) {
                    console.error('Failed to update firestore', error)
                }
            }
            dispatch({
                payload: {
                    notifications,
                    read: updatedRead
                }, type: NotificationActionKind.READ
            })
        }, [docRef, readIdSet, state, user])

    const refresh = useCallback(async () => {
        // Api call here
        let readState = state.read
        let notificationState = state.notifications
        if (user) {
            try {
                if (docRef === null) {
                    return
                }
                const portal_notification_doc = await getDoc(docRef)
                if (portal_notification_doc.exists()) {
                    readState = await portal_notification_doc.data()?.read_notification_id || []
                }
            } catch (error) {
                console.error('Failed to read firestore.', error)
            }
            try {
                const requestData = await handleRequest()

                const newState: PortalNotification[] = requestData.map((json: any) => InstantiatePortalNotificationFromJSON(json))
                notificationState = newState.sort((a, b) => {
                    return a.priority_sort_order - b.priority_sort_order;
                })
            } catch (error) {
                console.error('Failed to request notifications.', error)
            }
        }
        /*
        --- Handle Stale read notification ids ---
        This will not write to firestore. The update happens on actions like
        markRead, where stale ids will be over-written.
        */
        if (readState && notificationState) {
            removeStaleReadIds(readState, notificationState)
            updateModifedNotificationsReadState(readState, notificationState, user)
        }

        dispatch({
            payload: {
                notifications: notificationState,
                read: readState
            }, type: NotificationActionKind.REFRESH
        })

    }, [docRef, handleRequest, state.notifications, state.read, user])

    const highPriorityPortalNotification = useMemo(() =>
        state.notifications.filter((notice) => notice.priority === PriorityType.High), [state])

    const mediumPriorityPortalNotification = useMemo(() =>
        state.notifications.filter((notice) =>
            notice.priority === PriorityType.Medium
            && !readIdSet.has(notice.id)// filter read medium priority out
        ), [state, readIdSet])

    const showHighPriorityToolbar = useMemo(() => highPriorityPortalNotification.length > 0, [highPriorityPortalNotification])
    const showMediumPriorityToolbar = useMemo(() => mediumPriorityPortalNotification.length > 0, [mediumPriorityPortalNotification])

    const childrenTopMargin = useMemo(() => {
        let topMargin = 0
        if (!isOwner) {
            return topMargin
        }
        if (showHighPriorityToolbar) {
            topMargin += standardTopMargin
        }
        if (showMediumPriorityToolbar) {
            topMargin += standardTopMargin
        }
        return topMargin
    }, [showHighPriorityToolbar, showMediumPriorityToolbar, isOwner])

    const value = useMemo(() => (
        {
            ...state,
            readIdSet: readIdSet,
            markRead,
            totalUnRead: Math.max(0, state.notifications.length - readIdSet.size),
            refresh,
            childrenTopMargin,
            highPriorityPortalNotification,
            mediumPriorityPortalNotification,
            showHighPriorityToolbar,
            showMediumPriorityToolbar,
        }
    ), [state, readIdSet, markRead, refresh, childrenTopMargin, highPriorityPortalNotification, mediumPriorityPortalNotification, showHighPriorityToolbar, showMediumPriorityToolbar])

    if (!initialized.current && user) {
        initialized.current = true
        refresh()
    } else if (initialized.current && !user) {
        initialized.current = false
        clear()
    }

    return (
        <PortalNotificationContext.Provider value={value}>
            {children}
        </PortalNotificationContext.Provider>
    )

}

export function usePortalNotificationContext() {
    return useContext(PortalNotificationContext)
}

/**
 *
 * @param readObject object containing read notification ids and the date they where read.
 * { [notification_id]: read_datetime }
 * @param activeNotificationsArray array of active Portal Notifications.
 * @description mutates the readObject to remove any stale ids.
 */
function removeStaleReadIds(readObject: ReadPortalNotification, activeNotificationsArray: PortalNotification[]) {
    const notificationIds = new Set(activeNotificationsArray.map(({ id }) => id))
    // Get all of the read ids. (Possible containing stale values)
    const readStateIds = Object.keys(readObject)
    // Remove all stale ids.
    readObject = readStateIds.reduce((accum, id) => {
        if (!notificationIds.has(id)) {
            // remove stale ids
            delete accum[id]
        }
        return accum
    }, readObject)
}

/**
 *
 * @param readObject object containing read notification ids and the date they where read.
 * { [notification_id]: read_datetime }
 * @param activeNotificationsArray array of active Portal Notifications.
 * @param user User for firestore
 * @description loops through all active Portal Notifications and removes from Firestore all read ids
 * that where before the last modified time
 */
function updateModifedNotificationsReadState(
    readObject: ReadPortalNotification,
    activeNotificationsArray: PortalNotification[],
    user: AuthUserType | null) {
    activeNotificationsArray.forEach(({ id, last_modified_date_ISO }) => {
        if (isValid(new Date(readObject[id])) && isBefore(new Date(readObject[id]), last_modified_date_ISO)) {
            // this id is marked read but has been modified since.
            delete readObject[id]
            if (user) {
                const docRef = doc(DB, portalNotificationsFirestoreKey, user.id);
                try {
                    if (docRef === null) {
                        return
                    }
                    getDoc(docRef).then(async (userDoc) => {
                        const updatedDoc = {
                            ...userDoc.data(),
                            'read_notification_id': readObject
                        }
                        await setDoc(docRef, updatedDoc)
                    })
                } catch (error) {
                    console.error('Failed to unread message.', error)
                }
            }
        }
    })
}
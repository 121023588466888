//! Reservation is the internal name for Booking
import { parseISO } from "date-fns";
import { ReservationStage } from "../../utils/mrr/reservationConstants";
import { ListingGeneral } from "./listingGeneral";
import { UserAccountGeneral } from "./userAccountGeneral";

export interface ReservationFormValuesProps {
    id?: string,
    listing: ListingGeneral | null,
    guest_is_owner: string | boolean,
    guest_first_name: UserAccountGeneral['nameFirst'],
    guest_last_name: UserAccountGeneral['nameLast'],
    guest_email: UserAccountGeneral['email'],
    guest_phone: UserAccountGeneral['phoneNumber'],
    check_in: Date | null, //? using a date instead of string for now. This may change
    check_out: Date | null,
    guest_pay_final_cleaning: string | boolean
}

export interface EditReservationFormI {
    id: string;
    listing_id: string;
    guest_id: string;
    check_in?: Date;
    check_out?: Date;
}

export enum ReservationType {
    //! These are reservation types in the external model.
    Lessee = 'Lessee',
    GuestOccupancy = 'Guest Occupancy',
    OwnerOccupancy = 'Owner Occupancy',
    Maintenance = 'Maintenance',
    Housekeeping = 'Housekeeping',
    External = 'External',
    Internal = 'Internal',
    Orphan = 'Orphan',
    //! These are from the API for type and next_check_in_type.
    Owner = 'Owner',
    GuestOfOwner = 'Guest of Owner',
    Guest = 'Guest'
}

//NOTE: Keep in mind that Reservation type and guest_type can be remapped by the backend!
//      You will not necessarily get a value from the SF picklist.
//
//      The model field 'guest_type' derives from the reservation's type (with exceptions for
//      Maintenance and disaster relief), so checking:
//        (row.type === ReservationType.Owner || row.type === ReservationType.GuestOfOwner)
//      ...is often the literal equivalent of checking:
//        (row.guest_type === ReservationGuestType.Owner || row.guest_type === ReservationGuestType.OwnerGuest)

export enum ReservationGuestType {
    // Guest Types original
    Owner = 'Owner',
    OwnerGuest = 'Owner Guest',
    Guest = 'Guest',
    Other = 'Other',

    // Guest Types overridden when there is a disaster type
    ReliefWorker = 'Relief Worker',
    Survivor = 'Survivor',
    MRREmployeeSurvivor = 'MRR Employee Survivor',
    MRROhanaSurvivor = 'MRR Ohana Survivor',
    NoShow = 'No Show'
}
//NOTE: TODO: Technically this extends the listing model. Investigate mirroring that
//            structure on the client side.
export class ReservationGeneral {
    public readonly listing_status_mapped: string;
    public readonly sortable_name: string;
    public readonly stage_mapped: string;
    public readonly booking_date_ISO: Date
    public readonly check_in_ISO: Date
    public readonly check_out_ISO: Date
    public readonly next_check_in_ISO: Date
    public readonly ui_table_guest_name: string

    constructor(
        public readonly back_to_back: boolean,
        public readonly bathrooms: number,
        public readonly bedrooms: number,
        private readonly _booking_date: string,
        private readonly _check_in: string,
        public readonly check_in_gap: number,
        private readonly _check_out: string,
        public readonly check_out_gap: number,
        public readonly guest_id: string,
        public readonly guest_is_owner: boolean,
        public readonly guest_name: string,
        public readonly guest_type: ReservationGuestType,
        public readonly has_check_in_requests: boolean,
        public readonly id: string,
        public readonly listing_id: string,
        private readonly listing_status: string,
        public readonly name: string,
        public readonly name_detailed: string,
        private readonly _next_check_in: string,
        public readonly next_check_in_type: string,
        public readonly nightly_rate: number,
        public readonly nights: number,
        public readonly previous_check_out: string,
        public readonly rental_amount: number,
        public readonly split_beds: boolean,
        private readonly stage: string,
        public readonly turnaround: boolean,
        public readonly type: string,
        public readonly unit_id: string,
        public readonly owner_pays_guest_clean: boolean,
        public readonly arrival_time: string,
        public readonly departure_time: string,
        public readonly is_future_booking: boolean,
    ) {
        this.sortable_name = this.name.toLowerCase();
        this.stage_mapped = ReservationGeneral.MapStageToEnum(this.stage);
        this.listing_status_mapped = ListingGeneral.MapStatusToEnum(this.listing_status);
        this.booking_date_ISO = parseISO(this._booking_date)
        this.check_in_ISO = parseISO(this._check_in)
        this.check_out_ISO = parseISO(this._check_out)
        this.next_check_in_ISO = parseISO(this._next_check_in)

        if (!this.arrival_time) {
            this.arrival_time = '';
        }

        if (!this.departure_time) {
            this.departure_time = '';
        }

        this.ui_table_guest_name = this.type === ReservationType.Maintenance ? ReservationType.Maintenance : this.guest_name
    }

    private static MapStageToEnum(stageIn: string): ReservationStage {
        const lowerCased = stageIn.toLowerCase();

        switch (lowerCased) {
            case 'confirmed':
            case 'closed won': {
                return ReservationStage.Confirmed;
            }

            // case 'quote':
            // case 'pending': {
            //     return ReservationStage.Pending;
            // }

            case 'closed lost': {
                // case 'closed nonrefundable':
                // case 'closed rescheduled':
                return ReservationStage.Canceled;
            }

            default: {
                console.warn('unknown reservation stage: ' + lowerCased + ' as "' + stageIn + '"');
                return ReservationStage.Internal_Unknown;
            }
        }
    }
};


export function InstantiateReservationFromJSON(jsonRecord: any) {
    const newModel = new ReservationGeneral(
        jsonRecord.back_to_back,
        jsonRecord.bathrooms,
        jsonRecord.bedrooms,
        jsonRecord.booking_date,
        jsonRecord.check_in,
        jsonRecord.check_in_gap,
        jsonRecord.check_out,
        jsonRecord.check_out_gap,
        jsonRecord.guest_id,
        jsonRecord.guest_is_owner,
        jsonRecord.guest_name,
        jsonRecord.guest_type,
        jsonRecord.has_check_in_requests,
        jsonRecord.id,
        jsonRecord.listing_id,
        jsonRecord.listing_status,
        jsonRecord.name,
        jsonRecord.name_detailed,
        jsonRecord.next_check_in,
        jsonRecord.next_check_in_type,
        jsonRecord.nightly_rate,
        jsonRecord.nights,
        jsonRecord.previous_check_out,
        jsonRecord.rental_amount,
        jsonRecord.split_beds,
        jsonRecord.stage,
        jsonRecord.turnaround,
        jsonRecord.type,
        jsonRecord.unit_id,
        jsonRecord.owner_pays_guest_clean,
        jsonRecord.arrival_time,
        jsonRecord.departure_time,
        jsonRecord.is_future_booking
    );

    return newModel;
};

import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/material";
import { useState } from "react";
import { useSettingsContext } from "../../components/settings";
import { CheckEnvironment, SupportedEnvironments } from "../../config-global";

export interface CrashableChildProps {
    unused?: boolean[];
}

export default function CrashableChild( { unused }: CrashableChildProps) {
    const { themeStretch } = useSettingsContext();

    const [devJSXCrashEnabled, setDevJSXCrashEnabled] = useState(false);

    const onTestAButtonClick = () => {
        console.log('test A button clicked');

        setDevJSXCrashEnabled(true);
    }

    if (!CheckEnvironment(SupportedEnvironments.Dev)) {
        // failsafe, this doesn't have a prod usage
        return null;
    }

    return (
        <Container
            maxWidth={
                themeStretch
                    ? 'md'
                    : 'lg'
            }
            sx={{margin: 5}}
        >
            <LoadingButton loading={false} variant='outlined' onClick={onTestAButtonClick}>
                Trigger Error in Child Component
            </LoadingButton>

            {devJSXCrashEnabled &&
            <>
                {
                    //FORCE THIS: "TypeError: g.map is not a function"
                    // brokenObject.map((v: any) => { return (<div>{v}</div>); }) // intentional bad invoke

                    //FORCE THIS: "TypeError: ??"
                    (unused as any).map((v: any) => { return (<div>{v}</div>); }) // intentional bad invoke
                }
            </>
            }
        </Container>
    )
}
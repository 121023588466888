// @mui
import { Stack, InputAdornment, TextField, MenuItem, Button } from '@mui/material';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

type Props = {
    label: string,
    filterName: string;
    filterType: string;
    isFiltered: boolean;
    optionsType: string[];
    onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFilterType: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onResetFilter: VoidFunction;
};

export default function TaskTableToolbar({
    label,
    filterName,
    filterType,
    isFiltered,
    optionsType,
    onFilterName,
    onFilterType,
    onResetFilter,
}: Props) {
    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{ px: 2.5, py: 3 }}
        >
            <TextField
                fullWidth
                select
                label={label}
                value={filterType}
                onChange={onFilterType}
                SelectProps={{
                    MenuProps: {
                        PaperProps: {
                            sx: {
                                maxHeight: 260,
                            },
                        },
                    },
                }}
                sx={{
                    maxWidth: { sm: 240 },
                    textTransform: 'capitalize',
                }}
            >
                {optionsType.map((option, i) => (
                    <MenuItem
                        key={'menu_item_' + i}
                        value={option}
                        sx={{
                            mx: 1,
                            borderRadius: 0.75,
                            typography: 'body2',
                            textTransform: 'capitalize',
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                fullWidth
                value={filterName}
                onChange={onFilterName}
                placeholder="Search..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                    ),
                }}
            />

            {isFiltered && (
                <Button
                    color="error"
                    sx={{ flexShrink: 0 }}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="eva:trash-2-outline" />}
                >
                    Clear
                </Button>
            )}
        </Stack>
    );
}

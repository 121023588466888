import { useDispatch, useSelector } from 'src/redux/store';
// import { showError } from 'src/redux/slices/error';
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { removeError, removeMessage } from 'src/redux/slices/apiMessage';
import { safelyReadErrorMessage } from '../../utils/mrr/errorHandling';

// ----------------------------------------------------------------------

const DISPLAY_TIME = 5 * 1000;

export default function ReduxMessageProvider({ children }: PropsWithChildren) {
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()

	const errorsSelector = useSelector(state => state.apiMessage.errors);
	const messagesSelector = useSelector(state => state.apiMessage.messages);

	const [processingError, setProcessingError] = useState(false)
	const [processingMessage, setProcessingMessage] = useState(false)
	// console.log('error array', errorsSelector)
	// console.log('msg array', messagesSelector)

	//! Handle Errors ----------------
	useEffect(() => {
		if (errorsSelector.length && !processingError) {
			setProcessingError(true)
			const curError = errorsSelector[0]
			const message = safelyReadErrorMessage(null, curError.error)
			enqueueSnackbar(message, { variant: 'error', onClick: curError.onClick })
			dispatch(removeError(curError.id))
			setProcessingError(false)
		}
	}, [errorsSelector, enqueueSnackbar, dispatch, processingError])

	//! Handle Messages ----------------
	useEffect(() => {
		if (messagesSelector.length && !processingMessage) {
			setProcessingMessage(true)
			const curMessage = messagesSelector[0]
			const variant = curMessage.variant || 'success'
			enqueueSnackbar(curMessage.message, { variant: variant as any, onClick: curMessage.onClick })
			// console.log('clean up', curMessage.id)
			dispatch(removeMessage(curMessage.id))
			setProcessingMessage(false)
		}
	}, [messagesSelector, enqueueSnackbar, dispatch, processingMessage])
	// useEffect(() => {
	// 	const renderError = priorityErrorSelector !== null
	// 		&& !priorityErrorSelector.shown
	// 		&& (Date.now() - priorityErrorSelector.timeAdded) < DISPLAY_TIME;

	// 	if (renderError) {
	// 		enqueueSnackbar(priorityErrorSelector.message, { variant: 'error' });
	// 		dispatch(showError(priorityErrorSelector.id));

	// 		// IN-PROGRESS:
	// 		// Talk to Ray: Can/should we use a state here (in a Provider)?
	// 		// I'd ideally do last-shown and/or last-show time, and suppress repeats that way.

	// 	}
	// }, [enqueueSnackbar, priorityErrorSelector, dispatch]);

	return (
		<>
			{/*TEST INFO {renderError &&
				<div style={{position: 'absolute', zIndex: 32767}}>
					<h5>message: { priorityErrorSelector.message }</h5>
					<h5>id: { priorityErrorSelector.id }</h5>
					<h5>timeAdded: { priorityErrorSelector.timeAdded }</h5>
				</div>
			} */}
			{children}
		</>
	);
}

/*KEEP: Timer ref
const maxPreloadTime = 4 * 1000;
const verboseTrackPreload = false; // keep this until the feature is proved out on staging

COMPONENT
	const [preloadWindowOpen, setPreloadWindowOpen] = useState(true);

	useEffect(() => {
		if (verboseTrackPreload) { console.log('PRELOAD effect hit'); }
		const preloadTimerId = setTimeout(() => {
			if (verboseTrackPreload) { console.log('PRELOAD tick'); }
			if (preloadWindowOpen) {
				if (verboseTrackPreload) { console.log('PRELOAD close window in timer'); }
				setPreloadWindowOpen(false);
			}
			else {
				// eslint-disable-next-line no-lonely-if
				if (verboseTrackPreload) { console.log('PRELOAD miss (window already closed)'); }
			}
		}, maxPreloadTime);

		return () => {
			if (verboseTrackPreload) { console.log('PRELOAD cleanup'); }
			clearTimeout(preloadTimerId);
		};
	}, [preloadWindowOpen]);

	if (preloadWindowOpen && !isListingsFetching) {
		if (verboseTrackPreload) { console.log('PRELOAD close window in body'); }
		setPreloadWindowOpen(false);
	}
*/
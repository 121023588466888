import { Navigate, useRoutes } from 'react-router-dom';
import { Roles, adminRoleNames } from '../assets/data';
import AdminGuard from '../auth/AdminGuard';
import AuthGuard from '../auth/AuthGuard';
import DeveloperGuard from '../auth/DeveloperGuard';
import GuestGuard from '../auth/GuestGuard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import { PageErrorGuard } from '../components/error-handling/PageErrorGuard';
import { CheckEnvironment, SupportedEnvironments, WritePostLoginPath } from '../config-global';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import ListingDetailsPage from '../pages/dashboard/ListingDetailsPage';
import ReservationLandingPage from '../pages/dashboard/ReservationLandingPage';
import TestPage from '../pages/dev/TestPage';
import { OwnerReportType } from '../utils/mrr/ownerReportConstants';
import {
  BlankPage,
  CasePage,
  ComingSoonPage,
  DemoNoticesPage,
  DemoWidgetsPage,
  ErrorPage,
  FaqsPage,
  GeneralAppPage,
  ListingListPage,
  LogOutPage,
  LoginPage,
  MRRNotVerifiedPage,
  MRRVerifiedPage,
  MaintenancePage,
  NotificationPage,
  OobActionLandingPage,
  OwnerReportPage,
  Page403,
  Page404,
  Page500,
  PermissionDeniedPage,
  ReservationCalendarPage,
  ReservationCreatePage,
  ReservationEditPage,
  ReservationListPage,
  StatementsPage,
  UserAccountPage,
  UserCreatePage,
  UserEditPage,
  UserEmulatorPage,
  UserListPage,
  UserProfilePage
} from './elements';
import { OOB_ACTION, OOB_EMAIL_VERIFIED, PATH_PAGE, PATH_USER } from './paths';


// ----------------------------------------------------------------------
//auth/login auth/register
export default function Router() {
  return useRoutes([
    // User
    {
      path: 'user',
      children: [
        { element: <Navigate to={PATH_USER.action} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <PageErrorGuard>
                <LoginPage />
              </PageErrorGuard>
            </GuestGuard>
          ),
        },
        // ! Disabled self sign up
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <RegisterPage />
        //     </GuestGuard>
        //   ),
        // },
        {
          path: 'notifications',
          element: (
            <AuthGuard>
              <DashboardLayout showNotifications={false} />
            </AuthGuard>
          ),
          children: [
            { index: true, element: <PageErrorGuard> <NotificationPage /> </PageErrorGuard> }
          ]
        },
        {
          path: 'not-verified',
          element:
            <AuthGuard>
              <CompactLayout />
            </AuthGuard>
          ,
          children: [
            { index: true, element: <MRRNotVerifiedPage /> }
          ]
        },
        // { path: 'login-unprotected', element: <LoginPage /> },
        // { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: OOB_ACTION, element: <OobActionLandingPage /> },
            { path: OOB_EMAIL_VERIFIED, element: <MRRVerifiedPage /> },
          ],
        },
      ],
    },

    // Admin Home
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AdminGuard>
            <RoleBasedGuard hasContent roles={adminRoleNames}>
              <DashboardLayout showNotifications />
            </RoleBasedGuard>
          </AdminGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={WritePostLoginPath(Roles.Admin)} replace />, index: true },
        { path: 'app', element: <PageErrorGuard> <GeneralAppPage /> </PageErrorGuard> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/admin/user/profile" replace />, index: true },
            { path: 'profile', element: <PageErrorGuard> <UserProfilePage /> </PageErrorGuard> },
            { path: 'list', element: <PageErrorGuard> <UserListPage /> </PageErrorGuard> },
            { path: 'emulator', element: <PageErrorGuard> <UserEmulatorPage /> </PageErrorGuard> },
            { path: 'new', element: <PageErrorGuard> <UserCreatePage /> </PageErrorGuard> },
            { path: ':email/edit', element: <PageErrorGuard> <UserEditPage /> </PageErrorGuard> },
            { path: 'account', element: <PageErrorGuard> <UserAccountPage /> </PageErrorGuard> },
          ],
        },
      ],
    },
    {
      path: 'faqs',
      element: (
        <AuthGuard>
          <DashboardLayout showNotifications />
        </AuthGuard>
      ),
      children: [
        { element: <PageErrorGuard> <FaqsPage /> </PageErrorGuard>, index: true }
      ]
    },
    {
      path: 'demo',
      element: (
        <AuthGuard>
          <DeveloperGuard>
            <DashboardLayout showNotifications />
          </DeveloperGuard>
        </AuthGuard>
      ),
      children: [
        { path: 'widgets', element: <PageErrorGuard> <DemoWidgetsPage /> </PageErrorGuard> },
        { path: 'notices', element: <PageErrorGuard> <DemoNoticesPage /> </PageErrorGuard> },
      ]
    },
    // Dashboard
    {
      path: '/',
      element: (
        <AuthGuard>
          <AdminGuard>
            <DashboardLayout showNotifications={false} />
          </AdminGuard>
        </AuthGuard>
      ),
      children: [{
        path: 'booking', children: [
          { element: <PageErrorGuard> <ReservationEditPage /> </PageErrorGuard>, path: 'edit/:id' },
          { element: <PageErrorGuard> <ReservationCreatePage /> </PageErrorGuard>, path: 'create' },
          { element: <PageErrorGuard> <ReservationCreatePage /> </PageErrorGuard>, path: 'create/:id' }, // pre-set listing Id
        ]
      }]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <AdminGuard>
            <DashboardLayout showNotifications />
          </AdminGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={WritePostLoginPath(Roles.User)} replace />, index: true },
        {
          path: 'app', children: [
            { index: true, element: <PageErrorGuard> <GeneralAppPage /> </PageErrorGuard> },
          ]
        },
        { path: 'calendar', element: <PageErrorGuard> <ReservationCalendarPage /> </PageErrorGuard> },
        {
          path: 'booking', children: [
            { element: <PageErrorGuard> <ReservationListPage /> </PageErrorGuard>, index: true },
            { element: <PageErrorGuard> <ReservationLandingPage /> </PageErrorGuard>, path: ':id' },
          ]
        },
        // { path: 'timeshare', element: <PageErrorGuard> <TimeSharePage /> </PageErrorGuard> },
        { path: 'timeshare', element: <Navigate to={PATH_PAGE.comingSoon} replace />, index: true },
        { path: 'statements', element: <PageErrorGuard> <StatementsPage /> </PageErrorGuard> },
        { path: 'case', element: <PageErrorGuard> <CasePage /> </PageErrorGuard> },
        {
          path: 'reports',
          children: [
            { element: <Navigate to="/dashboard/reports" replace />, index: true },
            { path: 'occupancy', element: <PageErrorGuard> <OwnerReportPage reportType={OwnerReportType.Occupancy} /> </PageErrorGuard> },
            { path: 'revenue', element: <PageErrorGuard> <OwnerReportPage reportType={OwnerReportType.Revenue} /> </PageErrorGuard> },
            { path: 'bookings', element: <PageErrorGuard> <OwnerReportPage reportType={OwnerReportType.Booking} /> </PageErrorGuard> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'account', element: <PageErrorGuard> <UserAccountPage /> </PageErrorGuard> },
          ],
        },
        {
          path: 'property',
          children: [
            { index: true, element: <PageErrorGuard> <ListingListPage /> </PageErrorGuard> },
            { element: <PageErrorGuard> <ListingDetailsPage /> </PageErrorGuard>, path: ':id' },
          ],
        },
        {
          path: 'test', element: CheckEnvironment(SupportedEnvironments.Dev)
            ? (<PageErrorGuard> <TestPage unused={undefined as any} /> </PageErrorGuard>)
            : <Navigate to="/404" replace />
        }, // dev only route
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'error', element: <ErrorPage /> },
        { path: 'logout', element: <LogOutPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// @mui
import { Stack, InputAdornment, TextField, MenuItem, Button } from '@mui/material';
// components
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

type Props = {
  filterSearch: string;
  filterUnitId: string;
  isFiltered: boolean;
  optionsUnitId: string[];
  onFilterSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterUnitId: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResetFilter: VoidFunction;
  singleListing?: boolean,
  onFilterGuestType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filterGuestType: string;
  optionsGuestType: string[];
};

export default function ReservationTableToolbar({
  filterSearch,
  filterUnitId,
  isFiltered,
  optionsUnitId,
  onFilterSearch,
  onFilterUnitId,
  onResetFilter,
  singleListing = false,
  onFilterGuestType,
  filterGuestType,
  optionsGuestType
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      {!singleListing &&
        <TextField
          fullWidth
          select
          label="Property"
          value={filterUnitId}
          onChange={onFilterUnitId}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 260,
                },
              },
            },
          }}
          sx={{
            maxWidth: { sm: 175 },
            textTransform: 'capitalize',
          }}
        >
          {optionsUnitId.map((option, i) => (
            <MenuItem
              key={option + '-' + i}
              value={option}
              sx={{
                mx: 1,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      }
      <TextField
        fullWidth
        select
        label="Guest Type"
        value={filterGuestType}
        onChange={onFilterGuestType}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: 260,
              },
            },
          },
        }}
        sx={{
          maxWidth: { sm: 175 },
          textTransform: 'capitalize',
        }}
      >
        {optionsGuestType.map((option, i) => (
          <MenuItem
            key={option + '-' + i}
            value={option}
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        )
        )}
      </TextField>

      <TextField
        fullWidth
        value={filterSearch}
        onChange={onFilterSearch}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}

import { Box, Card, Stack, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledCardContent } from '../../components/containers/StyledCardContent';
import { brandConfig } from '../../config';
// ----------------------------------------------------------------------


export default function LoginLayout({ children }: PropsWithChildren) {
  return (
    <>
      <StyledBackground />
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        width={1}
        height={1}
        flexGrow={1}
        style={{ backdropFilter: 'blur(8px)' }}>
        <Card raised sx={{ width: { sm: 400, xs: 350 } }}>
          <StyledCardContent>
            <Stack className='login-content' sx={{ width: 1 }}>
              {children}
            </Stack>
          </StyledCardContent>
        </Card>
      </Box>
    </>
  );
}

//----------------
const StyledBackground = styled('div')(({ theme }) => ({
  backgroundImage: `url(${brandConfig.backgroundUrl})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: -1
}));
export const StyledRoot = styled('main')(({ theme }) => ({
  backdropFilter: 'blur(8px)',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: '400px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  // padding: '10vh 10px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
  '.login-content': {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5, 4),
    height: 'fit-content',
    display: 'flex',
    borderRadius: '10px',
  }
}));
import { useCallback } from 'react';
// eslint-disable-next-line import/no-cycle
import { useAuthContext } from '../../auth/useAuthContext';
import { standardFBExpiredMessage } from '../../redux/store';

//? In the case that a request is preformed without the use of RTK Query, this hook will handle an expired user session and call logout.
export function useExpiredSessionErrorToNavigate() {
    const { logout } = useAuthContext();

    return useCallback((error: any) => {
        if (error.message === standardFBExpiredMessage) {
            logout();
        }
    }, [logout]);
}

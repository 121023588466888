import { Grid, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReservationFormValuesProps, ReservationGeneral } from '../../../../../models/mrr/reservationGeneral';
import { useGetRTKQReservationsQuery } from '../../../../../redux/rtkQuery/apiSlice';
import { ReservationDatePicker } from '../../ReservationDatePicker/ReservationDatePicker';
import dateTimezone from '../../../../../utils/date-timezone';
import { brandConfig } from '../../../../../config';

//--------- Step Three form

const checkInRange = (start: moment.Moment, end: moment.Moment, blockedDates: ReservationGeneral[]) => {

    for (let i = 0; i < blockedDates.length; i++) {
        // const blockedStart = moment(blockedDate.check_in_ISO).tz(brandConfig.timezone)
        // const blockedEnd = moment(blockedDate.check_out_ISO).tz(brandConfig.timezone)
        const blockedDate = blockedDates[i]
        if (start.isSameOrAfter(blockedDate.check_in_ISO)
            && start.isBefore(blockedDate.check_out_ISO)
            && end.isAfter(blockedDate.check_in_ISO)
            && end.isSameOrBefore(blockedDate.check_in_ISO)) {
            return false
        }
    }
    return true
}

interface DatePickerFormI {
    ignoredOwnerReservation?: ReservationGeneral,
    listingId: string,
    title?: string,
}

const PRELOAD_DURATION_MS = 90 * 24 * 60 * 60 * 1000; // 90 days; we'll essentially never have a booking that long
export const datePickerStartDate = new Date(Date.now() - PRELOAD_DURATION_MS);

export default function DatePickerForm(props: DatePickerFormI) {
    const { ignoredOwnerReservation, listingId, title } = props

    //NOTE: This fetch essentially repeates the same query from an earlier step in the wizard. It will get the cached result.
    const {
        data: reservationData,
        error: reservationsError,
        isFetching,
        isSuccess,
        isError
    } = useGetRTKQReservationsQuery({ listingId: listingId, startDate: datePickerStartDate, includePending: true })

    const reservationsRelatedToListing = useMemo(() => {
        if (!isSuccess) {
            return [];
        }
        const listingReservations = reservationData.filter((reservationRelatedToListing) => reservationRelatedToListing.listing_id === listingId);
        return listingReservations;
    }, [reservationData, listingId, isSuccess]);

    const blockedDates = useMemo(() => {
        return !isSuccess
            ? []
            : ignoredOwnerReservation
                ? reservationsRelatedToListing.filter(({ id }) => id !== ignoredOwnerReservation.id)
                : reservationsRelatedToListing
    }, [ignoredOwnerReservation, isSuccess, reservationsRelatedToListing])

    const { watch, setValue, setError, formState: { errors } } = useFormContext<ReservationFormValuesProps>()
    const { check_in, check_out, listing } = watch()


    const checkIn = useMemo(() => check_in ? moment(check_in) : null, [check_in])
    const checkOut = useMemo(() => check_out ? moment(check_out) : null, [check_out])

    const setCheckIn = useCallback((date: moment.Moment | null) => { setValue('check_in', date ? date.toDate() : null) }, [setValue])
    const setCheckOut = useCallback((date: moment.Moment | null) => { setValue('check_out', date ? date.toDate() : null) }, [setValue])

    useEffect(() => {
        if (!checkIn) { return }
        if (!checkOut) { return }
        if (!checkInRange(checkIn, checkOut, blockedDates)) {
            setCheckIn(null)
            setCheckOut(null)
            setError('check_out', { message: 'The selected dates are not available.' })
        }
    }, [blockedDates, checkIn, checkOut, setCheckIn, setCheckOut, setError])

    //NOTE: We can't draw the form until we get our reservatation data back AND filter it.
    //      It's currently counting on reservationsRelatedToListing[0] which doesn't exist at first.
    return (
        <Grid container direction='column' spacing={2}>
            <Grid item>
                {isFetching
                    ?
                    <Typography variant='h5'>Loading dates...</Typography>
                    : (isSuccess &&
                        <Typography variant='h5'>
                            {title
                                ? title
                                : `Please select dates for ${listing?.name_detailed || reservationsRelatedToListing[0].name_detailed}`
                            }
                        </Typography>)
                }
            </Grid>
            <Grid item>
                {isFetching &&
                    <Skeleton width={275} height={55}>
                        <Typography variant='h3'>Loading...</Typography>
                    </Skeleton>
                }
                {!isFetching && <ReservationDatePicker
                    startDateName='check_in'
                    endDateName='check_out'
                    blockedDates={blockedDates.map((res) => {
                        //NOTE: We use the _reservation's_ daylight savings. Just getting the system
                        //      offset now doesn't work, because reservations before a dst jump will
                        //      be off by -1 (or vice-versa)
                        const checkInAsTimezone = moment(res.check_in_ISO).tz(brandConfig.timezone).toDate();
                        const checkOutAsTimezone = moment(res.check_out_ISO).tz(brandConfig.timezone).toDate();
                        // console.log(res)
                        // console.log('Add date blocker', res.id,
                        //     '\nIN\n', res.check_in_ISO, '\n', checkInAsHI,
                        //     '\nOUT\n', res.check_out_ISO, '\n', checkOutAsHI
                        // );

                        // checkInAsHI.setHours(12);
                        // checkOutAsHI.setHours(12);

                        return { check_in: checkInAsTimezone, check_out: checkOutAsTimezone, sfID: res.id };
                    })}
                />}
                {errors?.check_out && <Typography variant='body2' color='error'>{errors.check_out.message}</Typography>}
            </Grid>
        </Grid>
    )
}

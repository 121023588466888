
export const endpoint_getAttachments = 'getAttachments';
export const endpoint_getCases = 'getCases';
export const endpoint_getListing = 'getListing';
export const endpoint_getListings = 'getListings';
export const endpoint_getLOIs = 'getLOIs';
export const endpoint_getReport = 'getReport';
export const endpoint_getReservation = 'getReservation';
export const endpoint_getReservations = 'getReservations';
export const endpoint_getStatements = 'getStatements';
export const endpoint_getTasks = 'getTasks';

export const endpoint_patchLMI = 'patchLMI';
export const endpoint_postCaseComment = 'postCaseComment';
export const endpoint_postReservation = 'postReservation';
export const endpoint_postReservationChange = 'postReservationChange';
export const endpoint_putReservationCancel = 'putReservationCancel';
export const endpoint_putReservationUpdate = 'putReservationUpdate';

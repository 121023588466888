import { Divider, IconButton, Tab, Table, TableBody, TableContainer, Tabs, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import Iconify from "../../../components/iconify/Iconify";
import { LabelColor } from "../../../components/label";
import Label from "../../../components/label/Label";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import { TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, getComparator, useTable } from "../../../components/table";
import TableLoading from "../../../components/table/TableLoading";
import { useDynamicTableHeaders } from "../../../components/table/useDynamicTableHeaders";
import ListingOrderItemGeneral from "../../../models/mrr/loi";
import { TaskGeneral } from "../../../models/mrr/taskGeneral";
import { useGetRTKQListingOrderItemsQuery, useGetRTKQTasksQuery } from "../../../redux/rtkQuery/apiSlice";
import { TASK_TAB_OPTION, TASK_TAB_OPTIONS, TASK_TYPE_OPTIONS } from "../../../utils/mrr/taskConstants";
import { TableTabInfo, showTableTabCounts } from "../../../utils/mrr/uiConstants";
import { useTableWidth } from "../useTableWidth";
import { LoiTableRow } from "./LoiTableRow";
import { TaskTableRow } from "./TaskTableRow";
import TaskTableToolbar from './TaskTableToolbar';

interface TaskTableI {
    listingId?: string
}

// ----------------------------------------------------------------------

export default function TaskTable(props: TaskTableI) {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        // setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable({
        defaultDense: true,
        defaultOrder: 'desc',
        defaultOrderBy: 'last_modified_date_ISO'
    });

    const { listingId } = props

    const tableMinWidth = useTableWidth()

    const singleListingMode = !!listingId

    const {
        data: tasks,
        isSuccess: isTasksSuccess,
        isFetching: isTaskFetching
    } = useGetRTKQTasksQuery({ listingId: listingId })

    const {
        data: lois,
        isSuccess: isLOISuccess,
        isFetching: isLOIFetching
    } = useGetRTKQListingOrderItemsQuery({ listingId: listingId })

    const [filterName, setFilterName] = useState('');

    const [filterType, setFilterType] = useState('all');

    const [openConfirm, setOpenConfirm] = useState(false);

    const [filterTab, setFilterTab] = useState(TASK_TAB_OPTION.loi);

    const toolBarOptions = useMemo(() => {
        return filterTab === TASK_TAB_OPTION.task
            ? TASK_TYPE_OPTIONS
            : isLOISuccess
                ? ['all', ...Array.from(new Set(lois.map((loi) => loi.sub_type)))]
                : ['all']
    }, [filterTab, isLOISuccess, lois])

    const TABLE_HEAD: {
        id: keyof TaskGeneral | keyof ListingOrderItemGeneral;
        label?: string;
        align?: string;
    }[] = useMemo(() => {
        return filterTab === TASK_TAB_OPTION.task ? [
            { id: 'title', label: 'Title', align: 'left' },
            { id: 'status_mapped', label: 'Status', align: 'left' },
            { id: 'assignee_name', label: 'Assignee', align: 'left' },
            { id: 'related_name', label: 'Related To', align: 'left' },
            { id: 'dueDate', label: 'Due Date', align: 'left' },
            { id: 'last_modified_date_ISO', label: 'Updated', align: 'left' },
        ] : singleListingMode
            // LOIs
            ? [
                { id: 'title', label: 'Title', align: 'left' },
                { id: 'amount', label: 'Amount', align: 'left' },
                { id: 'status', label: 'Status', align: 'left' },
                { id: 'sub_type', label: 'Service', align: 'left' },
                { id: 'end_date_ISO', label: 'Date', align: 'left' },
                // { id: 'last_modified_date_ISO', label: 'Updated', align: 'left' },
            ] : [
                { id: 'name_detailed', label: 'Property', align: 'left' },
                { id: 'title', label: 'Title', align: 'left' },
                { id: 'amount', label: 'Amount', align: 'left' },
                { id: 'status', label: 'Status', align: 'left' },
                { id: 'sub_type', label: 'Service', align: 'left' },
                { id: 'end_date_ISO', label: 'Date', align: 'left' },
                // { id: 'last_modified_date_ISO', label: 'Updated', align: 'left' },
            ]
    }
        , [filterTab, singleListingMode]);

    const [dynamicTableHeaders, dynamicallyShownTableContentKeys] = useDynamicTableHeaders({
        tableHeaders: TABLE_HEAD,
        breakpoints: {
            sm: filterTab === TASK_TAB_OPTION.task
                ? ['title', 'related_name', 'dueDate', 'last_modified_date_ISO'] as (keyof TaskGeneral)[]
                : ['name_detailed', 'amount', 'title', 'status', 'end_date_ISO'] as (keyof ListingOrderItemGeneral)[],
            md: filterTab === TASK_TAB_OPTION.task
                ? ['title', 'status_mapped', 'related_name', 'dueDate', 'last_modified_date_ISO'] as (keyof TaskGeneral)[]
                : ['name_detailed', 'amount', 'title', 'status', 'sub_type', 'end_date_ISO'] as (keyof ListingOrderItemGeneral)[]
        }
    })

    const inputData = useMemo(() => {
        const dataArr = []

        if (isTasksSuccess && !isTaskFetching) {
            dataArr.push(...tasks)
        }

        if (isLOISuccess && !isLOIFetching) {
            dataArr.push(...lois)
        }

        return dataArr
    }, [isTasksSuccess, isTaskFetching, isLOISuccess, isLOIFetching, tasks, lois])

    const dataFiltered = applyFilter({
        inputData: inputData,
        comparator: getComparator(order, orderBy),
        filterName,
        filterType,
        filterByTab: filterTab,
    });

    const isFiltered = filterName !== '' || filterType !== 'all';

    const isNotFound =
        (!dataFiltered.length && !!filterName) ||
        (!dataFiltered.length && !!filterType) ||
        (!dataFiltered.length && !!filterTab);

    const handleOpenConfirm = () => {
        //REMINDER: This only pertains to _our_ confirm, not one opened by one of our table rows!
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        //REMINDER: This only pertains to _our_ confirm, not one opened by one of our table rows!
        setOpenConfirm(false);
    };

    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: TASK_TAB_OPTION) => {
        setPage(0);
        // inject new code here
        setFilterTab(newValue);
    };

    const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleFilterType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setFilterType(event.target.value);
    };

    const handleViewRow = async (id: string) => {
        // navigate(PATH_DASHBOARD.property.view(id));
        console.log('View row', id)
    };

    //? KEEP: But at the moment, we don't have a use case.
    const handleResetFilter = () => {
        setFilterName('');
        setFilterType('all');
        // setFilterStage(defaultStageFilter);
    };

    const rowIds = useMemo(() => Array.isArray(tasks) ? tasks?.map((row) => row.id) : [], [tasks])

    // set up the table's tab definitions
    let tabInfo: null | TableTabInfo[] = null;

    if (showTableTabCounts) {
        const getLengthByModelType = (modelType: TASK_TAB_OPTION) => {
            if (!Array.isArray(inputData)) {
                return 0;
            }

            const totalTasks = (inputData.filter((v) => v instanceof TaskGeneral)).length;

            if (modelType === TASK_TAB_OPTION.task) {
                return totalTasks;
            }

            return inputData.length - totalTasks;
        }

        tabInfo = TASK_TAB_OPTIONS.map(v => {
            const color = (v === TASK_TAB_OPTION.task ? 'secondary' : 'primary') as LabelColor;
            return { value: v, label: v, color: color, count: getLengthByModelType(v) };
        });
    }
    // else we'll use simple string tabs

    return (
        <>
            <Tabs
                value={filterTab}
                onChange={handleTabChange}
                sx={{
                    px: 2,
                    bgcolor: 'background.neutral',
                }}
            >
                {!showTableTabCounts
                    ? // draw simple string tabs
                    TASK_TAB_OPTIONS.map(tab => (<Tab key={tab} label={tab} value={tab} />))
                    : // draw tabs with dynamic counter
                    tabInfo !== null && tabInfo.map((tab, i) => (
                        <Tab
                            key={tab.value + '-' + i}
                            value={tab.value}
                            label={tab.label}
                            icon={<Label color={tab.color} sx={{ mr: 1 }}> {tab.count} </Label>}
                        />
                    ))
                }
            </Tabs>

            <Divider />

            <TaskTableToolbar
                label={filterTab === TASK_TAB_OPTION.task ? 'Type' : 'Service'}
                filterName={filterName}
                filterType={filterType}
                isFiltered={isFiltered}
                optionsType={toolBarOptions}
                onFilterName={handleFilterName}
                onFilterType={handleFilterType}
                onResetFilter={handleResetFilter}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                    dense={dense}
                    numSelected={selected.length}
                    rowCount={dataFiltered.length}
                    onSelectAllRows={(checked) => {
                        onSelectAllRows(
                            checked,
                            rowIds
                        )
                    }
                    }
                    action={
                        <Tooltip title="Delete">
                            <IconButton color="primary" onClick={handleOpenConfirm}>
                                <Iconify icon="eva:trash-2-outline" />
                            </IconButton>
                        </Tooltip>
                    }
                />

                <Scrollbar>
                    <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: tableMinWidth }}>
                        <TableHeadCustom
                            usageTable={filterTab.toLowerCase().replace(' ', '_')}
                            order={order}
                            orderBy={orderBy}
                            headLabel={dynamicTableHeaders}
                            rowCount={dataFiltered.length}
                            numSelected={selected.length}
                            onSort={onSort}
                            onSelectAllRows={(checked) =>
                                onSelectAllRows(
                                    checked,
                                    rowIds
                                )
                            }
                        />

                        <TableBody>
                            {isTasksSuccess && isLOISuccess && dataFiltered
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    if (row instanceof TaskGeneral) {
                                        return (
                                            <TaskTableRow
                                                key={row.id}
                                                row={row}
                                                selected={selected.includes(row.id)}
                                                onSelectRow={() => onSelectRow(row.id)}
                                                onDeleteRow={() => {
                                                    console.warn('listing delete-single disabled');
                                                }}
                                                onViewRow={() => handleViewRow(row.id)}
                                                showColumns={dynamicallyShownTableContentKeys as Set<keyof TaskGeneral>}
                                            />
                                        )
                                    }
                                    if (row instanceof ListingOrderItemGeneral) {
                                        return (
                                            <LoiTableRow
                                                key={row.id}
                                                singleListing={singleListingMode}
                                                row={row}
                                                selected={selected.includes(row.id)}
                                                onSelectRow={() => onSelectRow(row.id)}
                                                onDeleteRow={() => {
                                                    console.warn('listing delete-single disabled');
                                                }}
                                                onViewRow={() => handleViewRow(row.id)}
                                                showColumns={dynamicallyShownTableContentKeys as Set<keyof ListingOrderItemGeneral>}
                                            />
                                        )
                                    }
                                    return null
                                })}

                            {/* <TableEmptyRows
                                height={denseHeight}
                                emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)}
                            /> */}

                            {(isTaskFetching || isLOIFetching)
                                ? <TableLoading isNotFound={isNotFound} />
                                : <TableNoData isNotFound={isNotFound} isFiltered={isFiltered} />
                            }
                        </TableBody>
                    </Table>
                </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
                count={dataFiltered.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                showDenseToggle={false}
                dense={dense}
                onChangeDense={onChangeDense}
            />

        </>
    )
}

// ----------------------------------------------------------------------

function applyFilter({
    inputData,
    comparator,
    filterName,
    filterType,
    filterByTab,
}: {
    inputData: (TaskGeneral | ListingOrderItemGeneral)[];
    comparator: (a: any, b: any) => number;
    filterName: string;
    filterType: string;
    filterByTab: string;
}) {
    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    switch (filterByTab) {
        case 'Tasks':
            inputData = inputData.filter((el) => el instanceof TaskGeneral)
            break
        case 'Service Items':
            inputData = inputData.filter((el) => el instanceof ListingOrderItemGeneral)
            break
        default:
            break
    }

    if (filterType !== 'all') {
        inputData = inputData.filter((el) => {
            if (el instanceof TaskGeneral) {
                return el.type === filterType
            }
            if (el instanceof ListingOrderItemGeneral) {
                return el.sub_type === filterType
            }
            return false
        })
    }
    if (filterName) {
        inputData = inputData.filter((el) => {
            if (el instanceof TaskGeneral) {
                return el.title.toLowerCase().indexOf(filterName.toLowerCase()) > -1
                    || el.related_name && el.related_name.toLowerCase().indexOf(filterName.toLowerCase()) > -1
                    || el.unit_id.toLowerCase().indexOf(filterName.toLowerCase()) > -1
            }
            if (el instanceof ListingOrderItemGeneral) {
                return el.title.toLowerCase().indexOf(filterName.toLowerCase()) > -1
                    || el.name_detailed.toLowerCase().indexOf(filterName.toLowerCase()) > -1
            }
            return false
        })
    }
    return inputData;
}

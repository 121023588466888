
export const EmergencyFallbackErrorText = 'There was a problem.';

export function safelyReadErrorMessage(prefix: null | string, error: any) {
    let errorText = prefix === null ? '' : prefix;

    let errorDetail = '';

    if (error) {
        // check "errorMessage" first, which comes from our custom errors
        if ((typeof error.errorMessage === 'string') && error.errorMessage !== '') {
            errorDetail = error.errorMessage;
        }

        // check "message" next, which is the most common
        if ((typeof error.message === 'string') && error.message !== '') {
            errorDetail = error.message;
        }

        // we'll also take a raw string, to be thorough
        if ((typeof error === 'string') && error !== '') {
            errorDetail = error;
        }
    }

    // if we found an error detail, tack it on
    if (errorDetail !== '') {
        errorText += errorDetail;
    }
    else if (prefix) {
        // failsafe: if there was a prefix, but we found no suffix, append _something_
        errorText += 'Unknown';
    }

    return errorText;
};
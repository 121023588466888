import { Middleware, MiddlewareAPI, configureStore, isRejected } from '@reduxjs/toolkit';
import { signOut } from 'firebase/auth';
import {
	TypedUseSelectorHook,
	useDispatch as useAppDispatch,
	useSelector as useAppSelector,
} from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';

//TODO: If we keep this (here), fix the cycle.

// eslint-disable-next-line import/no-cycle
import { AUTH } from 'src/auth/FirebaseContext';

import { MutationMessageProviderErrorText, MutationMessageProviderSuccessText, QueryMessageProviderErrorText, QueryMessageProviderSuccessText } from './apiMessageTexts';
import rootReducer, { rootPersistConfig } from './rootReducer';
import { apiSlice } from './rtkQuery/apiSlice';
import { addError, addMessage } from './slices/apiMessage';
import { safelyReadErrorMessage } from '../utils/mrr/errorHandling';
import { FirebaseError } from 'firebase/app';
import { sendToSentry } from '../utils/mrr/sentryReporter';

// ----------------------------------------------------------------------
export type RootState = ReturnType<typeof rootReducer>;

export const standardFBExpiredMessage = 'Session expired.';

export type AppDispatch = typeof store.dispatch;

const checkActionIsFirebaseExpiration = (action: any) => {
	return (action
		&& (action.payload
			&& action.payload.name === 'FirebaseError'
			&& action.payload.message === standardFBExpiredMessage)
		&& (action.error && action.error.message === 'Rejected'));
};


const rtkQueryActionHandler: Middleware =
	(api: MiddlewareAPI<any, any>) => (next) => (action) => {
		if (checkActionIsFirebaseExpiration(action)) {
			console.warn('middleware detected Firebase session expired; signing out');
			// master Firebase end-of-session
			api.dispatch(addError({ error: 'This session has expired.', id: action }));

			// wipe the RTK Query cache
			api.dispatch(apiSlice.util.resetApiState());

			//[[USER LOGOUT]]

			// send the user back to sign-in
			signOut(AUTH);
		}

		const { meta } = action

		if (!meta || !meta.arg || !meta.arg.endpointName || meta.requestStatus === 'pending') {
			return next(action)
		}
		const { arg: { endpointName, type }, requestId } = meta

		if (isRejected(action)) {
            //--------------------------------------
            //vvvv  PLEASE DO NOT DELETE BELOW  vvvv
            //IN-PROGRESS: Tracking down the false-positive reports, e.g. the guests 'failure' from 2/12 that
            //             went to Sentry. It does not exist in the GCF logs.
            //             I suspect it's a call that's pending (or a similar mid-way state) then gets canceled
            //             by a page change, and comes through here.

            //SEE: https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
            // If it was cancelled before execution, meta.condition will be true.
            // If it was aborted while running, meta.aborted will be true.
            // If neither of those is true, the thunk was not cancelled, it was simply rejected, either by a Promise rejection or rejectWithValue.

            // ! PLEASE DO NOT DELETE !
            console.log('action rejected', action);

            const shouldReport = meta.condition === false && meta.aborted === false;

            if (meta.condition === true) {
                console.log('action cancelled before execution');
            }

            if (meta.aborted === true) {
                console.log('action aborted while running');
            }
            //^^^^  PLEASE DO NOT DELETE ABOVE  ^^^^
            //--------------------------------------

			//NOTE: If we have overly-cryptic errors, consider the parser from the Guest Portal.
			//		However, that's only been an issue during dev, e.g. when we briefly break a query.
            let errorMsg = null;

			if (type === 'query') {
				errorMsg = QueryMessageProviderErrorText[endpointName]
				console.log("query error", errorMsg)
				if (errorMsg) {
					api.dispatch(addError({ id: requestId, error: errorMsg }))
				}
			}
			if (type === 'mutation') {
				errorMsg = MutationMessageProviderErrorText[endpointName]
				console.log("mutation error", errorMsg, meta)
				if (errorMsg) {
					api.dispatch(addError({ id: requestId, error: errorMsg }))
				}
			}

            if (errorMsg != null) {
                if (shouldReport) {
                    console.log('middleware sentry capture', errorMsg);

                    const nullOrFirebaseError = action.payload instanceof FirebaseError ? action.payload : null

                    sendToSentry(   nullOrFirebaseError,
                                    errorMsg,
                                    'gcf endpoint error',
                                    endpointName,
                                    {
                                        action: action,
                                        error: action.error,
                                        meta: meta,
                                        arg: meta ? meta.arg : 'n/a',
                                        payload: action.payload,
                                        time: (new Date()).toLocaleString(),
                                        timeUTC: (new Date()).toUTCString()
                                    })
                }
            }

			return next(action)

		}

		// Success ----------------------
		if (type === 'query') {
			const message = QueryMessageProviderSuccessText[endpointName]
			// console.log("query success", message)
			if (message) {
				api.dispatch(addMessage({ id: requestId, message }))
			}
		}
		if (type === 'mutation') {
			const message = MutationMessageProviderSuccessText[endpointName]
			// console.log("mutation success", message)
			if (message) {
				api.dispatch(addMessage({ id: requestId, message }))
			}
		}

		return next(action)
	};


const store = configureStore({
	reducer: persistReducer(rootPersistConfig, rootReducer),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		})
			.concat(apiSlice.middleware)
			.concat(rtkQueryActionHandler),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

const useDispatch = () => useAppDispatch<AppDispatch>();

export { dispatch, persistor, store, useDispatch, useSelector };

import {
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import Iconify from '../../../components/iconify';
import useResponsive from '../../../hooks/useResponsive';
import { CalendarReservationContentMode } from '../../../models/mrr/calendarEvents';
import { EditReservationFormI, ReservationGeneral } from '../../../models/mrr/reservationGeneral';
import { PATH_HOME } from '../../../routes/paths';
import { fCurrency } from '../../../utils/formatNumber';
import { FDateFormat, fDate } from '../../../utils/formatTime';
import { ReservationStage } from '../../../utils/mrr/reservationConstants';
import { DropInFormDialog } from '../../@dashboard/reservation/DropInFormDialog';
import { useReservationPopoverState } from '../../@dashboard/reservation/list/useReservationPopoverState';
import ReservationDetailsPopover from '../../popover/reservation/ReservationDetailsPopover';
import { ReservationTableRowMenu } from './ReservationTableRowMenu';

// ----------------------------------------------------------------------

type Props = {
  row: ReservationGeneral;
  selected: boolean;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  mode: CalendarReservationContentMode;
  dense?: boolean;
  showColumns: Set<keyof ReservationGeneral | ''>
};

export default function ReservationTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  mode,
  dense = true,
  showColumns
}: Props) {

  const {
    name,
    guest_type,
    check_in_ISO,
    check_out_ISO,
    nights,
    nightly_rate,
    rental_amount,
    booking_date_ISO,
    guest_id,
    id,
    unit_id,
    listing_id,
    ui_table_guest_name,
  } = row;

  const isMobile = useResponsive('down', 'sm')

  const popoverState = useReservationPopoverState()

  const methods = useForm<EditReservationFormI>({
    defaultValues: {
      id,
      guest_id,
      listing_id,
      check_in: check_in_ISO,
      check_out: check_out_ISO,
    }
  })

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <TableRow
        hover
        selected={selected}
        sx={{ height: denseHeight }}
      >

        {showColumns.has('name') &&
          <TableCell width={40} sx={{ overflow: 'hidden' }}>
            <Link
              onClick={(e) => popoverState.setSelectedRow(e.currentTarget)}
              key={`Link_to_${id}`}
              variant="body2"
              rel="noopener"
              sx={{ display: 'table', cursor: 'pointer' }}
            >
              {isMobile ? <Typography variant="body2" noWrap width='53px'>
                {name}
              </Typography>
                : name
              }
            </Link>
          </TableCell>
        }

        {/* TODO: DOUBLE CHECK THESE MODES */}
        {mode === CalendarReservationContentMode.All && showColumns.has('unit_id') &&
          <TableCell>
            <Link
              component={NavLink}
              to={PATH_HOME.property.view(listing_id)}
              key={`Link_to_${unit_id}:${name}`}
              variant="body2"
              rel="noopener"
              sx={{ display: 'table', cursor: 'pointer' }}
            >
              {unit_id}
            </Link>
          </TableCell>
        }

        {showColumns.has('guest_name') &&
          <TableCell>
            {ui_table_guest_name}
          </TableCell>
        }

        {showColumns.has('guest_type') &&
          < TableCell>
            {guest_type}
          </TableCell>
        }

        {showColumns.has('check_in_ISO') &&
          <TableCell>
            {
              fDate(check_in_ISO, FDateFormat.localized_us_numeric_month_day_year)
            }
          </TableCell>}

        {showColumns.has('check_out_ISO') &&
          <TableCell>
            {
              fDate(check_out_ISO, FDateFormat.localized_us_numeric_month_day_year)
            }
          </TableCell>}

        {showColumns.has('nights') &&
          <TableCell>
            {nights}
          </TableCell>
        }

        {showColumns.has('nightly_rate') &&
          <TableCell>
            {fCurrency(nightly_rate)}
          </TableCell>
        }

        {showColumns.has('rental_amount') &&
          <TableCell>
            {fCurrency(rental_amount)}
          </TableCell>
        }

        {showColumns.has('booking_date_ISO') &&
          <TableCell>
            {fDate(booking_date_ISO, FDateFormat.localized_us_numeric_month_day_year)}
          </TableCell>
        }

        {showColumns.has('') &&
          <TableCell align="right">
            <IconButton color={popoverState.openPopover ? 'inherit' : 'default'} onClick={popoverState.handleOpenPopover}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        }
      </TableRow >
      <DropInFormDialog open={popoverState.openDropinForm} onClose={popoverState.handleCloseDropinForm} message={popoverState.dropRequestUserMessage} setMessage={popoverState.setDropRequestUserMessage} handleOpenConfirm={popoverState.handleOpenDropConfirmed} />
      <ReservationDetailsPopover popoverState={popoverState} reservation={row} />
      {row.stage_mapped !== ReservationStage.Canceled &&
        <ReservationTableRowMenu
          methods={methods}
          reservation={row}
          {...popoverState}
        />
      }
    </>
  );
}


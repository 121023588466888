import { isValid, parseISO } from "date-fns"

enum ListingOrderItemType {
    service = 'Service'
}
enum ListingOrderItemSubType {
    service_carpet_shampooing = 'Carpet Shampooing',
    deep_clean = 'Deep Clean',
    fire_extinguisher_installation = 'Fire Extinguisher Installation',
    pest_control = 'Pest Control',
    photo_shoot = 'Photo Shoot',
    upholstery_cleaning = 'Upholstery Cleaning'
}

export enum ListingOrderItemStatus {
    close = 'Closed',
    approved = 'Approved',
    schedualed = 'Scheduled',
    completed = 'Completed'
}

export default class ListingOrderItemGeneral {
    public readonly type_plus_subtype: string
    public readonly last_modified_date_ISO: Date
    public readonly end_date_ISO: Date | null = null
    constructor(
        public readonly type: ListingOrderItemType | string,
        public readonly title: string,
        public readonly sub_type: ListingOrderItemSubType | string,
        public readonly status: ListingOrderItemStatus | string,
        public readonly name: string,
        public readonly id: string,
        public readonly unit_id: string,
        public readonly name_detailed: string,
        public readonly listing_status: string,
        public readonly bedrooms: number,
        public readonly bathrooms: number,
        public readonly listing_id: string,
        public readonly amount: number,
        public readonly total_amount: number,
        public readonly discount: number,
        public readonly tax_amount: number,
        private readonly _end_date: string,
        private readonly _last_modified_date: string
    ) {
        this.type_plus_subtype = `${type} ${sub_type}`
        this.last_modified_date_ISO = parseISO(this._last_modified_date)

        if (this._end_date && isValid(parseISO(this._end_date))) {
            this.end_date_ISO = parseISO(this._end_date)
        }
    }
}

export function InstantiateLOIFromJSON(json: any) {
    return new ListingOrderItemGeneral(
        json.type,
        json.title,
        json.sub_type,
        json.status,
        json.name,
        json.id,
        json.unit_id,
        json.name_detailed,
        json.listing_status,
        json.bedrooms,
        json.bathrooms,
        json.listing_id,
        json.amount,
        json.total_amount,
        json.discount,
        json.tax_amount,
        json.end_date, // Private
        json.last_modified_date, // Private
    )
}

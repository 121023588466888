// @mui
import { LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
  mV?: number;
};

export default function CommonLoadingIndicator({ isNotFound, mV }: Props) {

  const styleObj: any = { display: "flex", justifyContent: "center"};
  if (mV) {
    styleObj.marginBottom = mV + "px";
    styleObj.marginTop = mV + "px";
  }

  return (
    <>
      {isNotFound &&
        <div style={styleObj}>
            <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
        </div>
      }
    </>
  );
}

/*TODO: This needs a viewport-sizes pass.
  const isDesktop = useResponsive('up', 'lg');

  const { isInitialized } = useAuthContext();

  const { themeLayout } = useSettingsContext();

  const isDashboard = isInitialized && pathname.includes('/dashboard') && isDesktop;

  const size =
    (themeLayout === 'mini' && NAV.W_DASHBOARD_MINI) ||
    (themeLayout === 'vertical' && NAV.W_DASHBOARD) ||
    144;

  return (
    <>
      <ProgressBar />

      <StyledRoot
        sx={{
          ...(isDashboard && {
            width: `calc(100% - ${size}px)`,
            height: `calc(100% - ${HEADER.H_DASHBOARD_DESKTOP}px)`,
            ...(themeLayout === 'horizontal' && {
              width: 1,
              height: `calc(100% - ${size}px)`,
            }),
          }),
        }}
      >
        {isDashboard ? (
          <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
        ) : (
*/
import { brandConfig } from "../../config"

export const { supportPhone, supportEmail } = brandConfig
export const companyName = brandConfig.name
export const companyStreetAddress = brandConfig.address.streetAddress
export const companyLocation = `${brandConfig.address.city}, ${brandConfig.address.state} ${brandConfig.address.postalCode}`
export const companyAddress = `${companyStreetAddress}, ${companyLocation}`
export const companySite = brandConfig.website

export const phoneSupportHref = `tel:${supportPhone}`
export function createMailToSupportHref(subject?: string) {
    return `mailTo:${supportEmail}${subject ? `?subject=${encodeURIComponent(subject)}` : ''}`

}
import { Alert, Button, Card, CardHeader, Grid, Stack, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import { useState } from "react"
import { useExpiredSessionErrorToNavigate } from "src/pages/dashboard/useExpiredSessionErrorToNavigate"
import { CLOUD_FUNCTIONS } from "../../../../../auth/FirebaseContext"
import Iconify from "../../../../../components/iconify/Iconify"
import Label from "../../../../../components/label/Label"
import { COIModel, COIStatus, coiRecordType } from "../../../../../models/mrr/listingGeneral"
import { usePatchRTKQListingCOIMutation } from "../../../../../redux/rtkQuery/apiSlice"
import { fCurrency } from "../../../../../utils/formatNumber"
import { FDateFormat, fDate } from "../../../../../utils/formatTime"
import { cloud_postCOIPdf } from "../../../../../utils/mrr/cloudFunctions"
import FileNewFolderDialogWithFilter from "../../../file/portal/FileNewFolderDialogWithFilter"


interface ListingCOICardI {
	listingId: string,
	model: COIModel,
	modelLoading: boolean
}

export default function ListingCOICard(props: ListingCOICardI) {
	const { listingId, model, modelLoading } = props
	const handleExpiredSession = useExpiredSessionErrorToNavigate()
	const [openConfirm, setOpenConfirm] = useState(false);

	const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);
	const [submissionProcessing, setSubmissionProcessing] = useState(false);

	const [
		sendLMIUpdatePatch, // This is the mutation trigger function.
		{
			//NOTE: These states are largely unused, because the call happens as the child of another call.
			//		That outer call's states drive the UI.
			data: lmiUpdateData,
			error: lmiUpdateError,
			isUninitialized: lmiUpdateIsUninitialized,
			isLoading: lmiUpdateIsLoading,
			isSuccess: lmiUpdateIsSuccess,
			isError: lmiUpdateIsError
		},
	] = usePatchRTKQListingCOIMutation();

	const handleOpenUploadFile = () => {
		setUploadFileDialogOpen(true);
	};

	const handleCloseUploadFile = () => {
		setUploadFileDialogOpen(false);
	};

	if (model.type !== coiRecordType) {
		console.warn('invalid model sent to COI card: ' + model.type);
		return null;
	}

	const submissionRequired = model.CheckSubmissionRequired();

	const handleUpload: (a: (File | string)[]) => Promise<void> = async (a) => {
		const fileInfo = a[0];

		//NOTE: We limit to one file in this context, so this is just added complexity.
		//		Of course we'll bring it back if the file limit increases.
		// await Promise.all(a.map(async (fileInfo) => {
			const data: { object_id: string, value: any } = {
				value: '',
				object_id: model.id
			}

			// start showing an upload spinner
			setSubmissionProcessing(true);

			if (typeof fileInfo !== 'string') {
				const reader = new FileReader();
				const readerData = await new Promise((resolve) => {
					reader.onloadend = () => {
						resolve(reader.result);
					};
					//! important that we send up a data url
					// cloudfunction will use this to create a blob for salesforce
					reader.readAsDataURL(fileInfo);
				})
				data.value = readerData;
			} else {
				data.value = fileInfo;
			}

			const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_postCOIPdf)
			const cloudResult = await cloudFunction(data)
				.then(async (result) => {
					if (result) {
						// console.warn('upload result', result);

						const patchResponse = await sendLMIUpdatePatch({
							item_id: model.id,
							status: 'Pending Verification'
						});

						// console.log('after', patchResponse,
						// 			'| objects', lmiUpdateData, lmiUpdateError,
						// 			'| states', lmiUpdateIsUninitialized, lmiUpdateIsLoading, lmiUpdateIsSuccess, lmiUpdateIsError);
					}

					return { data: true };
				})
				.catch((error) => {
					console.log('file post error', error);
					handleExpiredSession(error);
					// this format is required by RTKQ
					return { error: error };
				})
				.finally(() => {
					// hide the upload spinner
					setSubmissionProcessing(false);
				});

			console.log('upload done', cloudResult);
			// return cloudResult
		// }))
	};

	const supportedExtensionsDesc = 'Accepts PDF and image files.';
	const supportedExtensionsRegEx = /\.(pdf|png|jpg|jpeg)$/i;
	const fileCountLimit = 1;

	return (
		<>
			{/* NOTE: We may want a loading state here for refreshes. Not yet, since this model
					  is a child model. It's not fetched directly, and exists at page load. We now
					  cover the reload transition with the file-picker dialog. */}
			{/* {submissionProcessing || modelLoading
				<Card sx={{ padding: 2, height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<CommonLoadingIndicator isNotFound />
				</Card>
			*/}

			{/* {submissionRequired &&
				<><Alert severity="warning">An updated Certificate of Insurance is required for this property. Please upload an image or PDF.</Alert><br /></>
			} */}

			<Card sx={{ padding: 2 }}>
				<Stack direction='row' justifyContent='space-between'>
					<CardHeader
						sx={{ padding: 0 }}
						title={coiRecordType}
						subheader={submissionRequired ? 'Action Required' : ''}
					/>
					<Label
						color={model.status === COIStatus.Expired
							? 'error'
							: model.status === COIStatus.Current
								? 'secondary'
								: 'primary'} // this could be 'default'; it's using this to match the listing details card above it
					>
						{model.status}
					</Label>
				</Stack>
				<br />
				{submissionRequired
					?
					<>
						<Alert severity="warning">An updated Certificate of Insurance is required for this property. Please upload an image or PDF.</Alert><br />

						<Button
							variant="contained"
							startIcon={<Iconify icon="eva:cloud-upload-fill" />}
							onClick={handleOpenUploadFile}
						>
							Upload
						</Button>

						<FileNewFolderDialogWithFilter
							open={uploadFileDialogOpen}
							busy={submissionProcessing || modelLoading}
							supportedExtensionsDesc={supportedExtensionsDesc}
							supportedExtensionsRegEx={supportedExtensionsRegEx}
							fileCountLimit={fileCountLimit}
							onClose={handleCloseUploadFile}
							onUpload={handleUpload}
						/>
					</>
					: (model.status === COIStatus.PendingVerification)
						?
						'We are in the process of verifying your Certificate of Insurance.'
						:
						<Grid container direction='row'>
							<Grid item sm={12}>
								<Grid item>
									<Typography variant='caption'>Provider</Typography>
								</Grid>
								<Grid item>
									<Typography variant='body1'>{model.vendor_name}</Typography>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid item>
									<Typography variant='caption'>Policy Id</Typography>
								</Grid>
								<Grid item>
									{/* <Link onClick={(e) => handleOpenSupportPopover(e)} variant='body1'>{listing?.owner_name}</Link> */}
									<Typography variant='body1'>{model.policy_id}</Typography>
								</Grid>
							</Grid>
							<Grid item xs={6} >
								<Grid item>
									<Typography variant='caption'>Coverage Amount</Typography>
								</Grid>
								<Grid item>
									{/* <Typography variant='body1'>{listing?.effective_date_ISO ? fDate(listing.effective_date_ISO, FDateFormat.localized_us_long_month_day_year) : ''}</Typography> */}
									<Typography variant='body1'>{fCurrency(model.coverage_amount)}</Typography>
								</Grid>
							</Grid>
							<Grid item xs={6} >
								<Grid item>
									<Typography variant='caption'>Policy Coverage</Typography>
								</Grid>
								<Grid item>
									<Typography variant='body1'>{model.policy_coverage}</Typography>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid item>
									<Typography variant='caption'>Date of Receipt</Typography>
								</Grid>
								<Grid item>
									<Typography variant='body1'>{fDate(model.date_of_receipt, FDateFormat.localized_us_long_month_day_year)}</Typography>
								</Grid>
							</Grid>
						</Grid>
				}
			</Card>
		</>
	)
}

import {
    Badge,
    BadgeProps, styled
} from "@mui/material";

export const StyledBadge = styled(
    Badge
)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -1,
        top: 5,
        padding: '0 4px',
    },
}));

// @mui
import { Box, Link, Stack, Typography, Breadcrumbs } from '@mui/material';
//
import { CustomBreadcrumbsProps } from './types';
import LinkItem from './LinkItem';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}: CustomBreadcrumbsProps) {
  // const lastLink = links[links.length - 1].name;
  // const lastLink = null; // if we want all breadcrumbs to be links
  const isMobile = useResponsive('down', 'sm')
  // const stackButton = isMobile && action && links && links.length >= 3
  const stackButton = isMobile && action && links; // testing a size-only rule

  return (
    <Box sx={{ mb: isMobile ? 3 : 5, ...sx }}>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center">
          <Box sx={{ flexGrow: 1 }}>
            {/* HEADING */}
            {heading && (
              <Typography variant="h4" gutterBottom>
                {heading}
              </Typography>
            )}

            {/* BREADCRUMBS */}
            {!!links.length && (
              <Breadcrumbs separator={<Separator />} {...other}>
                {links.map((link) => (
                  <LinkItem
                    key={link.name || ''}
                    link={link}
                    activeLast={activeLast}
                    // disabled={link.name === lastLink}
                    disabled={!link?.href}
                  />
                ))}
              </Breadcrumbs>
            )}
          </Box>

          {!stackButton && action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
        </Stack>
        {stackButton &&
          <Stack direction="row" alignItems="center" justifyContent='flex-end'>
            {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
          </Stack>
        }
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }}
    />
  );
}

import { Moment } from "moment"

export interface DayProps {
    isToday?: boolean,
    isValid?: boolean,
    isFirstDayOfWeek?: boolean,
    isLastDayOfWeek?: boolean,
    isBlocked?: boolean,
    isBlockedCalendar?: boolean,
    isBlockedOutOfRange?: boolean,
    isBlockedByMinNights?: boolean,
    isSelectedStart?: boolean,
    noSelectedEnd?: boolean,
    isSelectedEnd?: boolean,
    isSelectedSpan?: boolean,
    isHovered?: boolean,
    isHoveredSpan?: boolean,
    isFocused?: boolean,
    isBlockedCheckOut?: boolean,
    isBlockedCheckIn?: boolean,
    selectActive?: boolean,
    selectionComplete?: boolean,
    isHighlighted?: boolean,
    day?: Moment | null,
}

export interface ColorType {
    reservedColor: string,
    selectedColor: string,
    defaultColor: string,
    textOutOfRangeColor: string,
    textColor: string,
}

const BlockColor = (Color: ColorType) => ({
    topWhiteBottomBlue: `linear-gradient(135deg, transparent 50%, ${Color.selectedColor} 50%)`,
    topBlueBottomWhite: `linear-gradient(135deg, ${Color.selectedColor} 50%, transparent 50%)`,
    topWhiteBottomGray: `linear-gradient(135deg, transparent 50%, ${Color.reservedColor} 50%)`,
    topGrayBottomWhite: `linear-gradient(135deg, ${Color.reservedColor} 50%, transparent 50%)`,
    topGrayBottomBlue: `linear-gradient(135deg, ${Color.reservedColor} 50%, ${Color.selectedColor} 50%)`,
    topBlueBottomGray: `linear-gradient(135deg, ${Color.selectedColor} 50%, ${Color.reservedColor} 50%)`,
})


export class CellColors {
    // Top
    private _top: string | null = null
    get top(): string | null {
        return this._top
    }
    set top(color: string | null) {
        if (color === null) {
            throw new Error('Top color can not be set to null.')
        }
        if (this._top !== null) {
            throw new Error('Top has already been set.')
        }
        this._top = color
    }

    // Bottom
    private _bottom: string | null = null
    get bottom(): string | null {
        return this._bottom
    }
    set bottom(color: string | null) {
        if (color === null) {
            throw new Error('Bottom color can not be set to null.')
        }
        if (this._bottom !== null) {
            throw new Error('Bottom has already been set.')
        }
        this._bottom = color
    }
}


const CheckedBlocked = ({
    isBlockedCalendar,
    isBlockedCheckIn,
    isBlockedCheckOut
}: DayProps) => {
    return isBlockedCalendar || isBlockedCheckIn || isBlockedCheckOut
}

export const SetBlockingColors = (
    dayProps: DayProps,
    cellColors: CellColors,
    Color: ColorType
): void => {

    if (dayProps.isBlockedOutOfRange) {
        if (dayProps.day && dayProps.day.isSameOrAfter(new Date(), 'day')) {
            // nothing
        } else {
            return
        }
    }

    if (!CheckedBlocked(dayProps)) {
        return
    }

    if (dayProps.isBlockedCheckIn) {
        cellColors.bottom = Color.reservedColor
        if (dayProps.isBlockedCheckOut) {
            cellColors.top = Color.reservedColor
        }
        return
    }

    if (dayProps.isBlockedCheckOut) {
        cellColors.top = Color.reservedColor
        if (dayProps.isBlockedCheckIn) {
            cellColors.bottom = Color.reservedColor
        }
        return
    }

    if (dayProps.isBlockedCalendar) {
        cellColors.top = Color.reservedColor
        cellColors.bottom = Color.reservedColor
        return
    }

    // if (cellColors.top !== null || cellColors.bottom !== null) {
    //     return
    // }

    throw new Error('Unhandled blocked state.')
}

export const CheckSelected = (dayProps: DayProps) => {
    return dayProps.isSelectedStart || dayProps.isSelectedEnd || dayProps.isSelectedSpan
}

export const SetSelected = (
    dayProps: DayProps,
    cellColors: CellColors,
    Color: ColorType): void => {


    if (dayProps.isBlockedOutOfRange) {
        return
    }

    if (!CheckSelected(dayProps)) {
        if (dayProps.selectActive) {
            // Day not selected but selection is in progress.
            if (dayProps.isHoveredSpan) {
                // We are the middle or end of hover span. (Not the start!)
                if (cellColors.top !== null) {
                    // console.warn('break in top selected')
                }
                // if ((dayProps.isBlockedCheckOut || dayProps.isBlockedCalendar)
                //     && !dayProps.isBlockedCheckIn) { //? This may be needed if dates can span from end to start (backwards)
                if (dayProps.isBlockedCheckOut || dayProps.isBlockedCalendar) {
                    // nothing
                    if (dayProps.day) {
                        // console.log('hover span', dayProps.day.format('MM D'), dayProps.isBlockedCheckOut, dayProps.isBlockedCalendar)
                    }
                } else {
                    cellColors.top = Color.selectedColor
                }
                if (!dayProps.isHovered) {
                    // We are the middle not the end.
                    if (cellColors.bottom !== null) {
                        // console.warn('break in bottom selected')
                    }

                    if ((dayProps.isBlockedCheckIn || dayProps.isBlockedCalendar)
                        && !dayProps.isBlockedCheckOut) {
                        // nothing
                        if (dayProps.day) {
                            // console.log('not hovered', dayProps.day.format('MM D'), dayProps.isBlockedCheckIn, dayProps.isBlockedCalendar, dayProps.isBlockedCheckOut)
                        }
                    } else if (dayProps.isBlockedCheckIn && dayProps.isBlockedCheckOut) {
                        // nothing
                    } else {
                        cellColors.bottom = Color.selectedColor
                    }
                }
            }
        }

        return
    }

    if (dayProps.isSelectedStart) {
        // If we select a blocked checkin we will throw an Error 'bottom already set'
        cellColors.bottom = Color.selectedColor
        return
    }
    if (dayProps.isSelectedEnd) {
        cellColors.top = Color.selectedColor
        return
    }

    if (cellColors.top !== null || cellColors.bottom !== null) {
        throw new Error('Invalid selected day state.')
    }

    // We are now a selected span
    cellColors.top = Color.selectedColor
    cellColors.bottom = Color.selectedColor

}


const ValidateColors = (colors: CellColors, Color: ColorType) => {
    if (colors.top === null) {
        colors.top = Color.defaultColor
    }
    if (colors.bottom === null) {
        colors.bottom = Color.defaultColor
    }
}

export const MapColorsToDays = (colors: CellColors, Color: ColorType) => {
    ValidateColors(colors, Color)
    switch (colors.top) {
        case Color.defaultColor: {
            switch (colors.bottom) {
                case Color.defaultColor: return Color.defaultColor
                case Color.reservedColor: return BlockColor(Color).topWhiteBottomGray
                case Color.selectedColor: return BlockColor(Color).topWhiteBottomBlue
                default:
                    throw new Error('No bottom default color set.')
            }
        }
        case Color.selectedColor: {
            switch (colors.bottom) {
                case Color.defaultColor: return BlockColor(Color).topBlueBottomWhite
                case Color.reservedColor: return BlockColor(Color).topBlueBottomGray
                case Color.selectedColor: return Color.selectedColor
                default:
                    throw new Error('No bottom selected color set.')
            }
        }
        case Color.reservedColor: {
            switch (colors.bottom) {
                case Color.defaultColor: return BlockColor(Color).topGrayBottomWhite
                case Color.reservedColor: return Color.reservedColor
                case Color.selectedColor: return BlockColor(Color).topGrayBottomBlue
                default:
                    throw new Error('No bottom reserved color set.')
            }
        }
        default:
            throw new Error('No top color set.')
    }
}
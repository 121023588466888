import { MenuItem } from '@mui/material';
import Iconify from '../../components/iconify/Iconify';
import MenuPopover from '../../components/menu-popover/MenuPopover';
import { supportEmail, supportPhone } from '../../utils/mrr/contactUs';

export function ContactUsPopover(props: { handleCloseSupportPopOver: VoidFunction; openSupportPopOver: HTMLElement | null; }) {
    const { handleCloseSupportPopOver, openSupportPopOver } = props;
    return (
        <MenuPopover
            open={openSupportPopOver}
            onClose={handleCloseSupportPopOver}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            // transformOrigin={{ vertical: 'center', horizontal: 'left' }}
            arrow='top-left'
            sx={{ width: 'auto' }}
        >
            <MenuItem
                component='a'
                href={`mailto:${supportEmail}?subject=Need%20Assistance`}
                onClick={() => {
                    handleCloseSupportPopOver();
                }}
                sx={{ color: 'primary.main' }}
            >
                <Iconify icon='eva:email-outline' />
                {supportEmail}
            </MenuItem>
            <MenuItem
                component='a'
                href={`tel:${supportPhone}`}
                onClick={() => {
                    handleCloseSupportPopOver();
                }}
                sx={{ color: 'primary.main' }}
            >
                <Iconify icon='eva:phone-outline' />
                {supportPhone}
            </MenuItem>
        </MenuPopover>
    );
}

// components
import Chart, { useChart } from '../../../../components/chart';
import { ApexChartEventRelay } from '../../../../components/chart/useChart';
import { fCurrency, fPercent } from '../../../../utils/formatNumber';

// ----------------------------------------------------------------------

export interface ChartMixedOccupancyWAverageProps {
    series: {
        name: string,
        data: number[],
        type: 'bar' | 'line'
    }[],
    labels: string[],
    percentageMode: boolean,
    handleChartEvents?: ApexChartEventRelay
}

export default function ChartMixedOccupancyWAverage(props: ChartMixedOccupancyWAverageProps) {
    const { series, labels, percentageMode = true, handleChartEvents } = props;
    const chartOptions = useChart({
        plotOptions: {
            bar: {
                columnWidth: '50%',
            },
        },
        stroke: {
            width: 2, // this is for the 'line' series in this mixed-mode chart
        },
        xaxis: {
            categories: labels,
        },
        yaxis: {
            min: 0,
            max: percentageMode ? 1.0 : undefined,
            labels: {
                formatter: (value: number) => {
                    return (percentageMode
                            ? fPercent(100 * value)
                            : fCurrency(Math.round(value)));
                }
            },
        },
        tooltip: {
            y: {
                formatter: (value: number) => {
                    return (percentageMode
                            ? fPercent(100 * value)
                            : fCurrency(Math.round(value)));
                }
            },
        },
    },
    handleChartEvents);

    return <Chart type="line" series={series} options={chartOptions} height={320} />;
}

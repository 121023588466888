import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_ADMIN, PATH_HOME, PATH_PAGE } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { roleCanSeePortalNotifications, routeRoleAsAdmin } from '../../../assets/data';
import Iconify from '../../../components/iconify/Iconify';
import { createMailToSupportHref, supportEmail, supportPhone } from '../../../utils/mrr/contactUs';
import { CheckEnvironment, SupportedEnvironments } from '../../../config-global';
import { clientVersion } from '../../../utils/mrr/version';
import { usePortalNotificationContext } from '../../../portalNotification/context/PortalNotificationProvider';
import { useSettingsContext } from '../../../components/settings';
import SvgColor from '../../../components/svg-color';


export default function AccountPopover() {
  const navigate = useNavigate();
  const {
    notifications,
    refresh
  } = usePortalNotificationContext()
  const { themeMode, onToggleMode } = useSettingsContext();
  const { user } = useAuthContext();
  const isOwner = roleCanSeePortalNotifications(user?.role)
  const OPTIONS = [
    {
      label: 'Home',
      linkTo: '/',
    },
    {
      label: 'Profile',
      linkTo: routeRoleAsAdmin(user?.role) ? PATH_ADMIN.user.account : PATH_HOME.user.account,
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const [openSupportPopOver, setOpenSupportPopOver] = useState<HTMLElement | null>(null)
  const handleOpenSupportPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenSupportPopOver(event.currentTarget);
  };
  const handleCloseSupportPopOver = () => {
    setOpenSupportPopOver(null)
  }

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleNotificationButtonClick = () => {
    refresh()
    navigate(PATH_HOME.user.notifications)
  }

  //[[USER LOGOUT]]
  const handleLogout = async () => {
    try {
      handleClosePopover();
      navigate(PATH_PAGE.logout, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout.', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  const devMode = CheckEnvironment(SupportedEnvironments.Dev);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.salutatedName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>
        {typeof themeMode === 'string' &&
          <MenuItem key='theme-mode' onClick={onToggleMode} sx={{ m: 1 }}>
            <SvgColor
              sx={{ mr: 1 }}
              src={`/assets/icons/setting/${themeMode !== 'light' ? 'ic_sun' : 'ic_moon'}.svg`} />
            {InvercedThemeModeLabel(themeMode)}
          </MenuItem>

        }

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
          {isOwner
            && notifications.length > 0
            && <MenuItem key='portal-notifications' onClick={handleNotificationButtonClick}>
              Notification{notifications.length !== 1 ? 's' : ''}
            </MenuItem>
          }
          <MenuItem key='contact-us' onClick={handleOpenSupportPopover}>
            Contact Us
          </MenuItem>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem key='faqs' sx={{ m: 1 }} onClick={() => handleClickItem(PATH_PAGE.faqs)} >
          FAQs
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
        <MenuItem style={{ display: 'none' }}>{'v' + clientVersion}</MenuItem>
        {devMode &&
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <MenuItem sx={{ m: 1 }}>
              {'(dev only) v' + clientVersion}
            </MenuItem>
          </>
        }
      </MenuPopover>
      {/* Contact us popover */}
      <MenuPopover
        open={openSupportPopOver}
        onClose={handleCloseSupportPopOver}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        // transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        arrow="right-top"
        sx={{ width: 'auto' }}
      >
        <MenuItem
          component='a'
          href={createMailToSupportHref('Need Assistance')}
          onClick={() => {
            handleCloseSupportPopOver();
          }}
          sx={{ color: 'primary.main' }}
        >
          <Iconify icon="eva:email-outline" />
          {supportEmail}
        </MenuItem>
        <MenuItem
          component='a'
          href={`tel:${supportPhone}`}
          onClick={() => {
            handleCloseSupportPopOver();
          }}
          sx={{ color: 'primary.main' }}
        >
          <Iconify icon="eva:phone-outline" />
          {supportPhone}
        </MenuItem>
      </MenuPopover>
    </>
  );
}

function InvercedThemeModeLabel(themeMode: 'light' | 'dark') {
  if (themeMode === 'light') {
    return 'Dark Mode'
  }
  return 'Light Mode'
}